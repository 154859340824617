
import React, { useRef } from 'react'
import { useEffect, useState, useContext, useMemo } from 'react';
import { TextInput, Textarea, SimpleGrid, Group, Title, Button, Select, FileInput, ActionIcon, Text, Loader } from '@mantine/core';
//import { useForm } from '@mantine/form';
import { Card, Modal, Container, Row } from 'react-bootstrap';
import { IconCheck, IconTrendingUp2, IconUpload, IconInfoCircle, IconX, IconPlaystationX, IconFileUpload } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import axios from 'axios';
import { getSysId, getIncidentSysId, uploadFile, pushSql, raiseTicket } from '../services/dataService'
import { urls } from '../utils/apiConfigs';
import ReactTooltip, { Tooltip } from 'react-tooltip'
import { FaInfoCircle, FaFileUpload } from 'react-icons/fa';
import { BarLoader } from 'react-spinners';
var SYS_ID;
var INC_NUM;

const Contactus = () => {
  //const fileInputRef = useRef(null);
  const fileInputRef = useRef(null);
  const { instance, accounts } = useMsal();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [issueType, setIssueType] = useState('');
  const [toolname, setToolName] = useState('');
  const [files, setFiles] = useState([]);
  const [validate, setValidate] = useState('this field is required');
  const handleValidate = () => setValidate(false);
  const [showProgress, setShowProgress] = useState(false);
  const [issueDescription, setIssueDescription] = useState('')
  const [shortDescription, setShortDescription] = useState('')


  const options = [
    {
      label: 'Technical',
      value: 'Technical',
    },
    {
      label: 'Business',
      value: 'Business',
    },
  ];


  const handleFileUpload = (e) => {
    const files = e.target.files;
    // Handle the file upload logic here
    console.log("File uploaded:", files[0].name);
    const selectedFile = e.target.files[0];
    // Handle the file upload logic here
    console.log("File uploaded:", selectedFile.name);
    setFiles([selectedFile]);

    // You can perform additional actions or send the file to a server
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const option = [
    {
      label: 'Customer Complaint Portal',
      value: 'Customer Complaint Portal',
    },
    {
      label: 'One Order Change Portal',
      value: 'One Order Change Portal',
    },
    {
      label: 'Ret Pack Portal',
      value: 'Ret Pack Portal',
    },
    {
      label: 'MDM ticketing Portal',
      value: 'MDM ticketing Portal',
    },
    {
      label: 'BeerBot',
      value: 'BeerBot',
    },
  ];
  const [errors, setErrors] = useState(
    {
      issueTypeE: false,
      shortDescriptionE: false,
      issueDescriptionE: false,
      issueToolE: false
    }
  );
  // const form = useForm({
  //   initialValues: {
  //     shortDescription: '',
  //     issueDescription: '',
  //   },
  //   validate: {
  //     shortDescription: (value) => value.trim().length === 0,
  //     issueDescription: (value) => value.trim().length === 0,
  //   },
  // });
  async function getSysID() {
    var name = localStorage.getItem('username');
    getSysId(`${name}`).then((res) => {
      console.log(res,"kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk")
      SYS_ID = res.result[0].sys_id;
      console.log(SYS_ID);
    })
      .catch((e) => {
        console.log(e)
      });
  }

  useEffect(() => {
    getSysID();
  }, []);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setFiles((prevSelectedFiles) => [
      ...prevSelectedFiles,
      ...files.map((file) => file.name),
    ]);

  };
  const removeAttachment = () => {
    setFiles('');
  }
  const onSubmit = (e) => {
    setShowProgress(true);
    e.preventDefault();
    console.log("llllllllllllllllllllllllllllllllllllllllllllll")
    //  const value = form.values;

    if (issueType === '' || shortDescription === '' || issueDescription === '' || toolname === '') {
      setErrors(
        {
          issueTypeE: issueType === '' ? true : false,
          shortDescriptionE: shortDescription === '' ? true : false,
          issueDescriptionE: issueDescription === '' ? true : false,
          issueToolE: toolname === '' ? true : false
        }
      )
      setShowProgress(false);
      return;
    }
    let data = {};

    data["sys_id"] = SYS_ID;
    data["username"] = localStorage.getItem('username');
    data['issueDescription'] = issueDescription;
    data['issueType'] = issueType;
    data['shortDescription'] = toolname + '||' + shortDescription;
    data['projectName'] = toolname;
    data['zone'] = 'GHQ';
    data['priority'] = '3';
    data['typeOfSolution'] = `${toolname}_application_issue`;
    data['issueType'] = 'u_performance';
    data['pointOfContact'] = localStorage.getItem('username');
    if (issueType == 'Business') {
      data['assignmentGroup'] = 'd790d73b4729dd148b10370cd36d43c1';
    }
    else {
      data['assignmentGroup'] = '47259ddc97fda91071adb9b0f053af61';
    }
    data['typeOfSolution'] = 'PWA';
    data['businessService'] = '62a65f2fdbf92090c014fe03f3961915'
    async function uploadFILE(data) {

      //     files.forEach((file) => {

      //       const formData = new FormData();

      //       formData.append("attachment", file);
      //       formData.append("sys_id", data["IncidentSysId"]);    

      //       uploadFile(formData)
      //       .catch((e) => {
      //         console.log(e);
      //       })

      //     });
      console.log('Inside Upload File');
      const formData = new FormData();
      formData.append("attachment", files[0]);
      formData.append("sys_id", data["IncidentSysId"]);
      console.log('Inside uploadFile', files[0], data["IncidentSysId"]);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${localStorage.getItem('snowtoken')}`
        },
      };
      axios.post(urls.UploadFile, formData, config).then((response) => {
        console.log('File Uploaded');
      })
    }




    async function getIncidentSysID(incidentNumber) {
      getIncidentSysId(incidentNumber).then((res) => {
        data["IncidentSysId"] = res.result[0].sys_id;
        uploadFILE(data);
      });
    }

    async function pushSQL(data) {
      pushSql(data).catch((e) => {
        console.log(e);
      })
      setShowProgress(false);
    }
    async function RaiseTicket(data) {
      raiseTicket(data).then((res) => {
        data["IncidentNumber"] = res.result.number;

        INC_NUM = data["IncidentNumber"];
        console.log(INC_NUM);
        getIncidentSysID(INC_NUM);
        pushSQL(data);
        setTimeout(() => {
          handleShow();
        }, 3000);
      })
        .catch((e) => {
          console.log(e);
          alert('Upload Error');
          window.location.reload();
        });
    }
    RaiseTicket(data);
  };
  return (
    <Container >
      <form onSubmit={onSubmit}>
        <div xs={12} sm={11} md={11} lg={9} style={{ margin: "auto", padding: "0 5vw 0 5vw" }}>
          <Card style={{ padding: '20px 30px 30px 40px', width: '100%' }}>
            <Row>
              <div className="list-group list-group-transparent mb-0">
                <h2 style={{ fontWeight: 'bold', color: '#f1c40f', textAlign: 'left' }}>Contact Us</h2>
              </div>
            </Row>

            <hr
              style={{
                color: "black",
                marginTop: "1rem",
                marginBottom: "1rem"
              }}
            />
            <Row >
              <div className="note" style={{ paddingBottom: '17px' }}>
                <p className="noteBoxes type3" style={{ fontSize: 'medium', fontWeight: '600', color: 'black', textAlign: 'left' }}>
                  Please fill the following details to create a support ticket on your queries. The team will get back to you shortly.
                </p>
              </div>
            </Row>
            <Row>
              <div style={{ paddingBottom: '9px' }}>
                <p style={{ fontSize: 'medium', fontWeight: '500', color: 'black', textAlign: 'left' }}>
                  Type of Issue
                </p>
              </div>
            </Row>
            <Row>
              <div style={{ paddingBottom: '17px', minWidth: "250px", width: "31.5vw", borderRadius: "14px", marginTop: "0px" }}>
                {/* <select
                  name="issueType"
                  value={issueType}
                  onChange={(e) => setIssueType(e.target.value)}
                  style={{ border: '2px solid #000', borderRadius: '14px', padding: '8px', width: '100%' }}
                >
                  <option value="" disabled>Select</option>
                  {/* Add your options here based on your data 
                </select> */}

                <select
                  id="inputState"
                  name="issueType"
                  value={issueType}
                  onChange={(e) => setIssueType(e.target.value)}
                  class="form-select" aria-label="Default select example"
                  style={{ border: '1px solid rgb(204, 204, 204)', borderRadius: '12px', padding: '-3px', width: '100%' , color:'rgb(190, 190, 190)' }}
                >
                         <option style={{backgroundColor:'#ccc',color:'black'}} disabled selected value="">Select an option</option>
                  <option style={{color:'black'}} value="technical">Technical</option>
                  <option style={{color:'black'}} value="business">Business</option>
                </select>
                {errors.issueTypeE && (
                  <div style={{ color: 'red', marginTop: '5px' }}>{validate}</div>
                )}



              </div>
            </Row>
            <Row>
              <div style={{ paddingBottom: '9px' }}>
                <p style={{ fontSize: 'medium', fontWeight: '500', color: 'black', textAlign: 'left' }}>
                  Tool Name *
                </p>

              </div>
            </Row>
            <Row>
              <div style={{ paddingBottom: '17px', minWidth: "250px", width: "31.5vw", borderRadius: "14px", marginTop: "0px" }}>
                {/* <select
                  required
                  name="toolname"
                  value={toolname}
                  onChange={(e) => setToolName(e.target.value)}
                  style={{ border: '2px solid #000', borderRadius: '14px', padding: '8px', width: '100%' }}
                >
                  <option value="" disabled>Select</option>
                  {/* Add your options here based on your data 
                </select> */}

                <select
                  onChange={(e) => setToolName(e.target.value)}
                  // required
                  name="toolname"
                  value={toolname}
                  id="inputState"
                  class="form-select" 
                  style={{ border: '1px solid rgb(204, 204, 204)', borderRadius: '12px', width: '100%', color:'rgb(190, 190, 190)'  }}
                >
   <option style={{backgroundColor:'#ccc',color:'black'}} disabled selected value="">Select an option</option>
                  <option style={{color:'black'}} value="retPackPortal">Ret Pack Portal</option>
                  <option style={{color:'black'}} value="beerBot">Beer Bot</option>
                  <option style={{color:'black'}} value="customerComplaintPortal">Customer Complaint Portal</option>
                  <option  style={{color:'black'}} value="oneOrderChangePortal">One Order Change Portal</option>
                </select>
                {errors.issueToolE && (
                  <div style={{ color: 'red', marginTop: '5px' }}>{validate}</div>
                )}


              </div>
            </Row>

            <Row>
              <div style={{ paddingBottom: '7px' }}>
                <p style={{ fontSize: 'medium', fontWeight: '500', color: 'black', textAlign: 'left' }}>
                  Subject *
                </p>

              </div>
            </Row>


            <Row>
            <div style={{ paddingBottom: '17px', minWidth: "250px", width: "31.5vw", borderRadius: "14px", marginTop: "0px" }}>
                {/* <TextInput
                  placeholder="Enter Subject"
                  required
                  mt="md"
                  name="shortDescription"
                  radius="14px"
                  style={{ minWidth: "250px", width: "30vw", borderRadius: "14px", marginTop: "0px" }}
                  {...form.getInputProps('shortDescription')}
                  error={errors.shortDescription}
                /> */}
                <input
                  className='form-control'
                  type="text"
                  placeholder="Enter Subject"
                  //  required
                  name="shortDescription"
                  value={shortDescription}
                  onChange={(e) => setShortDescription(e.target.value)}
                  style={{
                    minWidth: '250px',
                    width: '30vw',
                    marginTop: '0px',
                    padding: '10px',
                    borderRadius: '10px',
                    border: '1px solid #ccc',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    transition: 'border-color 0.2s ease-in-out',
                  }}
                />
                {errors.shortDescriptionE && (
                  <div style={{ color: 'red', marginTop: '5px' }}>{validate}</div>
                )}


                {/* <input type="email" class="form-control" id="inputEmail4" placeholder="Email"></input> */}

              </div>
            </Row>
            <Row>
              <div style={{ paddingBottom: '7px' }}>
                <p style={{ fontSize: 'medium', fontWeight: '500', color: 'black', textAlign: 'left' }}>
                  Short Description *
                </p>

              </div>
            </Row>
            <Row>
            <div style={{ paddingBottom: '17px', minWidth: "250px", width: "31.5vw", borderRadius: "14px", marginTop: "0px" }}>
                <textarea
                  // required
                  className='form-control '
                  placeholder="Enter Short Description"
                  rows={3} // Adjust rows as needed
                  name="issueDescription"
                  value={issueDescription}
                  onChange={(e) => setIssueDescription(e.target.value)}
                  style={{
                    minWidth: '250px',
                    width: '30vw',
                    marginTop: '0px',
                    padding: '10px',
                    borderRadius: '10px',
                    border: '1px solid #ccc',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    transition: 'border-color 0.2s ease-in-out',
                  }}
                />
                {errors.issueDescriptionE && (
                  <div style={{ color: 'red', marginTop: '5px' }}>{validate}</div>
                )}

              </div>
            </Row>


            {/* <Row>
                        <div style={{display:'flex'}}>
                            <div style={{ paddingBottom: '7px' }}>
                                <Text size="md" weight={500} color="black" align="left">{`Attachment *`}</Text>
                           </div>
                            
                        <Tooltip
                              multiline
                              width={270}
                              withArrow
                              transition="fade"
                              transitionDuration={200}
                              label={<div><div>Attach relevant email chains or error screenshots with your request to support the ticket.</div> <div>For multiple attachments please provide the zip folder.</div></div>}
                              >
                               <ActionIcon>
                                <IconInfoCircle size={18} strokeWidth={2} color={'#fab005'} />
                              </ActionIcon> 
                            </Tooltip>
                            </div>
                            <div style = {{paddingLeft: "10px"}}>
                                <Row>
                                    <Text size="11px" weight={400} color="black" align="left" style={{paddingTop:"5px"}}>{`(Strictly allowed File types : .zip, .png , .jpeg, .jpg, .docx, .xlsx, .csv)`}</Text>
                                    </Row>
                            </div>
                    </Row>
                            <Row>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    id="mp-attachment"
                                                    multiple
                                                    style={{ display: 'none' }}
                                                    onChange={handleFileChange}
                                                />
                                                
                                                <div className='upload-sec' style = {{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                        padding: "9px 9px 3px 9px",
                                                        border: "1px solid #ccc",
                                                        borderRadius: "5px",
                                                        marginTop: "5px",
                                                        cursor: "pointer",
                                                        maxWidth: '30vw'
                                                }}>
                                                    <div style= {{paddingRight: "15px"}}>
                                                        <label style={{ marginBottom: "0.2rem"}}>Choose Files</label>
                                                        <div style={{ maxWidth: '100px', fontWeight: 600, fontSize: 10, paddingBottom: "3px"}}> (Max-Size: 5MB) </div>
                                                    </div>
                                                    <div >
                                                        <label htmlFor="mp-attachment" id="uploadEvidencebtn">
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                component="span"
                                                            >
                                                                 <IconFileUpload/> 
                                                            </Button>

                                                        </label>
                                                    </div>
                                                </div>
                                                
                                                
                                                <div className="attachment-links" style = {{paddingLeft: "10px", paddingTop : "30px" ,maxWidth: '30vw'}}>
                                                      {files && files.length > 0 ? (
                                                        files.map((fileName) => (
                                                          <div className="attachment-links" key={fileName}>
                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                              <div>{fileName}</div>
                                                              <IconPlaystationX
                                                                className="close-icon"
                                                                onClick={() => removeAttachment(fileName)}
                                                                style={{ cursor: "pointer", transform: "scale(0.8)", marginLeft: "0.5rem" }}
                                                              />
                                                            </div>
                                                          </div>
                                                        ))
                                                      ) : (
                                                        ""
                                                      )}

                                                  </div>
                                                
                                                </Row>
                                                <Row>
                                    <Text size="11px" weight={400} color="black" align="left" style={{paddingTop:"5px"}}>{`(Please attach relevant email chains or screenshots with your request to support the ticket and faster resolution)`}</Text>
                                    </Row> */}
            <Row>
              <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "left", flexWrap: "wrap" }}>
                <div style={{ paddingBottom: '7px' }}>
                  <p style={{ fontSize: 'medium', fontWeight: '500', color: 'black', textAlign: 'left' }}>
                    Attachment
                  </p>

                </div>
                <div>
                  <Tooltip
                    multiline
                    maxWidth={270}
                    withArrow
                    transition="fade"
                    transitionDuration={200}
                    label={<div><div>Provide relevant error screenshots regarding your query.</div> <div>For multiple attachments please provide the zip folder.</div></div>}
                  >
                    <div style={{ marginRight: '10px' }}>
                      <FaInfoCircle size={18} color={'#fab005'} />
                    </div>
                  </Tooltip>
                </div>
                <div style={{ paddingLeft: "10px" }}>
                  <Row>
                    <p data-tip="Tooltip content" data-place="bottom" style={{ fontSize: '11px', fontWeight: '400', color: 'black', textAlign: 'left', paddingTop: '2px' }}>
                      (Strictly allowed File types : .zip, .png , .jpeg, .jpg, .docx, .xlsx, .csv, .pdf)
                    </p>

                  </Row>
                </div>
              </div>
            </Row>
            <Row>
              <div class="mb-3">

                {/* <input class="form-control form-control" id="formFile" type="file"
                    className="form-control"
                   
                    multiple={false}
                    style={{
                      minWidth: '250px',
                      width: '30vw',
                      marginTop: '0px',
                      padding: '10px',
                      borderRadius: '10px',
                      border: '1px solid #ccc',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                      transition: 'border-color 0.2s ease-in-out',
                    }}
                    
                    accept="image/png,image/jpeg,application/pdf,text/csv,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/zip"
                    onChange={(e) => {
                      setFiles(e.target.files);
                    }
                  }
                 
                  ></input> */}
                <div style={{
                  textAlign: 'center', marginTop: '50px',
                }}>


                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      padding: '20px',
                      border: '2px dashed #ccc',
                      cursor: 'pointer',
                      minWidth: '250px',
                      width: '30vw',

                    }}
                    onClick={handleClick}
                  >
                    <div style={{ fontSize: '40px', marginBottom: '10px' }}>📤</div>
                    <div>Click to Upload</div>
                    <div>Max size 2MB</div>
                  </div>

                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}

                    onChange={handleFileUpload}

                  />
                </div>
              </div>



              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <div className="attachment-links" style={{ paddingLeft: '10px', paddingTop: '10px', maxWidth: '30vw' }}>
                  {files[0]?.name ? <div>{files[0]?.name}</div> : <div>{files[0]?.fileName}</div>}
                </div>
                <div style={{ paddingLeft: '5px', paddingTop: '10px' }}>
                  {files[0]?.name ? <div onClick={removeAttachment} style={{ cursor: 'pointer' }}>Remove Attachment</div> : ""}
                </div>
              </div>


            </Row>
            {/* <Group position="right" mt="xl">
              <Link to="/">
                 <button
                  color="gray"
                  radius="xl"
                  leftIcon={<IconX size={16} color="white" />}
                >{`Cancel`}</button> 
              </Link>
              
              <button
                type="submit"
                color="yellow"
                radius="xl"

                leftIcon={<IconCheck size={16} color="white" />}
              >{`Submit`}</button> 
            </Group> */}
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2rem' }}>
              <Link to="/">
                <button style={{ color: '#333', borderRadius: '14px', padding: '12px', marginRight: '10px', backgroundColor: '#e0e0e0', width: '200px', border: 'none', cursor: 'pointer', transition: 'background-color 0.3s' }}>
                  Cancel
                </button>
              </Link>

              <button
                type="submit"
                style={{ color: '#333', backgroundColor: '#fcbf49', borderRadius: '14px', padding: '12px', width: '200px', border: 'none', cursor: 'pointer', transition: 'background-color 0.3s' }}
              >
                Submit
              </button>
            </div>


          </Card >
        </div >
      </form >
      <Modal show={showProgress} backdrop="static" keyboard={false} centered>
        <Modal.Header>
          <Modal.Title>Thank You!</Modal.Title>
        </Modal.Header>
        <Modal.Body text-align="center">
          Please wait we are communicating your query to SNOW team.
          <BarLoader
            color="gray"
            height={5}
            width={150}
            css={{ margin: 'auto', display: 'block', paddingTop: '15px' }}
          />
        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
        <Modal.Header>
          <Modal.Title>Note</Modal.Title>
          <Link to="/">
            <IconX color="black" />
          </Link>

        </Modal.Header>
        <Modal.Body text-align="center">
          Your ticket has been raised with Incident Number: {INC_NUM}.
        </Modal.Body>
        <Modal.Footer>
          <Link to="/">
            <button
              color="gray"
              radius="xl"
              leftIcon={<IconX size={16} color="white" />}
            >{`Close`}</button>
          </Link>
        </Modal.Footer>
      </Modal>


    </Container>

  );
}

export default Contactus;