import React, { useState, useEffect, createRef } from "react";
import NavBar from "../Navbar";
import { Button } from "reactstrap";
import CustomerTable from "./CustomerTable";
import SKUTable from "./SKUTable";
import BootstrapTable from "react-bootstrap-table-next";
import ImportingChart from "./ImportingChart";
import ExportingChart from "./ExportingChart";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import { Form } from 'reactstrap'
import ConfirmAlert from "../../common/ConfirmAlert";
import MdmNavbar from "./MdmNavbar";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { v4 as uuidv4 } from "uuid";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { Alert } from 'reactstrap';
import { Navigate } from "react-router-dom";
import axios from 'axios';
import AxiosApi from '../../Api'

// axios.defaults.headers.post['Content-Type'] = 'application/json';
let zones = [];
var distances = [];
let Label = [],
  Count = [];
const Sku = () => {

  const isAuthenticated = useIsAuthenticated();
  const [skuData, setSkuData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const dataMap1 = (element) => ({
    ...element,
    SkuNumber: element?.['Article #'],
    MaterialDescription: element?.['Material Description'],
    Brand: element?.['Brand'],
    SubBrand: element?.['Sub Brand'],
    ContainerSize: element?.['Container Size'],
    SecondPack: element?.['Secondary Pack Combined'],
    Soldto: element?.['Soldto'],
    id: uuidv4(),
  });

  const dataMap2 = (element) => ({
    ...element,
    Attribute: element?.["Attribute 3rd party"],
    AreaManager: element?.["Area Manager"],
    ImportingZone: element?.["Importing zone"],
    PayerName: element?.["Payer name"],
    ShipToName: element?.["Ship to name"],
    ShipTo: element?.["Shipto"],
    SoldTo: element?.["Soldto"],
    SoldToName: element?.[" Sold to Name"],
    SalesOrg: element?.["Sales Org#"],
    ShipToCountry: element?.["Ship to country"],
    Payer: element?.["Payer"],
    ExportingZone: element?.["Flow"],
    id: uuidv4(),
  });

  const config2 = {
    method: "GET",
    url: process.env.REACT_APP_API_ROOT_URL_PREFIX + "/getCustomerList",
  };

  const config1 = {
    method: "GET",
    url: process.env.REACT_APP_API_ROOT_URL_PREFIX + "/getskulist",
  };
  const config3 = {
    method: "GET",
    url: process.env.REACT_APP_API_ROOT_URL_PREFIX + "/getExportingZone",
  };

  useEffect(() => {
    Label = []
  }, [])


  useEffect(() => {
    function fetchData() {
      AxiosApi(config1)
        .then((response) => {
          // console.log(response.data.recordsets[0]);
          setSkuData(response?.data?.recordsets[0]);
        })
        .catch((error) => {
          console.log(error);
        })
    }
    fetchData();
  }, [])


  useEffect(() => {
    function fetchData1() {
      AxiosApi(config2)
        .then((response) => {
          setCustomerData(response?.data?.recordsets[0]);
        })
        .catch((error) => {
          console.log(error);
        })
    }
    fetchData1()
  }, []);


  useEffect(() => {
    function fetchData2() {
      AxiosApi(config3)
        .then((response) => {
          // console.log(response.data.recordsets[0]["Flow"]);
          zones = response.data.recordsets[0];
        })
        .catch((error) => {
          console.log(error);
        })
    }
    fetchData2();
  }, []);

  for (let j = 0; j < zones.length; j++) {
    distances[zones[j]["Flow"]] = (distances[zones[j]["Flow"]] || 0) + 1;
    j++;
  }

  for (let i in distances) {
    if (i == "null" || i == '') continue;
    if (Label.indexOf(i) == -1) Label.push(i);
  }
  const dataa1 = skuData?.map(dataMap1);
  const dataa2 = customerData instanceof Array && customerData?.map(dataMap2);
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  return <Home2 dataa1={dataa1} dataa2={dataa2} label={Label} />;
};

const Home2 = ({ dataa1, dataa2, label }) => {
  const values = label.reduce((a, v) => ({ ...a, [v]: v }), {})

  const columns2 = [
    {
      dataField: "SoldTo",
      text: "Sold To",
      headerClasses: "border-0 font-weight-bold",
      classes: "border-0 py-2 align-middle text-400",
      // sort: true,
      filter: textFilter({
        delay: 100,
      }),
    },
    {
      dataField: "ShipTo",
      text: "Ship To",
      headerClasses: "border-0 font-weight-bold",
      classes: "border-0 py-2 align-middle text-400",
      // sort: true,
      filter: textFilter({
        delay: 100,
      }),
    },
    {
      dataField: "SalesOrg",
      text: "Sales Org",
      headerClasses: "border-0 font-weight-bold",
      classes: "border-0 py-2 align-middle text-400",
      filter: textFilter({
        delay: 100,
      }),
    },
    {
      dataField: "ExportingZone",
      text: "Exporting Zone",
      headerClasses: "border-0 font-weight-bold",
      classes: "border-0 py-2 align-middle text-400",

      filter: selectFilter({
        options: values,
      }),
    },
    {
      dataField: "SoldToName",
      text: "Sold To Name",
      headerClasses: "border-0 font-weight-bold",
      classes: "border-0 py-2 align-middle text-400",

      filter: textFilter({
        delay: 100,
      }),
    },

    {
      dataField: "ShipToName",
      text: "Ship To Name",
      headerClasses: "border-0 font-weight-bold",
      classes: "border-0 py-2 align-middle text-400",
      // sort: true,
      filter: textFilter({
        delay: 100,
      }),
    },
    {
      dataField: "ShipToCountry",
      text: "Ship to country",
      headerClasses: "border-0 font-weight-bold",
      classes: "border-0 py-2 align-middle text-400",
      // sort: true,
      filter: textFilter({
        delay: 100,
      }),
    },

    {
      dataField: "Payer",
      text: "Payer",
      headerClasses: "border-0 font-weight-bold",
      classes: "border-0 py-2 align-middle text-400",
      // sort: true
      filter: textFilter({
        delay: 100,
      }),
    },

    {
      dataField: "PayerName",
      text: "Payer Name",
      headerClasses: "border-0 font-weight-bold",
      classes: "border-0 py-2 align-middle text-400",
      // sort: true,
      filter: textFilter({
        delay: 100,
      }),
    },

    {
      dataField: "AreaManager",
      text: "Area Manager",
      headerClasses: "border-0 font-weight-bold",
      classes: "border-0 py-2 align-middle text-400",
      // sort: true,
      filter: textFilter({
        delay: 100,
      }),
    },

    {
      dataField: "ImportingZone",
      text: "Importing Zone",
      headerClasses: "border-0 font-weight-bold",
      classes: "border-0 py-2 align-middle text-400",
      // sort: true
    },

    {
      dataField: "Attribute",
      text: "Attribute",
      headerClasses: "border-0 font-weight-bold",
      classes: "border-0 py-2 align-middle text-400",
      // sort: true,
    },
  ];
  const columns = [
    {
      dataField: "SkuNumber",
      text: "SKU Number",
      headerClasses: "border-0 font-weight-bold",
      classes: "border-0 py-2 align-middle text-400",
      //sort: true,
      filter: textFilter({
        delay: 100,
      }),
    },
    {
      dataField: "Soldto",
      text: "Sold To",
      headerClasses: "border-0 font-weight-bold",
      classes: "border-0 py-2 align-middle text-400",
      //sort: true,
      filter: textFilter({
        delay: 100,
      }),
    },
    {
      dataField: "MaterialDescription",
      text: "Material Description",
      headerClasses: "border-0 font-weight-bold",
      classes: "border-0 py-2 align-middle text-400",
      //sort: true,
      filter: textFilter({
        delay: 100,
      }),
    },
    {
      dataField: "Brand",
      text: "Brand",
      headerClasses: "border-0 font-weight-bold",
      classes: "border-0 py-2 align-middle text-400",
      //sort: true,
      filter: textFilter({
        delay: 100,
      }),
    },
    {
      dataField: "SubBrand",
      text: "Sub Brand",
      headerClasses: "border-0 font-weight-bold",
      classes: "border-0 py-2 align-middle text-400",
      //sort: true,
      filter: textFilter({
        delay: 100,
      }),
    },
    {
      dataField: "ContainerSize",
      text: "Container Size",
      headerClasses: "border-0 font-weight-bold",
      classes: "border-0 py-2 align-middle text-400",
      //sort: true,
      filter: textFilter({
        delay: 100,
      }),
    },
    {
      dataField: "SecondPack",
      text: "SecondPack",
      headerClasses: "border-0 font-weight-bold",
      classes: "border-0 py-2 align-middle text-400",
      //sort: true,
      filter: textFilter({
        delay: 100,
      }),
    },
  ];

  let table = createRef();
  const [skuNumber, setSkuNumber] = useState([]);
  const [result, setResult] = useState([]);
  const [result2, setResult2] = useState([]);
  const [salesOrganizationValue, setSalesOrganizationValue] = useState([]);
  const [soldToValue, setSoldToValue] = useState([]);
  const [shipToValue, setShipToValue] = useState([]);
  const [exportingZoneValue, setExportingZoneValue] = useState([]);
  const select = (row) => {
    if (!result2?.includes(row.id)) {
      setResult2((result2) => [...result2, row.id]);
      setSalesOrganizationValue((salesOrganizationValue) => [
        ...salesOrganizationValue,
        row["SalesOrg"],
      ]);
      setShipToValue((shipToValue) => [...shipToValue, row["ShipTo"]]);
      setSoldToValue((soldToValue) => [...soldToValue, row["SoldTo"]]);
      setExportingZoneValue((exportingZoneValue) => [...exportingZoneValue, row['Flow']])
    } else {
      setResult2((result2) => result2?.filter((item) => item !== row.id));
      var indexSalesOrganization = salesOrganizationValue.indexOf(row["SalesOrg"])
      setSalesOrganizationValue((salesOrganizationValue) =>
        salesOrganizationValue.filter((_, i) => i !== indexSalesOrganization)
      );

      setShipToValue((shipToValue) =>
        shipToValue.filter((_, item) => item !== shipToValue.indexOf(row["ShipTo"]))
      );
      setSoldToValue((soldToValue) =>
        soldToValue.filter((_, item) => item !== soldToValue.indexOf(row["SoldTo"]))
      );
      setExportingZoneValue((exportingZoneValue) => exportingZoneValue.filter((_, item) => item !== exportingZoneValue.indexOf(row['Flow'])));
    }
    // setExportingZoneValue(row.ExportingZone);
  };
  const [visible, setVisible] = useState(false);

  const onDismiss = () => setVisible(false);
  const onSelect = (row) => {
    if (!result?.includes(row.id)) {
      setResult((result) => [...result, row.id]);
      setSkuNumber((skuNumber) => [...skuNumber, row["SkuNumber"]]);
    } else {
      setResult((result) => result?.filter((item) => item !== row.id));
      setSkuNumber((skuNumber) =>
        skuNumber.filter((_, item) => item !== skuNumber.indexOf(row.SkuNumber))
      );
    }
  };
  const selectRow = {
    mode: "checkbox", // single row selection
    onSelect: onSelect,
    onSelectAll: (isSelect, rows, _e) => {
      ResetSku();
    },
  };
  const selectRow2 = {
    mode: "checkbox",
    clickToSelect: true,
    // hideSelectAll:true,
    onSelect: select,
    onSelectAll: (isSelect, rows, _e) => {
      ResetCustomer();
    },
  };
  const handleChangeStatus = ({ meta, file }, status) => {
    console.log(status, meta, file);
  };

  // receives array of files that are done uploading when submit button is clicked

  const [category, setCategory] = useState();
  const [type, setType] = useState();
  const [subType, setSubType] = useState();

  const [requestDescription, setRequestDescription] = useState();

  const [subject, setSubject] = useState();


  const [base64format, setBase64Format] = useState([]);

  function hideAlert() {
    window.location.reload();
  }
  const onTypeChange = (event) => {
    setType(event.target.value);
  };
  const onSubTypeChange = (event) => {
    setSubType(event.target.value);
  };
  const onChangeCategory = (event) => {
    setCategory(event.target.value);
    ResetSku();
    ResetCustomer();
    setRequestDescription();
    setFiles([]);
    setBase64Format([]);
    setSubject();
  };
  const onExportingZoneValueChange = (event) => {
    setExportingZoneValue(event.target.value);
  };

  const [successData, setSuccessData] = useState();
  // const [accessToken, setAccessToken] = useState();
  const [name, changeName] = useState("");
  const [email, changeEmail] = useState("");
  const { accounts, instance } = useMsal();
  const [isSubmit, setIsSubmit] = useState(false);
  useEffect(() => {

    function fetchdata3() {
      if (accounts?.length !== 0) {
        changeName(accounts[0].name);
        changeEmail(accounts[0].username);
      } else {
        changeName("");
        changeEmail("");
      }
    }
    fetchdata3();

  }, [accounts]);

   const client_id =
    process.env.REACT_APP_MDM_CLIENT_ID;
  const client_secret =
    process.env.REACT_APP_MDM_CLIENT_SECRET;
  const username = process.env.REACT_APP_MDM_USERNAME;
  const password = process.env.REACT_APP_MDM_PASSWORD;
  const grant_type = "password";
  const config = {
    method: "POST",
    url: `https://abiglobalsolutioncenter.my.salesforce.com//services/oauth2/token?grant_type=${grant_type}&username=${username}&client_secret=${client_secret}&password=${password}&client_id=${client_id}`,

    header: {
      "Access-Control-Allow-Origin": "*",
    },
  };


  let SubjectTemp = "";
  let Catalogue = "";
  let group = "";
  let data = {};
  let owner = "";
  let succ = false;
  let ticket = "";
  let uploadData = {};
  let uploadId = "";
  let contentId = ""
  let linkdata = {};
  function submitButton(e) {

    email?.length > 0 && subject.length > 0 && category.length > 0 && requestDescription.length > 0 && subType.length > 0 && ((type != 'Project' && exportingZoneValue.length > 0) || ((type == 'Project' || exportingZoneValue.length > 0))) &&
      axios(config)
        .then((response) => {
          localStorage.setItem("zt-token", response.data.access_token);
          return axios.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/users?type=${type}&subType=${subType}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("zt-token")}`,
            },
          });
        })
        .then((response) => {
          setIsSubmit(true);
          SubjectTemp = subject
          Catalogue = response?.data?.records[0]["Id"];
          group = response?.data?.records[0]["SC_ResolverGroup__c"];
          return axios.get(
            `${process.env.REACT_APP_API_ROOT_URL_PREFIX}/getOwnerId?resolverGroup=${response?.data?.records[0]["SC_ResolverGroup__c"]}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("zt-token")}`,
              },
            }
          );
        })
        .then((response) => {
          owner = response?.data?.records[0]?.Id;

          data = {
            SC_MDM_Requester_Email__c: email,
            SC_MDM_Category__c: category,
            SC_MDM_Type__c: type,
            SC_MDM_Subtype__c: subType,
            SC_MDM_Sales_Organisation__c: salesOrganizationValue?.toString(),
            SC_MDM_Sold_To__c: soldToValue?.toString(),
            SC_MDM_Ship_To__c: shipToValue?.toString(),
            SC_MDM_Exporting_Zone__c: exportingZoneValue?.toString(),
            SC_MDM_Sku_Selected__c: skuNumber?.toString(),
            description: requestDescription,
            Subject: SubjectTemp,
            SC_ServiceCatalogue__c: Catalogue,
            Ownerid: owner,

          };

          return axios.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/submitTicket", data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("zt-token")}`,
            },
          });
        })
        .then((response) => {
          succ = response?.data?.success;


          ticket = response?.data?.id;
          setSuccessData(response?.data?.success);

          for (let i = 0; i < base64format.length; i++) {
            uploadData = {
              Title: ticket,
              PathOnClient: Files[i],
              ContentLocation: "S",
              VersionData: base64format[i].substring(base64format[i].indexOf(",") + 1),
            };
            return axios.post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/postUpload`, uploadData, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("zt-token")}`,
              },
            })
              .then((response) => {

                uploadId = response?.data?.id;

                return axios.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/getContentId?uploadId=${uploadId}`, {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("zt-token")}`,
                  },
                });
              })
              .then((response) => {

                contentId = response?.data?.records[0]?.ContentDocumentId;
                linkdata = {
                  ContentDocumentId: contentId,
                  LinkedEntityId: ticket,
                  Visibility: "AllUsers",
                };
                return axios.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/linkUpload", linkdata, {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("zt-token")}`,
                  },
                });
              })
              ;
          }


        });

  }







  const ResetCustomer = () => {
    setSalesOrganizationValue([]);
    setShipToValue([]);
    setSoldToValue([]);
    setExportingZoneValue([]);
    setResult2([]);
  };
  const ResetSku = () => {
    setSkuNumber([]);
    setResult([]);
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const [Files, setFiles] = useState([]);

  const handleSubmit = (files) => {

    files.map(async (f) => {
      setFiles(Files => [...Files, f.file.name]);
      const base64 = await convertBase64(f.file);
      setBase64Format((base64format) => [...base64format, base64]);
    });
    setVisible(true);
  };

  return (
    <>
      <MdmNavbar />

      <div className=" mb-3 mt-3" style={{ padding: "1vw 1vw", marginLeft: "10vw", marginRight: "10vw" }}>
        <div className="pt-2">
          <h3>Int. Log. Master Data and System support </h3>
          <hr className="mt-0 mb-0" />
          <Form onSubmit={e => e.preventDefault()}>


            <div className="tile is-ancestor" id="form-controls">
              <div className="tile is-parent">
                <div className="tile is-child box">
                  <div
                    className="row justify-content-around mt-4"
                    style={{ display: "flex" }}
                  >
                    <div style={{ flex: 1 }}>
                      <input
                        className="form-check-input "
                        type="radio"
                        name="category"
                        value="Master Data"
                        id="master-data"
                        onChange={onChangeCategory}

                      />
                      <label className="form-check-label" for="master-data">
                        <strong>Master Data</strong>
                        <br />
                        ( Operational Master Data maintenance of
                        <br />
                        customer, portfolio and pricing set-up)
                      </label>
                    </div>
                    <div style={{ flex: 1 }}>
                      <input
                        className="form-check-input "
                        type="radio"
                        name="category"
                        id="int-erp-support"
                        value="International ERP Support"
                        onChange={onChangeCategory}

                      />
                      <label className="form-check-label" for="int-erp-support">
                        <strong>International ERP Support</strong>
                        <br />
                        (System Support for issue investigation
                        <br /> and change request management)
                      </label>
                    </div>
                  </div>
                  {category === "Master Data" ? (
                    <div
                      id="master-data-values"
                      className="row justify-content-around"
                    >
                      <div className="col-6">
                        <label className="form-heading" for="type">
                          <h5>
                            Type{" "}
                            <font style={{ color: "red", fontSize: ".8vw" }}>
                              * Required
                            </font>
                          </h5>
                        </label>
                        <select
                          id="type-md"
                          className="form-control form-input"
                          name="type"
                          onChange={onTypeChange}
                          required
                        >
                          <option value="">Select a Type</option>
                          <option value="Machine Accounts">
                            Machine Accounts
                          </option>
                          <option value="Airfreight">Airfreight</option>
                          <option value="Growth Operations">
                            Growth Operations
                          </option>
                          <option value="Prime">Prime</option>
                          <option value="Project">Project</option>
                        </select>
                      </div>
                      <div className="col-6">
                        <label className="form-heading" for="subtype">
                          <h5>
                            Subtype{" "}
                            <font style={{ color: "red", fontSize: ".8vw" }}>
                              * Required
                            </font>
                          </h5>
                        </label>
                        <select
                          id="subtype-md"
                          className="form-control form-input"
                          name="subtype"
                          onChange={onSubTypeChange}
                          required
                        >
                          <option value="">Select a Subtype</option>
                          <option value="Price Maintenance">
                            Price Maintenance
                          </option>
                          <option value="Portfolio Maintenance">
                            Portfolio Maintenance
                          </option>
                          <option value="Empties">Empties</option>
                          <option value="Customer Maintenance">
                            Customer Maintenance
                          </option>
                          <option value="Material Maintenance">
                            Material Maintenance
                          </option>
                          <option value="Operational Issues">
                            Operational Issues
                          </option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                    </div>
                  ) : null}
                  {category === "International ERP Support" ? (
                    <div
                      id="int-erp-support-values"
                      className="row justify-content-around"
                    >
                      <div className="col-4">
                        <label className="form-heading" for="type">
                          <h5>
                            Type{" "}
                            <font style={{ color: "red", fontSize: ".8vw" }}>
                              * Required
                            </font>
                          </h5>
                        </label>
                        <select
                          id="type-ierp"
                          className="form-control form-input"
                          name="type"
                          onChange={onTypeChange}
                          required
                        >
                          <option value="">Select a Type</option>
                          <option value="Operational Issue">
                            Operational Issues
                          </option>
                          <option value="Interface Issue">Interface Issue</option>
                          <option value="Missing Set Up">Missing Set Up</option>
                          <option value="Explanation-Clarification">
                            Explanation-Clarification
                          </option>

                          <option value="Others">Others</option>
                        </select>
                      </div>

                      <div className="col-4">
                        <label className="form-heading" for="type">
                          <h5>
                            Subtype{" "}
                            <font style={{ color: "red", fontSize: ".8vw" }}>
                              * Required
                            </font>
                          </h5>
                        </label>
                        <select
                          id="subtype-ierp"
                          className="form-control form-input"
                          name="subtype"
                          onChange={onSubTypeChange}
                          required
                        >
                          <option value="">Select a Subtype</option>
                          <option value="Order Taking">Order Taking</option>
                          <option value="Transport Planning">
                            Transport Planning
                          </option>
                          <option value="Documentation">Documentation</option>
                          <option value="Invoicing">Invoicing</option>
                          <option value="Freight Audit">Freight Audit</option>
                          <option value="Imports EUR">Imports EUR</option>
                          <option value="Master Data">Master Data</option>
                          <option value="Growth">Growth</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                      <div className="col-4">
                        <label className="form-heading" for="zone">
                          <h5>
                            Exporting Zone{" "}
                            <font style={{ color: "red", fontSize: ".8vw" }}>
                              * Required
                            </font>
                          </h5>
                        </label>
                        <select
                          id="zone"
                          className="form-control form-input"
                          name="subtype"
                          onChange={onExportingZoneValueChange}
                          required
                        >
                          <option value="">Select a Zone</option>
                          {label.map((l) => (
                            <option value={l}>{l}</option>
                          ))}


                        </select>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            {category === "Master Data" ? (
              <>
                <div className="tile is-ancestor" id="form-controls">
                  <div className="tile is-parent">
                    <div className="tile is-child box row justify-content-around">
                      <div>
                        <h4 align="left">
                          Customer Selection Table{" "}
                          {type !== "Project" ? (
                            <font style={{ color: "red", fontSize: ".8vw" }}>
                              * Required
                            </font>
                          ) : null}{" "}
                        </h4>
                      </div>
                      <BootstrapTable
                        bootstrap4
                        keyField="id"
                        data={dataa2}
                        columns={columns2}
                        selectRow={selectRow2}
                        filter={filterFactory()}
                        classes="table-dashboard table-striped table-sm fs--1 border-bottom border-200 mb-0 fontt"
                        rowClasses="btn-reveal-trigger text-400 border-top  border-200"
                        headerClasses="bg-200 text-700 border-y font-weight-bold border-200"
                        pagination={paginationFactory()}
                      />
                      {type != 'Project' ? (<>
                        <div style={{ textAlign: "left" }}>
                          <h5>Selected Values:</h5>
                          <div className="row">
                            <div className="col-auto">
                              <label className="form-label">Sales Organization</label>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  value={salesOrganizationValue}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-auto">
                              <label className="form-label">Ship To</label>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  value={shipToValue}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-auto">
                              <label className="form-label">Sold To</label>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  value={soldToValue}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-auto">
                              <label className="form-label">Exporting Zone</label>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  value={exportingZoneValue}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>) : (<><div style={{ textAlign: "left" }}>
                        <h5>Selected Values:</h5>
                        <div className="row">
                          <div className="col-auto">
                            <label className="form-label">Sales Organization</label>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value={salesOrganizationValue}

                              />
                            </div>
                          </div>
                          <div className="col-auto">
                            <label className="form-label">Ship To</label>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value={shipToValue}

                              />
                            </div>
                          </div>
                          <div className="col-auto">
                            <label className="form-label">Sold To</label>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value={soldToValue}

                              />
                            </div>
                          </div>
                          <div className="col-auto">
                            <label className="form-label">Exporting Zone</label>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value={exportingZoneValue}

                              />
                            </div>
                          </div>
                        </div>
                      </div></>)}



                    </div>
                  </div>
                </div>
                <div className="tile is-ancestor" id="form-controls">
                  <div className="tile is-parent">
                    <div className="tile is-child box row ">
                      <div>
                        <h4 align="left">Sku Selection Table </h4>
                      </div>
                      <BootstrapTable
                        ref={table}
                        bootstrap4
                        keyField="id"
                        data={dataa1}
                        columns={columns}
                        bordered={false}
                        classes="table-dashboard table-striped table-sm fs--1 border-bottom border-200 mb-0 fontt table-dashboard-th-nowrap"
                        rowClasses="btn-reveal-trigger text-400 border-top  border-200"
                        headerClasses="bg-200 text-700 border-y font-weight-bold border-200"
                        filter={filterFactory()}
                        selectRow={selectRow}
                        pagination={paginationFactory()} // {...paginationTableProps}
                      />
                      <div style={{ textAlign: "left" }}>
                        <h5>Selected Values:</h5>
                        <div className="row">
                          <div className="col-auto">
                            <label className="form-label">SKU Number</label>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value={skuNumber}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </>
            ) : null}
            <div className="tile is-ancestor" id="form-controls">
              <div className="tile is-parent">
                <div className="tile is-child box">
                  <div style={{ textAlign: "left" }}>
                    <label className="form-heading" for="">
                      {" "}
                      <h5>
                        Add Attachments
                      </h5>
                    </label>
                  </div>
                  <Alert style={{ zIndex: 9999 }} color="success" isOpen={visible} toggle={onDismiss} fade={true}>
                    Uploads ready to submit
                  </Alert>
                  <div id="file-js-example" className="file has-name is-fullwidth">
                    <br />
                    <Dropzone
                      onChangeStatus={handleChangeStatus}
                      onSubmit={handleSubmit}
                      submitButtonContent="Upload"
                      className="form-control"
                    />


                  </div>
                </div>
              </div></div>




            <div >
              <div className="tile is-ancestor" id="form-controls">
                <div className="tile is-parent">
                  <div className="tile is-child box">
                    <div style={{ textAlign: "left" }}>
                      <label className="form-heading" for="">
                        {" "}
                        <h5>
                          Subject{" "}
                          <font style={{ color: "red", fontSize: ".8vw" }}>
                            * Required
                          </font>
                        </h5>
                      </label>
                      <br />
                      <input
                        className=" is-medium form-input form-control"
                        style={{ width: "100%", borderColor: "#dbdbdb", borderRadius: "4px" }}
                        placeholder="Enter the subject"
                        required
                        onChange={(e) => setSubject(e.target.value)}
                      />
                      <br />
                    </div>
                  </div>
                </div>
              </div></div>

            <div className="tile is-ancestor" id="form-controls">
              <div className="tile is-parent">
                <div className="tile is-child box">
                  <div className="field">
                    <div className="control" style={{ textAlign: "left" }}>
                      <label className="form-heading" for="">
                        {" "}
                        <h5>
                          Request Description{" "}
                          <font style={{ color: "red", fontSize: ".8vw" }}>
                            * Required
                          </font>
                        </h5>
                      </label>
                      <textarea
                        className="textarea is-medium form-input form-control"
                        placeholder="Enter the request Description"
                        required
                        onChange={(e) => setRequestDescription(e.target.value)}
                      />
                      <br />
                    </div>
                  </div>
                  <input
                    type="submit"
                    onClick={submitButton}
                    style={{ background: "#e3af32", color: "black" }}
                    className="
                button  
                is-medium is-fullwidth 
              "
                    disabled={isSubmit}
                  ></input>
                </div>
              </div></div>


          </Form>


        </div>
      </div>
      {successData && (
        <ConfirmAlert
          confirm={hideAlert}
          hideAlert={hideAlert}
          cancelButton={false}
          confirmBtnText="Ok"
          type={successData === true ? "success" : "warning"}
          title={successData === true ? "Submitted" : "Failed"}
          body={
            successData === true
              ? "Ticket Submitted Successfully"
              : `${successData}`
          }
        />
      )}
    </>
  );
};
export default Sku;
