import React from 'react';
import { Spinner } from 'react-bootstrap';

const FullScreenLoader = ({ complaintregistering }) => {
  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Semi-transparent background
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999 // Ensures it's on top of everything
  };

  const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: '1.5rem'
  };

  return (
    complaintregistering === "Yes" && (
      <div style={overlayStyle}>
        <div style={contentStyle}>
          <Spinner animation="border" role="status" variant="light" />
          <span>Complaint Registering...</span>
        </div>
      </div>
    )
  );
};

export default FullScreenLoader;
