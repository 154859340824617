import React from 'react'
import * as ReactBootstrap from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import ModalDialog from 'react-bootstrap/ModalDialog'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import { useEffect } from 'react';
import useState from 'react-usestateref'
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Form, FormControl, Button, Image } from 'react-bootstrap'
import NavbarComponent from './NavbarComponent'
import Container from 'react-bootstrap/Container'
import { Card, FormGroup } from "react-bootstrap";
// import HorizontalLinearStepper from './ProgressBar.js'
// import ReactDOM from 'react-dom';
// import TimelineComponent from './TimelineComponent'
// import FooterComponent from './FooterComponent';
import "./CQ.module.css"
// import NewTrial from "./NewTrial.js";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import ConfirmAlert from "../../common/ConfirmAlert";
import Typography from "@material-ui/core/Typography";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import "./Navbar.css"
import 'react-vertical-timeline-component/style.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import AxiosApi from '../../Api';
import { GrClose } from "react-icons/gr";
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import useUnsavedChangesWarning from "./useUnsavedChangesWarning";
import FileDownload from 'js-file-download';
import * as businessmoment from 'moment-business-days'
import * as moment from 'moment'


const { ExportCSVButton } = CSVExport;
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

function getSteps() {
  return ["Submitted", "Validation", "Registered", "In Progress", "Resolution", "In Progress", "Crediting", "Closed"];
}

const stepslist = ["Submitted", "Validation", "Registered", "In Progress", "Resolution", "In Progress", "Crediting", "Closed"];

function ComplaintPage3() {

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const columns = [
    {
      dataField: "extraFeesCategory",
      text: "Extra fees type",
    },
    {
      dataField: "extraFeesCosts",
      text: "Cost",

    },
    {
      dataField: "extraFeesCurrency",
      text: "Currency",

    }
  ];
  const columns2 = [
    {
      dataField: "salesOrder",
      text: "Sales Order",
    },
    {
      dataField: "Container",
      text: "Container"
    },
    {
      dataField: "PO",
      text: "PO Number"
    },
    {
      dataField: "Quantity",
      text: "Quantity",

    },
    {
      dataField: "SKU",
      text: "SKU",

    },
    {
      dataField: "batchCode",
      text: "Batch Code",

    },
    {
      dataField: "dateOfGoodsArrival",
      text: "Date of Arrival"
    }
  ];


  const classes = useStyles();
  const [diffquantityclaimed, setdiffquantityclaimed] = useState();
  const [duedate, setduedate, refduedate] = useState();
  const [currextradays, setcurrextradays, refcurrextradays] = useState();
  const [currregistered, setcurrregister, refcurrregistered] = useState();
  const [activeStep, setActiveStep, refactiveStep] = useState(1);
  const [skipped, setSkipped] = useState(new Set());
  const steps = getSteps();
  const [show1, setShow1] = useState(false);
  const [data1, setdata1] = useState([]);
  const [order, setorder] = useState([]);
  const [follow, setfollow] = useState([]);
  const [compdesc, setcompdesc] = useState([]);
  const { ackno } = useParams();
  const [acknowledgementNumber, setacknowledgementNumber] = useState();
  const [complaintNumber, setcomplainttNumber] = useState();
  const [complaintdesc, setcomplaintdesc] = useState();
  const [email, setemail] = useState();
  const [errorsubmitting, seterrorsubmitting] = useState(false)
  const [status, setstatus, refstatus] = useState();
  const [typeOfComplaint, settypeOfComplaint, reftypeofComplaint] = useState();
  const [uploaded, setuploaded, refuploaded] = useState();
  const [type, settype, reftype] = useState();
  const [accepted, setaccepted, refaccepted] = useState();
  const [fileslist, setfileslist] = useState([]);
  const [fileslist2, setfileslist2] = useState([]);
  const [chatvalue, setChatValue] = useState();
  const [dynamicfilesizechecker, setdynamicfilesizechecker] = useState();
  const [companyName, setcompanyName] = useState();
  const [costclaim, setcostclaim] = useState();
  const [currencyclaim, setcurrencyclaim] = useState();
  const [closedDate, setclosedDate, refClosedDate] = useState();
  const [customerContacted, setcustomerContacted, refcustomerContacted] = useState();
  const [checkacknopresent, setcheckacknopresent] = useState();
  const [actualstatus, setactualstatus, refactualstatus] = useState();
  const [filessubmitting, setfilessubmitting] = useState("No");
  var username, role, userId;
  const [boolValue, setBoolValue] = useState();
  const [parentAccountNumber, setParentAccountNumber] = useState('');
  useEffect(() => {
    // GET request using AxiosApi inside useEffect React hook
    console.log('Getting user infro');
    username = localStorage.getItem('username');
    role = localStorage.getItem('role');
    console.log('Getting data');
    AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/fetchUserId?email=${username}`)
      .then((response) => {
        userId = response?.data?.recordset[0]?.userID;
        console.log(userId, 'userId');
        return AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/fetchSoldTo?userID=${userId}`);
      })
      .then((response) => {
        // var lengthtot = response?.data.recordset.length
        // for (var i = 0; i < response?.data.recordset.length; i++) {
        //   AxiosApi.get(
        //     `/checkacknopresent?ackno=${ackno}&parentAccountNumber=${response?.data.recordset[i]['parentAccountNumber']}&role=${role}&email=${username}`,
        //   )
        //     .then(function (response) {
        //       console.log("Check Ackno:",i,response.data)
        //       // if (role === 'ABI_CQ_SPOC' || role === 'ABI_CQTeam' || role === 'Manager') {
        //         if(response?.data === 'TRUE'){
        //            setcheckacknopresent('TRUE');
        //            i = lengthtot
        //            return;
        //           }
        //           else{ 
        //             if(checkacknopresent != 'TRUE'){
        //             setcheckacknopresent('FALSE')
        //             }
        //           }
        //       // } else {
        //       //   if(response?.data === 'TRUE'){
        //       //     setcheckacknopresent('TRUE');
        //       //     i = lengthtot
        //       //     return;
        //       //   }
        //       //     else{ 
        //       //       if(checkacknopresent != 'TRUE'){
        //       //       setcheckacknopresent('FALSE')
        //       //       }
        //       // }
        //       // }
        //     })
        //     .catch(function (error) {
        //       // handle error
        //       console.log(error);
        //     });
        // }
      });
  }, []);

  useEffect(() => {
    // GET request using AxiosApi inside useEffect React hook
    console.log("Getting data");
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/status/salesorder', { ackno: { ackno } }).then(function (response) {
      // handle success
      setorder(response?.data);
      console.log("This is data 1")
    })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  }, []);

  useEffect(() => {
    new Promise(function (resolve, reject) {
      console.log("Getting data");
      AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/status/complaintdesc123', { ackno: { ackno } }).then(async function (response) {
        try {
          // handle success
          console.log("This is the dataset")
          setacknowledgementNumber(response?.data[0].acknowledgementNumber);
          setcomplainttNumber(response?.data[0].complaintNumber);
          setcomplaintdesc(response?.data[0].complaintDesc);
          if (response?.data[0].differentQuantityClaimed == 'true') {
            setdiffquantityclaimed(true);
          }
          else {
            setdiffquantityclaimed(false);
          }
          setemail(response?.data[0].email)
          setcurrregister(response?.data[0].registrationDate)
          console.log(response?.data[0].registrationDate)
          settype(response?.data[0].type)
          settypeOfComplaint(response?.data[0].typeOfComplaint)
          setuploaded(response?.data[0].uploaded)
          setaccepted(response?.data[0].accepted)
          setactualstatus(response?.data[0].status)
          setcurrextradays(response?.data[0].extra_dueDate)
          setcompanyName(response?.data[0].companyName)
          setclosedDate(response?.data[0].closedDate)
          setcustomerContacted(response?.data[0].customerContacted)
          setcostclaim(response?.data[0].costClaimed)
          setcurrencyclaim(response?.data[0].currencyClaimed)
          setParentAccountNumber(response?.data[0].parentAccountNumber)
          console.log("This is data 1")
          console.log(complaintdesc)
          console.log(order)
          setstatus('Submitted')
          console.log("This is the type")
          console.log(reftype.current)
          console.log(refactualstatus.current)
          console.log("This is the endtype")
          if (reftype.current == 'Complaint') {
            setActiveStep(3);
            setstatus('Registered')
          }

          if ((refaccepted.current == 'Yes' && reftype.current == 'Complaint') || (refaccepted.current == 'Partially' && reftype.current == 'Complaint') || (refaccepted.current == 'No' && reftype.current == 'Complaint')) {
            setActiveStep(5);
            setstatus('Resolution')
          }

          if (refactualstatus.current == 'Closed' && refClosedDate.current != '' && refClosedDate.current != 'Invalid date' && refClosedDate.current != 'null' && refcustomerContacted.current != null && refClosedDate.current != 'NULL' && refClosedDate.current == "Complaint") {
            setActiveStep(8);
            setstatus('Closed')
          }
          if (reftype.current == 'Incident' && refactualstatus.current == 'Closed') {
            setActiveStep(8);
            setstatus('Closed')
          }

          AxiosApi.get(
            `${process.env.REACT_APP_API_ROOT_URL_PREFIX}/checkacknopresent?ackno=${ackno}&parentAccountNumber=${response?.data[0].parentAccountNumber}&role=${role}&email=${username}`,
          )
            .then(function (response) {
              // if (role === 'ABI_CQ_SPOC' || role === 'ABI_CQTeam' || role === 'Manager') {
              if (response?.data === 'TRUE') {
                setcheckacknopresent('TRUE');
                return;
              }
              else {
                if (checkacknopresent != 'TRUE') {
                  setcheckacknopresent('FALSE')
                }
              }

            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });


        } catch (e) {
          console.log(e)
        }
      })
      setTimeout(() => resolve(1), 2000); // (*)

    }).then(function (result) {
      return new Promise((resolve, reject) => {
        try {
          setTimeout(() => resolve(result * 2), 2000);

          console.log(refcurrregistered.current)
          console.log(refduedate.current)
          setduedate((businessmoment(refcurrregistered.current, 'DD-MM-YYYY').businessAdd(16)._d))
          console.log((businessmoment(refcurrregistered.current, 'DD-MM-YYYY').businessAdd(16)._d))
          setduedate((businessmoment(refduedate.current, 'DD-MM-YYYY').businessAdd(parseInt(refcurrextradays.current))._d))
          console.log((businessmoment(refduedate.current, 'DD-MM-YYYY').businessAdd(parseInt(refcurrextradays.current))._d))

        } catch (e) {
          console.log(e)
        }
      })
    })
      .catch(function (error) {
        // handle error
        console.log(error);
      })

  }, []);

  useEffect(() => {
    // GET request using AxiosApi inside useEffect React hook
    console.log("Follow up:");
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/status/followup', { ackno: { ackno } }).then(function (response) {
      // handle success
      console.log(response?.data, "followups");
      setfollow(response?.data);
      console.log("This is the final data")
      console.log(type)
      console.log(accepted)
    })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  }, []);

  useEffect(() => {
    // GET request using AxiosApi inside useEffect React hook
    console.log("Extra Fees");
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/status/extrafees', { ackno: { ackno } }).then(function (response) {
      // handle success
      console.log(response?.data);
      setdata1(response?.data);
      console.log("This is data 1")
      console.log(data1)
    })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  }, []);

  const rowStyle = { fontWeight: '600' }

  const handleShow1 = () => setShow1(true);
  const handleSave1 = () => {
    setShow1(false);
  }
  const handleClose1 = () => {
    setShow1(false);
  }
  function Downloadfile(finalfilename) {
    // console.log(e.target.outerText);
    AxiosApi({
      url: process.env.REACT_APP_API_ROOT_URL_PREFIX + '/download2/files',
      method: 'POST',
      responseType: 'arraybuffer', // Important
      data: {
        ackno: acknowledgementNumber, compno: complaintNumber, compname: companyName, filename: finalfilename, typeofcomp: type
      }
    }).then(response => {

      console.log(response)
      // const type = response?.headers['content-type']
      // console.log(type)
      // const blob = new Blob([response?.data], { type: type, encoding: 'UTF-8' })
      // console.log(blob)
      // const link = document.createElement('a')
      // link.href = window.URL.createObjectURL(blob)
      // link.download = 'Testfile.pptx'
      // link.click()
      FileDownload(response?.data, `${finalfilename}`);
    })

  }

  useEffect(() => {

    AxiosApi.post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/download/files/${ackno}`).then(function (response) {
      console.log(response?.data);
      setfileslist(response?.data);
    })
  }, [])
  useEffect(() => {

    AxiosApi.post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/download/creditnote/${ackno}`).then(function (response) {
      console.log(response?.data);
      setfileslist2(response?.data);
    })
  }, [])
  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  var size = 0;
  const [sessionid34, setsessionid34, refsessionid34] = useState([]);
  const [isdisabled, setisdisabled, refisdisabled] = useState();
  const [isdisabledsubmit, setisdisabledsubmit, refisdisabledsubmit] = useState(true);
  const [dropdisabled, setdropdisabled] = useState();
  // const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();
  const [setDirty, setPristine] = useUnsavedChangesWarning();
  const [filecount, setfilecount] = useState(0);
  const [del, setdel, refdel] = useState(0)
  const [filesfinal, setfilesfinal, reffilesfinal] = useState([])

  const handleUnload = (event) => {
    if (refdel.current == 0) {
      var tempemailid = localStorage.getItem('username');
      AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/storefileinfo/removefilesfromdb', { session: refsessionid34.current, filenames: reffilesfinal.current, email: tempemailid }).then(response => {
        var message = response;
        console.log(message);
      })
    }
    const uploadurl2 = process.env.REACT_APP_API_ROOT_URL_PREFIX + '/delete/api' + '/' + refsessionid34.current;
    AxiosApi.post(uploadurl2).then(function (response) {
      var folder = response
      console.log(folder)
    })
    AxiosApi.get(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/session/api/id3').then(function (response) {
      // handle success
      var ses = response?.data;
      setsessionid34(ses);
      console.log(ses);
    })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  };

  React.useEffect(() => {
    window.addEventListener('unload', handleUnload);

    // cleanup this component
    return () => {
      window.removeEventListener('unload', handleUnload);
    };
  }, []);

  // window.addEventListener('unload', function(event) {
  //   if(refdel.current == 0){
  //     AxiosApi.post('/storefileinfo/removefilesfromdb',{session:sessionid34,filenames:files}).then(response => {
  //       var message = response ;
  //       console.log(message);
  //     })
  //   }
  //     const uploadurl2 = '/delete/api'+'/'+sessionid34;
  //     AxiosApi.post(uploadurl2).then(function (response) {
  //         var folder = response
  //         console.log(folder)
  //     })

  // });
  useEffect(() => {
    console.log("Getting session id");
    AxiosApi.get(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/session/api/id3').then(function (response) {
      // handle success
      var ses = response?.data;
      setsessionid34(ses);
      console.log(ses);
    })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  }, []);
  const getUploadParams = ({ meta, file }) => {
    console.log(meta);
    const body = new FormData()
    //body.append('sessionid34', sessionid34);
    body.append('customFile', file);
    const uploadurl = process.env.REACT_APP_API_ROOT_URL_PREFIX + '/files/api/files-1' + '/' + sessionid34;
    console.log(uploadurl)
    return { url: uploadurl, body }
  }

  useEffect(() => {
    console.log("getting file upload size limit");

    AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/dynamicfilesizechecker/getfilesizes?acknowledgementnumber=${ackno}`).then(function (response) {
      // handle success
      // var ses = response?.data;

      console.log(response?.data, "file limit output");

      setdynamicfilesizechecker(parseInt(response?.data));
    })
      .catch(function (error) {
        // handle error
        console.log(error);
      })


  }, []);

  const handleChangeStatus = async ({ meta, file }, status) => {
    setDirty();
    console.log(status, meta, file);
    if (status == "rejected_file_type") {
      alert("UnSupported file type. Either the extension is not supported / your file size is > than 50 MB")
    }
    if (status == 'error_upload') {
      alert("File upload failed, files being uploaded may be open in your local PC")
    }
    if (status == 'done') {
      var bs64 = await convertBase64(file)
      setfilecount(filecount + 1)
      {//logging file info and name to other table
        console.log("starting file information logging to backend", file);
        console.log("file meta info print", meta)
        var emailautofill = localStorage.getItem("username");
        AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/storefileinfo/storedetailsfromcustomerstatus', { meta: meta, file: file, ackno: { ackno }, bs64: bs64, session: sessionid34, email: emailautofill });
      }
      setfilesfinal(filesfinal => [...filesfinal, meta.name]);
      console.log("filename", filesfinal)
      size += file.size;
      if (size > (200000000 - dynamicfilesizechecker)) {
        setisdisabled(true);
      }
      else if (size < (200000000 - dynamicfilesizechecker)) {
        setisdisabled(false)
      }
      console.log("The total files size after upload is:")
      console.log(size);
    }
    if (status == 'removed') {
      var filename = file.name
      setfilecount(filecount - 1)
      var tempemailid = localStorage.getItem('username');
      {
        AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/storefileinfo/removefilefromdb', { meta: meta, file: file, session: sessionid34, email: tempemailid }).then(response => {//logging info to other table of the file name and type
          var message = response;
          console.log(message);
        });
      }

      AxiosApi.post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/remove/api/${sessionid34}`, { fname: filename }).then(response => {
        var message = response;
        console.log(`Removed file message:`)
        console.log(message);
      }).then(
        AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/folder/api/folder/${sessionid34}`).then(response => {
          size = response?.data.filesize;
          console.log("Afer removal total size is:")
          console.log(size);
        }))
      if (size > (200000000 - dynamicfilesizechecker)) {
        setisdisabled(true);
      }
      else if (size < (200000000 - dynamicfilesizechecker)) {
        setisdisabled(false)
      }
      console.log(size);
    }
    if (status == 'error_validation') {
      alert("Maximum size is 200MB totally");
    }
  }

  const handleSubmit = (files, allFiles) => {
    setPristine();
    setisdisabled(true);
    AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/filecheck/api/aftersubmit/${sessionid34}`, { ackno: acknowledgementNumber })
      .then(function (response2) {
        var filecheck = response2.data;
        AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/count/api/filecount/${sessionid34}`)
          .then(function (response) {
            if (response.data > 0 && filecheck == "notexceededlimit") {
              setdel(1);
              setfilessubmitting("Yes");
              setisdisabled(true);
              setPristine();
              var emailautofill = localStorage.getItem("username");
              AxiosApi.post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/upload2/files/${sessionid34}`, { ackno: acknowledgementNumber, compno: complaintNumber, compname: companyName, fcount: filecount, username: emailautofill, messagepostedby: localStorage.getItem("role") })
                .then(function (response) {
                  alert('All Files are uploaded successfully!');
                  window.location.reload();
                })
            }
            else {
              seterrorsubmitting(true)
            }
          })
      })

    for (let i = 0; i < files.length; i++) {
      size += files[i].meta.size;
    }

  }

  const getFilesFromEvent = e => {
    return new Promise(resolve => {
      getDroppedOrSelectedFiles(e).then(chosenFiles => {
        resolve(chosenFiles.map(f => f.fileObject))
      })
    })
  }
  const validatefile = ({ meta, file }) => {
    const valsize = 200000000 - dynamicfilesizechecker;
    var totsize = size + file.size
    if ((totsize) > valsize) {
      return ("File Upload Error: Maximum total files size = 200 MB")
    }
    else {
      return (false)
    }
  }

  async function hideAlert() {
    window.location.reload();
  }

  const handleChatSubmit = () => {
    setisdisabled(true);
    console.log("hi", chatvalue);
    var emailautofill = localStorage.getItem("username");

    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/postcustomerfollowup", { username: emailautofill, ackno: ackno, compno: complaintNumber, compname: companyName, chatvalue: chatvalue }).then(response => {
      window.location.reload();
    })
  }

  if (localStorage.getItem("username")?.length > 0) {
    if (localStorage.getItem("role") != 'Internal_Customer' && localStorage.getItem("role") != 'External_Customer') {

      return (
        <h1>You are not authorized to view this page</h1>
      );
    }
  }





  return (
    <>
      {checkacknopresent === "FALSE" && (
        <div>
          {console.log(ackno, "props from backend")}
          <h1>You are not authorized to view this Complaint</h1></div>
      )}
      {checkacknopresent === "TRUE" && (
        <div className="background" style={{ marginBottom: "2rem" }}>
          <NavbarComponent />
          <Container>
            <Modal
              show={show1}
              onHide={handleClose1}
              dialogClassName="my-modal"
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Header >
                <Modal.Title className="page-title page-header">
                  <h1 className="page-title">
                    {type} Details - {type === 'Complaint' && (<>{complaintNumber}</>)} {type === 'Incident' && (<>{acknowledgementNumber}</>)}
                  </h1></Modal.Title>
                <span className="btn"><GrClose onClick={handleClose1} /></span>
              </Modal.Header>
              <Modal.Body>
                <div className="row" style={{ margin: "0 !important" }}>
                  <div className="row col-lg-8">
                    <div style={{ overflow: "scroll", maxWidth: "100%" }}>
                      <ToolkitProvider
                        keyField="name"
                        data={order}
                        columns={columns2}
                        exportCSV={{ onlyExportFiltered: true, exportAll: false }}
                      >
                        {
                          props => (
                            <div >
                              <div className="text-left" style={{ marginBottom: "0.5rem" }}>

                                <div className="text-center">
                                </div>
                              </div>
                              <div className="row col-lg-12 table-background expanded-container">
                                <div className="row">
                                  <div className="col-lg-4 pr-4">
                                    <Row className="mb-3 group-box" controlId="formHorizontalEmail">
                                      <Col className="boxes fields text-left col-lg-4 pr-0 pt-2" style={{ padddingTop: '15px' }} >
                                        <Form.Label>Company Name</Form.Label>
                                      </Col>
                                      <Col className="text-left">
                                        {companyName}
                                      </Col>
                                    </Row>
                                    <Row className="mb-3 group-box" controlId="formHorizontalEmail">
                                      <Col className="boxes fields text-left col-lg-4 pr-0 pt-2" style={{ padddingTop: '15px' }}>
                                        <Form.Label>Email</Form.Label>
                                      </Col>
                                      <Col className="text-leftcol-lg-2" style={{ padddingTop: '15px' }}>
                                        {email}
                                      </Col>
                                    </Row>
                                    {type === 'Incident' && (<>
                                      <Row className="mb-3 group-box" controlId="formHorizontalEmail">
                                        <Form.Label>Did you receive a different quantity?</Form.Label>
                                        <input type="checkbox" checked={diffquantityclaimed} disabled={true} />
                                      </Row>
                                    </>
                                    )}
                                  </div>
                                  <div className="col-lg-4 pr-4">
                                    <Row className="mb-2 group-box" controlId="formHorizontalEmail">
                                      <Col className="boxes fields text-left col-lg-4 pr-0 pt-2" style={{ padddingTop: '15px' }}>
                                        <Form.Label>Type of Complaint</Form.Label>
                                      </Col>
                                      <Col className="text-left">
                                        {typeOfComplaint}
                                      </Col>
                                    </Row>
                                    <Row className="mb-3 group-box" controlId="formHorizontalEmail">
                                      <Col className="boxes fields text-left col-lg-5 pr-0 pt-2" style={{ padddingTop: '15px' }}>
                                        <Form.Label>Complaint Description</Form.Label>
                                      </Col>
                                      <Col className="text-left">
                                        {complaintdesc}
                                      </Col>
                                    </Row>

                                  </div>
                                  <div className="col-lg-4 pr-4 ">
                                    <Row className="mb-3 group-box" controlId="formHorizontalEmail">
                                      <Col className="boxes fields text-left col-lg-4 pr-0 pt-2" style={{ padddingTop: '15px' }}>
                                        <Form.Label >Total Product Cost</Form.Label>
                                      </Col>
                                      <Col >
                                        {costclaim}
                                      </Col>
                                    </Row>
                                    <Row className="mb-3 group-box" controlId="formHorizontalEmail">
                                      <Col className="boxes fields text-left col-lg-4 pr-0 pt-2" style={{ padddingTop: '15px' }}>
                                        <Form.Label>Currency</Form.Label>
                                      </Col>
                                      <Col className="text-left">
                                        {currencyclaim}
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                                <hr />

                              </div>
                              <ExportCSVButton  {...props.csvProps} className="text-right">Export to CSV</ExportCSVButton>
                              <div className="row col-lg-12 table-background expanded-container">
                                <BootstrapTable
                                  style={{ display: 'table', marginBottom: '60px' }}
                                  {...props.baseProps}
                                  rowStyle={rowStyle}
                                  striped
                                  hover
                                  condensed
                                />
                              </div>
                            </div>
                          )
                        }
                      </ToolkitProvider>
                    </div>
                  </div>
                  <div className="row col-lg-4" style={{ overflow: "scroll" }}>
                    <ToolkitProvider
                      keyField="id"
                      data={data1}
                      columns={columns}
                      exportCSV
                    >
                      {props => (
                        <div>
                          <div className="d-flex justify-content-around p-2">
                            <ExportCSVButton
                              className="export-button border-secondary rounded"
                              {...props.csvProps}
                            >
                              <span>Export to CSV</span>
                            </ExportCSVButton>


                          </div>
                          <BootstrapTable
                            {...props.baseProps}
                            keyField="id"
                            data={data1}
                            columns={columns}
                            striped
                            hover
                            condensed
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>


              </Modal.Body>
            </Modal>
            <div className="page-header">
              <h1 className="page-title">
                {type} Details - {type === 'Complaint' && (<>{complaintNumber}</>)} {type === '' && (<>{acknowledgementNumber}</>)} {type === 'Incident' && (<>{acknowledgementNumber}</>)}
              </h1>
            </div>
            <Row>
              <Col className="col-lg-9">
                <Card>
                  <div className={classes.root}>
                    <Stepper activeStep={activeStep}>
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepOptional(index)) {
                          labelProps.optional = (
                            <Typography variant="caption"></Typography>
                          );
                        }
                        if (isStepSkipped(index)) {
                          stepProps.completed = false;
                        }
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>



                  </div>
                  <hr />
                  <div className='my-2'>
                    <h1>Chat with the ABI executive</h1>

                    {/* <label htmlFor="username" style={{ marginRight: "20px" }}>Type your message here: </label> */}
                    <input
                      className='form-control my-2'
                      type="text"
                      name="username"
                      placeholder='Type your message here...'
                      onChange={e => setChatValue(e.target.value)}
                      style={{ display: "block", margin: "0 auto", width: "400px" }}
                    />
                    <Button onClick={handleChatSubmit} disabled={isdisabled} style={{ marginLeft: "20px" }}>Send</Button>

                  </div>
                  <VerticalTimeline layout={'2-columns'}>
                    {follow.map((val) => {
                      var info = val.date_start + "  (All timings are in Central Europe time)"
                      if (val.messagePostedBy == "customer")
                        return (


                          <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date={info}
                            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                            position="right"
                          >
                            <h3 className="vertical-timeline-element-title">{val.action}</h3>
                            <h4 className="vertical-timeline-element-subtitle">{val.author}</h4>
                            <h3><p>Message : {val.comment}</p></h3>
                            <h5><p>
                              Posted On : {info}


                            </p></h5>


                          </VerticalTimelineElement>

                        );

                      else
                        return (
                          <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date={info}
                            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                            position="left"
                          >
                            <h3 className="vertical-timeline-element-title">{val.action}</h3>
                            <h4 className="vertical-timeline-element-subtitle">{val.author}</h4>
                            <h3> <p>Message : {val.comment}</p></h3>
                            <h5>
                              <p>
                                Posted On : {info}

                              </p></h5>

                          </VerticalTimelineElement>

                        )


                    })}
                    {/*
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    date="2010 - 2011"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    
  >
    <h3 className="vertical-timeline-element-title">Art Director</h3>
    <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4>
    <p>
      Creative Direction, User Experience, Visual Design, SEO, Online Marketing
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="2008 - 2010"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    
  >
    <h3 className="vertical-timeline-element-title">Web Designer</h3>
    <h4 className="vertical-timeline-element-subtitle">Los Angeles, CA</h4>
    <p>
      User Experience, Visual Design
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="2006 - 2008"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    
  >
    <h3 className="vertical-timeline-element-title">Web Designer</h3>
    <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4>
    <p>
      User Experience, Visual Design
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="April 2013"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    
  >
    <h3 className="vertical-timeline-element-title">Content Marketing for Web, Mobile and Social Media</h3>
    <h4 className="vertical-timeline-element-subtitle">Online Course</h4>
    <p>
      Strategy, Social Media
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="November 2012"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    position={ "left" }
    
  >
    <h3 className="vertical-timeline-element-title">Agile Development Scrum Master</h3>
    <h4 className="vertical-timeline-element-subtitle">Certification</h4>
    <p>
      Creative Direction, User Experience, Visual Design
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="2002 - 2006"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    
  >
    <h3 className="vertical-timeline-element-title">Bachelor of Science in Interactive Digital Media Visual Imaging</h3>
    <h4 className="vertical-timeline-element-subtitle">Bachelor Degree</h4>
    <p>
      Creative Direction, Visual Design
    </p>
  </VerticalTimelineElement>
  */}
                    <VerticalTimelineElement
                      className="vertical-timeline-element--work"
                      contentStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                      contentArrowStyle={{ borderRight: '7px solid  rgb(16, 204, 82)' }}
                      date={uploaded}
                      iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}

                    >
                      <h3 className="vertical-timeline-element-title">Complaint Submitted</h3>
                      {/*<h4 className="vertical-timeline-element-subtitle"></h4>*/}
                    </VerticalTimelineElement>
                  </VerticalTimeline>
                  <hr />
                  <div className="page-header ml-4">
                    <h1 className="page-title">Additional Attachments</h1>
                  </div>
                  <div className="dz-default dz-message">
                    <span>Drop files here or click to upload</span>
                    <br />
                    <span>Total Max File Size Allowed = {(200 - (dynamicfilesizechecker / (1024 * 1024)).toFixed(2))} MB. Max size per file 50MB.</span>
                    <Dropzone
                      validate={validatefile}
                      onSubmit={handleSubmit}
                      accept="image/png,image/jpeg,application/pdf,video/mp4,video/mpeg,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,application/zip,application/x-zip-compressed,application/vnd.openxmlformats-officedocument.presentationml.presentation,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.ppt,.pptx,.msg"
                      getUploadParams={getUploadParams}
                      onChangeStatus={handleChangeStatus}
                      getFilesFromEvent={getFilesFromEvent}
                      submitButtonDisabled={isdisabled}
                      maxSizeBytes={500000000}
                      styles={{
                        dropzone: { height: 250 },
                        dropzoneActive: { borderColor: 'green', backgroundColor: '#baffb3' },
                        dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
                        inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : {}),
                      }}
                    />
                  </div>
                  <div>
                    {filessubmitting === "Yes" && (
                      <div>
                        <ReactBootstrap.Spinner animation="border" /> Files are uploading...
                      </div>
                    )}
                  </div>
                </Card>
              </Col>
              <Col className="col-lg-3">
                <Card>
                  <Card.Header><h5><strong>Complaint Information</strong></h5></Card.Header>
                  <Card.Body className="card-body text-left">
                    <Card.Text><strong>Short Description:</strong></Card.Text>
                    <Card.Text style={{ fontSize: "14px" }}> {complaintdesc}</Card.Text>
                    <Card.Text style={{ fontSize: "14px" }}><strong>Acknowledgement Number: </strong> {acknowledgementNumber}</Card.Text>
                    <Card.Text style={{ fontSize: "14px" }}><strong>Status: </strong> {status}</Card.Text>
                    <Card.Text style={{ fontSize: "14px" }}><strong>Due Date: </strong> {String(moment(refduedate.current).format("DD-MM-YYYY"))}</Card.Text>
                    <Card.Text style={{ fontSize: "14px" }}><strong>Submitted: </strong> {uploaded}</Card.Text>
                    <Card.Text style={{ fontSize: "14px" }}><strong>Accepted: </strong> {accepted}</Card.Text>

                  </Card.Body>
                  <Card.Body>
                    <Button variant="primary" onClick={handleShow1} className="btn btn-primary btn-lg text-center">Show Details</Button>
                    <hr />
                    Customer Quality Team Files / Credit Note:
                    <Card.Text><strong>Attachments:</strong></Card.Text>
                    {fileslist2.map((val) => {
                      return (
                        <p>
                          <a onClick={() => { Downloadfile(val.fileName) }} name={val.fileName} style={{ textDecoration: "underline", color: "#0d6efd", fontWeight: "700" }} value={val.fileName}>{val.fileName}</a>
                        </p>
                      );
                    })}
                    <hr />
                    <hr />
                    Submitted Files:
                    <Card.Text><strong>Attachments:</strong></Card.Text>
                    {fileslist.map((val) => {
                      return (
                        <p>
                          <a onClick={() => { Downloadfile(val.fileName) }} name={val.fileName} style={{ textDecoration: "underline", color: "#0d6efd", fontWeight: "700" }} value={val.fileName}>{val.fileName}</a>
                        </p>
                      );
                    })}

                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
          {errorsubmitting && (
            <ConfirmAlert
              confirm={hideAlert}
              hideAlert={hideAlert}
              cancelButton={false}
              confirmBtnText="OK"
              type={"error"}
              title={"Error Timelimit Exceeded"}
              body={
                "Please reupload & resubmit the files again after clicking as timelimit of 1 hour has been exceeded."
              }
            />
          )}
        </div>
      )}
    </>
  )
}

export default ComplaintPage3