import React, { Component, useState, useEffect } from "react"
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

//import AxiosApi from "AxiosApi";
import AxiosApi from '../../../Api'
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.min.css";
import FileUpload from "./fileUpload";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {
    textFilter,
    // selectFilter,
} from "react-bootstrap-table2-filter";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { TimePickerComponent } from '@syncfusion/ej2-react-calendars';
// import 'antd/dist/antd.css';
// import './index.css';
// import { TimePicker } from 'antd';
// import TimePicker from 'react-time-picker';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import { getRoles } from "@testing-library/react";
// import "../style.css";
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import "../reqform.css";


const initialstate = {
    customer_address_error: "",
    location_error: "",
    country_origin_error: "",
    type_of_return_error: "",
    product_name_error: "",
    quantity_error: "",
    plant_name_error: "",
    fileuploaderror: "",
    containernumbererror: "",
    DemurrageError: "",
    DetentionError: "",
    StorageError: "",
    ETAError: "",
    DateofArrivalError: "",
    TimeOfArrivalError: "",
    AddClickError:""

}

const format = 'hh:mm';

const columns = [
    {
        dataField: "Id",
        text: "ID"
    },
    {
        dataField: "Product",
        text: "Product Name"
    },
    {
        dataField: "quantity",
        text: "Quantity"
    },
];
const customtoast = ({ }) => {
    return (
        <div>
            For these requests, please use
            <a href="mailto:tpexport_returns@ab-inbev.com">tpexport_returns@ab-inbev.com</a> mailbox
        </div>
    )
}

export class RequestForm extends Component {
    constructor(props) {
        super(props);

        this.onChangeException = this.onChangeException.bind(this);
        this.onChangeLocation = this.onChangeLocation.bind(this);
        this.onchangeCustomerAddress = this.onchangeCustomerAddress.bind(this);
        this.onchangeCustomerName = this.onchangeCustomerName.bind(this);
        this.onChangeVessel = this.onChangeVessel.bind(this);
        this.onChangePortofloading = this.onChangePortofloading.bind(this);
        this.onChangeBLNo = this.onChangeBLNo.bind(this);
        this.onChangeSealNo = this.onChangeSealNo.bind(this);
        this.onChangeComments = this.onChangeComments.bind(this);
        this.onChangeTypeOfReturn = this.onChangeTypeOfReturn.bind(this);
        this.onchangeCountryOrigin = this.onchangeCountryOrigin.bind(this);
        this.onchangeContainerNumber = this.onchangeContainerNumber.bind(this);
        this.onchangeShippingline = this.onchangeShippingline.bind(this);
        this.onchangeportofdischarge = this.onchangeportofdischarge.bind(this);
        this.onchangeETA = this.onchangeETA.bind(this);
        this.onchangedateofarrival = this.onchangedateofarrival.bind(this);
        this.onchangetimeofarrival = this.onchangetimeofarrival.bind(this);
        this.onchangeplanttoreturn = this.onchangeplanttoreturn.bind(this);
        this.getportofdischarge = this.getportofdischarge.bind(this);
        this.onchangeproductname = this.onchangeproductname.bind(this);
        this.onchangequantity = this.onchangequantity.bind(this);
        this.onchangeplantname = this.onchangeplantname.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onchangeDemurrage = this.onchangeDemurrage.bind(this);
        this.onchangeDetention = this.onchangeDetention.bind(this);
        this.onchangeStorage = this.onchangeStorage.bind(this);
        this.getcustomeraddressdata = this.getcustomeraddressdata.bind(this);
        this.getcustomernamedata = this.getcustomernamedata.bind(this);
        this.getsoldto = this.getsoldto.bind(this);
        this.onchangesoldto = this.onchangesoldto.bind(this);

        this.state = {
            isFormSubmitted: false,
            showPopup: false,
            blnohidedata: " hide_data",
            vesselhidedata: " hide_data",
            Port_of_loadingHidedata: " hide_data",
            Seal_noHidedata: " hide_data",
            container_numberhidedata: " hide_data",
            port_of_dischargehidedata: " hide_data",
            shipping_linehidedata: " hide_data",
            ETAhidedata: " hide_data",
            dateofarrivalhidedata: " hide_data",
            timeofarrivalhidedata: " hide_data",
            containerhidedata: " hide_data",
            truckhidedata: " hide_data",
            submitbtnhide: "",
            soldtodata: " hide_data",
            exception_change: "",
            location_change: "",
            customer_address: "",
            customer_name: "",
            emailid: this.props.emailid || JSON.stringify(localStorage.getItem('emailid')).replace(/['"]+/g, ''),
            userRole: this.props.userRole || JSON.stringify(localStorage.getItem('userRole')).replace(/['"]+/g, ''),
            vessel: "",
            port_of_loading: "",
            BL_no: "",
            Seal_no: "",
            Comments: "",
            type_of_return: "",
            country_origin: "",
            country_origin_selected: "",
            container_number: "",
            shipping_line: "",
            port_of_discharge: "",
            ETA: "",
            dateofarrival: "",
            timeofarrival: "",
            plant_to_return: "",
            port_of_discharge_selected: "",
            product_name: "",
            product_name_selected: "",
            quantity: "",
            quantity_changed: "",
            plant_name: "",
            plant_name_selected: "",
            Storage: "",
            Detention: "",
            Demurrage: "",
            uploadedFile: "",
            totalfilecount: "",
            origindivision: "",
            soldto: "",
            soldto_selected: "",
            filesData: [],
            nonSelected: [],
            selected: [],
            products: [],
            isHidden: true,
            initstate: initialstate,
        };
    }

    handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            this.setState(() => ({
                selected: [...this.state.selected, row.Id],
            }));
        } else {
            this.setState(() => ({
                selected: this.state.selected.filter((x) => x !== row.Id),
            }));
        }
    };

    handleOnSelectAll = (isSelect, rows) => {
        const ids = rows.map((r) => r.Id);
        if (isSelect) {
            this.setState(() => ({
                selected: ids,
            }));
        } else {
            this.setState(() => ({
                selected: [],
            }));
        }
    };


    handleFileUpload(val) {
        console.log(val);
        console.log("file length:" + val.length);
        var FileName = "";
        for (var i = 0; i < val.length; i++)
            if (i == 0) { FileName = val[i].name; }
            else {
                FileName = FileName + "," + val[i].name;
            }
        console.log(FileName)

        this.setState({ uploadedFile: FileName, totalfilecount: val.length });
        console.log(this.state.totalfilecount)
    }

    togglePopup() {
        //this.state.type_of_return,this.state.container_number,this.state.dateofarrival
        if (this.state.location_change != '' && this.state.customer_name != '' &&
            ((this.state.type_of_return === 'Container' && this.state.container_number !== '') || (this.state.type_of_return === 'Truck' && this.state.dateofarrival !== ''))) {
            console.log("in condition");
            this.setState({
                showPopup: !this.state.showPopup,
            });
        } else {
            toast.warning("Please enter mandatory values");
        }

    }

    componentDidMount() {
        console.log("this called");
        var locastateemail = JSON.stringify(localStorage.getItem('emailid')).replace(/['"]+/g, '');
        console.log(locastateemail);

        console.log(this.props.userRole);
        var localuserRole = JSON.stringify(localStorage.getItem('userRole')).replace(/['"]+/g, '');

        console.log("localuerrole =====" + localuserRole);

        this.setState({
            userRole: localuserRole,
        });

        console.log("props role =====" + this.state.userRole);
        this.setState({
            emailid: locastateemail,
        });

        console.log("LOCAL ASSIGN" + this.state.emailid)
        this.getdata();
        console.log(this.state.emailid);
        console.log("LOCAL ASSIGN" + this.state.emailid)
        this.getRoles({ emailid: this.state.emailid });
    }

    getRoles = (emailid) => {
        console.log(emailid)
        AxiosApi
            .post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/filterDataret/togetuserrole", emailid)
            .then((res) => {
                console.log("role" + res.data);
                console.log("role" + res.data[0].label);
                this.setState({
                    userRole: res.data[0].label
                });
                localStorage.setItem("userRole", this.state.userRole);
                console.log(this.state.userRole);
                if (res.data[0].label == 2 || res.data[0].label == 4) {
                    this.getcustomeraddressdata({ emailid: this.state.emailid });
                    this.getcustomernamedata({ emailid: this.state.emailid });
                    this.getcustomersoldto({ emailid: this.state.emailid });
                }
                if (res.data[0].label == 1 || res.data[0].label == 3) {
                    this.getsoldto();
                }
            })
            .catch((err) => {
                console.log(err)
                console.log("User doesnot exist in the Role table. User role : Requestor");
                this.setState({ isReady: true })
            });
    }


    onchangesoldto(e) {
        console.log("soldto" + e.label);
        this.setState({
            soldto_selected: e.label,
        });
        var soldtovalue = e.label;
        this.getcustomernamebysoldto({ soldto: soldtovalue })
        this.getcustomeraddressbysoldto({ soldto: soldtovalue });
    }

    onchangeplantname(e) {
        console.log("plant" + e.label);
        this.setState({
            plant_name_selected: e.label,
        });
    }

    onchangeproductname(e) {
        console.log("product" + e.label);
        this.setState({
            product_name_selected: e.label,
        });
    }

    onchangequantity(e) {
        console.log(e.target.value);
        this.setState({
            quantity: e.target.value,
        });
    }

    onchangeDemurrage(e) {
        console.log(e.target.value);
        this.setState({
            Demurrage: e.target.value,
        });
    }

    onchangeDetention(e) {
        console.log(e.target.value);
        this.setState({
            Detention: e.target.value,
        });
    }

    onchangeStorage(e) {
        console.log(e.target.value);
        this.setState({
            Storage: e.target.value,
        });
    }

    onchangeplanttoreturn(e) {
        console.log("plant to return" + e.label);
        this.setState({
            plant_to_return: e.label,
        });
    }

    onchangeContainerNumber(e) {
        console.log(e.target.value);
        this.setState({
            container_number: e.target.value,
        });
    }

    onchangedateofarrival(e) {
        console.log(e.target.value);
        this.setState({
            dateofarrival: e.target.value,
        });
    }

    onchangetimeofarrival(e) {
        // console.log(e.target.value);
        // console.log(e);
        this.setState({
            timeofarrival: e.target.value,
            // timeofarrival: e,
        });
    }

    onchangeShippingline(e) {
        console.log(e.target.value);
        this.setState({
            shipping_line: e.target.value,
        });
    }

    onchangeportofdischarge(e) {
        console.log("port of discharge" + e.label);
        this.setState({
            port_of_discharge_selected: e.label,
        })
    }

    onchangeETA(e) {
        console.log(e.target.value);
        this.setState({
            ETA: e.target.value,
        });
    }

    onchangeCountryOrigin(e) {
        console.log("country origin" + e.label);
        this.setState({
            country_origin_selected: e.label,
        });
        var data1 = { countryorigin: e.label }

        console.log(data1)
        console.log(this.state.location_change)

        if (this.state.location_change == 'Belgium') {
            console.log(data1);
            AxiosApi
                .post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/filterDataret/findcountryorigindivison", data1)
                .then((res) => {
                    console.log(res.data);
                    if (res.data.length > 0) {
                        console.log(res.data[0].label);
                        if (res.data[0].label === null) {
                            this.setState({
                                origindivision: "",
                            });
                        } else {
                            this.setState({
                                origindivision: res.data[0].label,
                            });
                            var spData2 = { division: res.data[0].label, location: this.state.location_change }
                            console.log(spData2)
                            AxiosApi
                                .post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/filterDataret/findbelgiumproductname", spData2)
                                .then((res) => {
                                    console.log(res.data)
                                    if (res.data.length > 0) {
                                        console.log(res.data);
                                        this.setState({
                                            product_name: res.data,
                                        });
                                    }
                                })
                        }
                    } else {
                        toast.warning("No data found for the country origin");
                    }
                })
        }
    }

    onChangeTypeOfReturn(e) {
        console.log("type of return" + e.label);
        this.setState({
            type_of_return: e.label,
        });

        var data1 = { location: this.state.location_change, typeofreturn: e.label };
        console.log(data1)
        console.log(data1.typeofreturn.length)
        console.log(data1.location.length)
        if (data1.location.length !== 0 && data1.typeofreturn.length !== 0) {
            console.log("plant")
            this.getPlantdata(data1)
        }

        if (e.value === 1) {
            this.setState({
                containerhidedata: "containers_css",
            });
        } else {
            this.setState({
                containerhidedata: " hide_data",
            });
        }
        console.log(this.state.location_change)
        if (e.value === 1 && this.state.location_change === 'Germany' || this.state.location_change === 'UK') {
            this.setState({
                vesselhidedata: "",
            });
        } else {
            this.setState({
                vesselhidedata: " hide_data",
            });
        }

        if (e.value === 2) {
            this.setState({
                truckhidedata: "",
            });
        } else {
            this.setState({
                truckhidedata: " hide_data",
            });
        }
    }

    onChangeVessel(e) {
        console.log(e.target.value);
        this.setState({
            vessel: e.target.value,
        });
    }
    onChangePortofloading(e) {
        console.log(e.target.value);
        this.setState({
            port_of_loading: e.target.value,
        });
    }
    onChangeBLNo(e) {
        console.log(e.target.value);
        this.setState({
            BL_no: e.target.value,
        });
    }
    onChangeSealNo(e) {
        console.log(e.target.value);
        this.setState({
            Seal_no: e.target.value,
        });
    }
    onChangeComments(e) {
        console.log(e.target.value);
        this.setState({
            Comments: e.target.value,
        });
    }

    onchangeCustomerName(e) {
        console.log(e.target.value);
        console.log(this.props);
        this.setState({

            customer_name: e.label,
        });
    }

    onChangeException(e) {
        console.log("exception" + e.label);
        this.setState({
            exception_change: e.label,
        });

        if (e.label == "Yes") {
            console.log(e.label)
            toast.warn(customtoast);
            this.setState({
                submitbtnhide: " hide_data",
            });
        } else {
            this.setState({
                submitbtnhide: "",
            });
        }

    }

    onChangeLocation(e) {
        console.log("location " + e.label);
        this.setState({
            location_change: e.label,
        });
        var data = { 'value': e.label };
        var data1 = { location: e.label, 'typeofreturn': this.state.type_of_return };
        var spdata = { countryorigin: this.state.country_origin_selected }
        console.log(spdata)
        console.log(data1)

        this.getportofdischarge(data)

        console.log(e.label === 'Belgium')
        if (e.label === 'Belgium') {

            console.log(data1);
            AxiosApi
                .post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/filterDataret/findcountryorigindivison", spdata)
                .then((res) => {
                    console.log(res.data);
                    if (res.data.length > 0) {
                        console.log(res.data[0].label);
                        if (res.data[0].label === null) {
                            this.setState({
                                origindivision: "",
                            });
                        } else {
                            this.setState({
                                origindivision: res.data[0].label,
                            });
                            var spData2 = { division: res.data[0].label, location: data.value }
                            console.log(spData2)
                            AxiosApi
                                .post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/filterDataret/findbelgiumproductname", spData2)
                                .then((res) => {
                                    console.log(res.data)
                                    if (res.data.length > 0) {
                                        console.log(res.data);
                                        this.setState({
                                            product_name: res.data,
                                        });
                                    }
                                })
                        }
                    } else {
                        //toast.warning("No data found for the country origin");
                    }
                })


        } else {
            this.getproductname(data)
        }

        if (data1.location.length !== 0 && this.state.type_of_return.length !== 0) {
            console.log(data1)
            this.getPlantdata(data1)
        }

        if (e.value === 2 || e.value === 3) {
            this.setState({
                vesselhidedata: "",
            });
        } else {
            this.setState({
                vesselhidedata: " hide_data",
            });
        }
        if (e.value === 3) {
            this.setState({
                Port_of_loadingHidedata: "",
                blnohidedata: "",
            });
        } else {
            this.setState({
                Port_of_loadingHidedata: " hide_data",
                blnohidedata: " hide_data",
            });
        }
        if (e.value === 4) {
            this.setState({
                Seal_noHidedata: "",
            });
        } else {
            this.setState({
                Seal_noHidedata: " hide_data",
            });
        }

        this.setState({
            products: [],
        })
    }

    onchangeCustomerAddress(e) {
        console.log(e.target.value);
        this.setState({
            customer_address: e.label,
        });
    }


    // getcountryorigindivison(data){
    //     console.log(data);
    //     AxiosApi
    //     .post("http://localhost:9000/api/filterDataret/findcountryorigindivison",data)
    //     .then((res) => 
    //     {console.log(res.data);
    //         console.log(res.data.length);
    //           this.setState({
    //             origindivision: res.data,
    //           });
    //     })
    // }

    getdata() {
        AxiosApi
            .get(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/filterDataret/findcountry")
            .then((res) => {
                this.setState({
                    country_origin: res.data,
                });
            })
    }


    getsoldto() {
        AxiosApi
            .get(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/filterDataret/getsoldto")
            .then((res) => {
                console.log(res.data)
                this.setState({
                    soldto: res.data,
                });
            })
    }

    getcustomernamebysoldto(soldto) {
        console.log(soldto)
        AxiosApi
            .post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/filterDataret/getcustomernameonsoldto", soldto)
            .then((res) => {
                this.setState({
                    customer_name: res.data[0].label,
                    // customer_name: "kushal",
                });
            })

    }

    getcustomeraddressbysoldto(soldto) {
        console.log(soldto)
        AxiosApi
            .post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/filterDataret/getcustomeraddressonsoldto", soldto)
            .then((res) => {
                this.setState({
                    customer_address: res.data[0].label,
                });
            })
    }

    getcustomernamedata(emailid) {
        console.log(emailid)
        AxiosApi
            .post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/filterDataret/customername", emailid)
            .then((res) => {
                // console.log(res.data[0].label)
                // console.log(res.data)
                this.setState({
                    customer_name: res.data[0].label,
                });
            })
    }

    getcustomeraddressdata(emailid) {
        console.log(emailid)
        AxiosApi
            .post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/filterDataret/customeraddress", emailid)
            .then((res) => {
                console.log(res);
                this.setState({
                    customer_address: res.data[0].label,
                });
            })
    }

    getportofdischarge(data) {
        // console.log(data);
        AxiosApi
            .post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/filterDataret/findportofdischarge", data)
            .then((res) => {
                console.log(res.data);
                console.log(res.data.length);
                this.setState({
                    port_of_discharge: res.data,
                });
            })
    }

    getproductname(data) {
        console.log(data);
        AxiosApi
            .post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/filterDataret/findproductname", data)
            .then((res) => {
                console.log(res.data);
                console.log(res.data.length);
                this.setState({
                    product_name: res.data,
                });
            })
    }

    getPlantdata(data1) {
        console.log(data1);
        AxiosApi
            .post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/filterDataret/findplant", data1)
            .then((res) => {
                console.log(res.data);
                console.log(res.data.length);
                this.setState({
                    plant_name: res.data,
                });
            })
    }

    onCancel = () => {
        window.location.reload(true);
    }


    getcustomersoldto = (emailId) => {
        AxiosApi
            .post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/filterDataret/getcustomersoldtoonemail", emailId)
            .then((res) => {
                console.log(res.data)
                this.setState({
                    soldto_selected: res.data[0].label,
                });
                console.log(this.state.soldto_selected);
            })
    }
    newrequestionttable() {
        console.log();
        AxiosApi
            .post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/add/new", this.state)
            .then((res) => {
                toast.success("Request raised successfully.");
                setTimeout(() => window.location.reload(), 1000);
            })
            .catch((err) => {
                console.log(err);
                toast.error("Error in upload.");
            });
    }
    // process.env.REACT_APP_API_ROOT_URL_PREFIX +
    // http://localhost:9000/api/add/TableRequest
    async newrequestionttable1() {
        // console.log(this.state.uploadedFile);
        const myemail = localStorage.getItem('emailid');
        console.log('myemail: ', myemail);
        console.log('api hit requestform.js line no 797');
        await AxiosApi
            .post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/add/TableRequest", {
                location_change: this.state.location_change,
                country_origin_selected: this.state.country_origin_selected,
                customer_name: this.state.customer_name,
                customer_address: this.state.customer_address,
                type_of_return: this.state.type_of_return,
                product_name_selected: this.state.product_name_selected,
                quantity: this.state.quantity,
                plant_name_selected: this.state.plant_name_selected,
                container_number: this.state.container_number,
                port_of_discharge_selected: this.state.port_of_discharge_selected,
                shipping_line: this.state.shipping_line,
                vessel: this.state.vessel,
                port_of_loading: this.state.port_of_loading,
                BL_no: this.state.BL_no,
                Seal_no: this.state.Seal_no,
                ETA: this.state.ETA,
                dateofarrival: this.state.dateofarrival,
                timeofarrival: this.state.timeofarrival,
                Comments: this.state.Comments,
                products: this.state.products,
                FileUploaded: this.state.uploadedFile,
                demurrage: this.state.Demurrage,
                detention: this.state.Detention,
                storage: this.state.Storage,
                soldto: this.state.soldto_selected,
                email: localStorage.getItem('emailid'),
            })
            .then((res) => {
                console.log("Final Data:", res.data);
                toast.success("Request raised successfully.");
                // window.location.reload(true);
                // setTimeout(() => window.location.reload(), 1000);
                // window.location.reload();
            })
            .catch((err) => {
                console.log("api/add/TableRequest " + err);
            })

        // process.env.REACT_APP_API_ROOT_URL_PREFIX
        // AxiosApi.post new
        // console.log('new API is called')
        // AxiosApi
        //     .post("http://localhost:9000/api/add/new1", {
        //         email: myemail
        //     })
        //     .then((res) => {
        //         console.log(res.data);
        //         toast.success("Email send successfully.");
        //     })
        //     .catch((err) => {
        //         console.log("/api/add/new1" + err); 
        //     })
    }

    onSubmit = () => {
        this.setState({ isFormSubmitted: true });
        const isValid = this.validationcheck();
        if (isValid) {
            this.setState(this.state.initstate);
            this.newrequestionttable1();
        }
        this.setState({ isFormSubmitted: false ,isAddClick:false});
    }

    validationcheck = () => {
        let customer_address_error = "";
        let location_error = "";
        let country_origin_error = "";
        let type_of_return_error = "";
        let product_name_error = "";
        let quantity_error = "";
        let plant_name_error = "";
        let fileuploaderror = "";
        let containernumbererror = "";
        let DemurrageError = "";
        let DetentionError = "";
        let StorageError = "";
        let ETAError = "";
        let DateofArrivalError = "";
        let TimeOfArrivalError = "";
        let AddClickError="";
        var regex = " /^[0-9a-zA-Z]+$/";
        var quantityregex = "^[0-9]+$";

        if (!this.state.customer_address) {
            customer_address_error = "Customer address cannot be blank";
        }

        console.log(this.state.location_change.length === 0)
        if (this.state.location_change.length === 0) {
            location_error = "Please select location";
        }
        if(!this.state.isAddClick){
            AddClickError="Please click on add button the Products cannot be empty"
        }


        if (this.state.country_origin_selected.length === 0) {
            country_origin_error = "Please select country origin";
        }

        if (this.state.type_of_return.length === 0) {
            type_of_return_error = "Please select type of return";
        }

        if (this.state.product_name.length === 0) {
            product_name_error = "Please select product name";
        }

        if (!this.state.container_number.match(quantityregex) && !this.state.quantity) {
            quantity_error = "Quantity cannot be blank and numbers are allowed";
        }

        if (this.state.plant_name_selected.length === 0) {
            plant_name_error = "Please select plant location to return";
        }


        if (this.state.totalfilecount.length === 0) {
            fileuploaderror = "Please upload at least 2 files";
        }

        console.log(this.state.container_number.length)
        if (this.state.type_of_return == 'Container') {
            console.log(this.state.type_of_return)

            if (!this.state.container_number.match(regex) && this.state.container_number.length !== 11) {
                containernumbererror = "11 digits required and special charaters are not allowed";
            }
            console.log(this.state.Demurrage.length)
            if (!this.state.Demurrage.match(DemurrageError) && this.state.Demurrage.length == 0) {
                DemurrageError = "Demurrage cannot be blank and numbers are allowed";
            }
            console.log(this.state.Detention.length)
            if (!this.state.Detention.match(DetentionError) && this.state.Detention.length == 0) {
                DetentionError = "Detention cannot be blank and numbers are allowed";
            }
            console.log(this.state.Storage.length)
            if (!this.state.Storage.match(StorageError) && this.state.Storage.length == 0) {
                StorageError = "Storage cannot be blank and numbers are allowed";
            }

            console.log(this.state.ETA.length)
            if (this.state.ETA.length == 0) {
                ETAError = "ETA cannot be blank";
            }

        }

        if (this.state.type_of_return == 'Truck') {

            console.log(this.state.dateofarrival.length)
            if (this.state.dateofarrival.length == 0) {
                DateofArrivalError = "Date arrival cannot be blank";
            }

            //console.log(this.state.timeofarrival.length)
            if (!this.state.timeofarrival || this.state.timeofarrival == 'null') {
                TimeOfArrivalError = "Time if arrival cannot be blank";
            }
        }

         if (customer_address_error || location_error || country_origin_error || type_of_return_error || product_name_error || quantity_error || plant_name_error || fileuploaderror || containernumbererror || DetentionError || DemurrageError || StorageError || ETAError || DateofArrivalError || TimeOfArrivalError||AddClickError) {
            this.setState({ customer_address_error, location_error, country_origin_error, type_of_return_error, product_name_error, quantity_error, plant_name_error, fileuploaderror, containernumbererror, DetentionError, DemurrageError, StorageError, ETAError, DateofArrivalError, TimeOfArrivalError ,AddClickError});
            return false;
        } else {
            return true;
        }

    }

    onAdd() {
        this.setState({isAddClick:true});
        console.log(this.state.products.length);
        var count = this.state.products.length + 1
        // var quantityregex = "^[0-9]+$";
        let quantity_error = "";

        var quantityregex = new RegExp("/^[0-9\b]+$/");
        let isValid = true;

        console.log("inside")
        if (!quantityregex.test(this.state.quantity)) {
            isValid = false;
            quantityregex = "Only numbers are allowed."
        }

        if (this.state.quantity.length == 0) {
            quantityregex = "Quantity cannot be blank";
        }

        if (quantity_error) {
            this.setState({ quantity_error });
            return false;
        } else {

        }

        if (this.state.product_name_selected !== "" && this.state.quantity !== "") {

            this.setState(x => ({ products: [...x.products, { Id: count, Product: this.state.product_name_selected, quantity: this.state.quantity }] }))

        }
        console.log(this.state.products.length)
    }

    onDelete() {
        var var1 = this.state.products;
        console.log(this.state.selected);
        console.log(var1.Id);
        const newList = var1.filter((var1) => false == this.state.selected.includes(var1.Id));
        //const newList = list.filter((item) => item.id !== id);

        console.log(newList)
        this.setState(() => ({
            products: newList,
        }));
    }

    render() {
        const selectRow = {
            mode: "checkbox",
            clickToSelect: true,
            selected: this.state.selected,
            //nonSelectable: this.state.nonSelected,
            onSelect: this.handleOnSelect,
            onSelectAll: this.handleOnSelectAll,
        };
        const ExceptionSelect = [
            { label: "Yes", value: 1 },
            { label: "No", value: 2 },
        ];
        const locationselect = [
            { label: "Belgium", value: 1 },
            { label: "Germany", value: 2 },
            { label: "UK", value: 3 },
            { label: "US", value: 4 },
        ];
        const typeofreturn = [
            { label: "Container", value: 1 },
            { label: "Truck", value: 2 },
        ];

        const checkRole = this.state.userRole;

        if (this.props.isReady) {
            return (

                <div className="container my-3 box_wrapper">

                    <div className="retpack_box">
                        <h4>Request Form</h4>
                        <h6 style={{ color: "red", textAlign: "left !important", float: "left" }}> * Mandatory fields</h6>
                        {(checkRole == 1 || checkRole == 3) &&

                            <div className="input_wrapper">
                                <label className="fresh-label" for="fresh-request_type">
                                    <span className="fresh-title">
                                        <span className="astrik"></span>Sold To
                                    </span>
                                </label>
                                <Select className="retpack_input" options={this.state.soldto} onChange={this.onchangesoldto} />
                            </div>

                        }
                        <div className="input_wrapper">
                            <label className="fresh-label" for="fresh-request_type">
                                <span className="fresh-title">
                                    <span className="astrik"></span>Customer Name
                                </span>
                            </label>
                            {checkRole == 1 || checkRole == 3 || checkRole == 5 ?
                                <input
                                    id="fresh-sales_order-user-profile"
                                    className="retpack_input"
                                    onChange={this.onchangeCustomerName}
                                    required={true}
                                    type="text"
                                    readOnly={true}
                                    value={this.state.customer_name}
                                ></input> :
                                <input
                                    id="fresh-sales_order-user-profile"
                                    className="retpack_input"
                                    onChange={this.onchangeCustomerName}
                                    required={true}
                                    type="text"
                                    readOnly={true}
                                    value={this.state.customer_name}
                                ></input>}
                        </div>
                        <div className="input_wrapper">
                            <label className="fresh-label" for="fresh-request_type">
                                <span className="fresh-title">
                                    <span className="astrik"> * </span>Customer Address
                                </span>
                            </label>
                            {checkRole == 1 || checkRole == 3 || checkRole == 5 ?
                                <input
                                    id="fresh-sales_order-user-profile"
                                    className="retpack_input"
                                    onChange={this.onchangeCustomerAddress}
                                    required={true}
                                    type="text"
                                    readOnly={true}
                                    value={this.state.customer_address}
                                ></input>
                                :
                                <input
                                    id="fresh-sales_order-user-profile"
                                    className="retpack_input"
                                    onChange={this.onchangeCustomerAddress}
                                    required={true}
                                    type="text"
                                    readOnly={true}
                                    value={this.state.customer_address}
                                ></input>}
                        </div>
                        <div style={{ fontSize: 12, color: "red" }}>
                            {this.state.customer_address_error}
                        </div>

                        <div className="input_wrapper">
                            <label className="fresh-label" for="fresh-request_type">
                                <span className="fresh-title">
                                    <span className="astrik"> * </span>Location
                                </span>
                            </label>
                            <Select options={locationselect} onChange={this.onChangeLocation} />
                        </div>
                        <div style={{ fontSize: 12, color: "red" }}>
                            {this.state.location_error}
                        </div>

                        <div className="input_wrapper">
                            <label className="fresh-label" for="fresh-request_type">
                                <span className="fresh-title">
                                    <span className="astrik"> * </span>Country of origin
                                </span>
                            </label>
                            <Select options={this.state.country_origin} onChange={this.onchangeCountryOrigin} />
                        </div>
                        <div style={{ fontSize: 12, color: "red" }}>
                            {this.state.country_origin_error}
                        </div>

                        <div className="input_wrapper">
                            <label className="fresh-label" for="fresh-request_type">
                                <span className="fresh-title">
                                    <span className="astrik">*</span>Type of return
                                </span>
                            </label>
                            <Select options={typeofreturn} onChange={this.onChangeTypeOfReturn} />
                        </div>
                        <div style={{ fontSize: 12, color: "red" }}>
                            {this.state.type_of_return_error}
                        </div>

                        <div className="input_wrapper">
                            <label className="fresh-label" for="fresh-request_type">
                                <span className="fresh-title">
                                    <span className="astrik"> </span>Exception
                                    <h9 className="astrik" > (* E.g Full, Faulty, Expired or Foreign Kegs) </h9>
                                </span>
                            </label>
                            <Select options={ExceptionSelect} onChange={this.onChangeException}
                                defaultValue={{ label: "No", value: 2 }} />

                        </div>

                        <div className="input_wrapper">
                            <label className="fresh-label" for="fresh-request_type">
                                <span className="fresh-title">
                                    <span className="astrik"> * </span>Product Name
                                </span>
                            </label>
                            <Select options={this.state.product_name} onChange={this.onchangeproductname} />
                        </div>
                        <div style={{ fontSize: 12, color: "red" }}>
                            {this.state.product_name_error}
                        </div>

                        <div className="input_wrapper">
                            <label className="fresh-label" for="fresh-request_type">
                                <span className="fresh-title">
                                    <span className="astrik"> * </span>Quantity
                                </span>
                            </label>
                            <input
                                id="fresh-quantity"
                                className="retpack_input"
                                onChange={this.onchangequantity}
                                required={true}
                                type="number" min="0"
                                value={this.state.quantity}
                            ></input>
                        </div>
                        <div style={{ fontSize: 12, color: "red" }}>
                            {this.state.quantity_error}
                        </div>
                        <div>
                            <button className="retpack_button mr-2"
                                onClick={this.onAdd}
                            >
                                Add</button>
                            <button className="retpack_button mr-2"
                                onClick={this.onDelete}
                            >
                                Delete</button>
                        </div>
                         <div style={{ fontSize: 12, color: "red" }}>
                            {this.state.AddClickError}
                        </div>
                        <div style={{ padding: "20px" }}>
                            <h4>Products</h4>
                            <BootstrapTable
                                bootstrap4
                                keyField="Id"
                                data={this.state.products}
                                columns={columns}
                                // filter={filterFactory()}
                                // pagination={ paginationFactory() }
                                selectRow={selectRow}
                                headerClasses="cust_header-class"
                            />
                        </div>
                        <div className="input_wrapper">
                            <label className="fresh-label" for="fresh-request_type">
                                <span className="fresh-title">
                                    <span className="astrik"> * </span>Plant location to return
                                </span>
                            </label>
                            <Select options={this.state.plant_name} onChange={this.onchangeplantname} />
                        </div>
                        <div style={{ fontSize: 12, color: "red" }}>
                            {this.state.plant_name_error}
                        </div>

                        <div className={this.state.vesselhidedata}>
                            <div className="input_wrapper">
                                <label className="fresh-label" for="fresh-request_type">
                                    <span className="fresh-title">
                                        <span className="astrik"></span>Vessel
                                    </span>
                                </label>
                                <input
                                    id="fresh-vessel"
                                    className="retpack_input"
                                    onChange={this.onChangeVessel}
                                    required={true}
                                    type="text"
                                    value={this.state.vessel}
                                ></input>
                            </div>
                        </div>

                        <div className={this.state.Port_of_loadingHidedata}>
                            <div className="input_wrapper">
                                <label className="fresh-label" for="fresh-request_type">
                                    <span className="fresh-title">
                                        <span className="astrik"></span>Port of loading
                                    </span>
                                </label>
                                <input
                                    id="fresh-port-of-loading"
                                    className="retpack_input"
                                    onChange={this.onChangePortofloading}
                                    required={true}
                                    type="text"
                                    value={this.state.port_of_loading}
                                ></input>
                            </div>
                        </div>
                        <div className={this.state.blnohidedata}>
                            <div className="input_wrapper">
                                <label className="fresh-label" for="fresh-request_type">
                                    <span className="fresh-title">
                                        <span className="astrik"></span>BL Number
                                    </span>
                                </label>
                                <input
                                    id="fresh-BL_Number"
                                    className="retpack_input"
                                    onChange={this.onChangeBLNo}
                                    required={true}
                                    type="text"
                                    value={this.state.BL_no}
                                ></input>
                            </div>
                        </div>
                        <div className={this.state.Seal_noHidedata}>
                            <div className="input_wrapper">
                                <label className="fresh-label" for="fresh-request_type">
                                    <span className="fresh-title">
                                        <span className="astrik"></span>Seal Number
                                    </span>
                                </label>
                                <input
                                    id="fresh-seal_number"
                                    className="retpack_input"
                                    onChange={this.onChangeSealNo}
                                    required={true}
                                    type="text"
                                    value={this.state.Seal_no}
                                ></input>
                            </div>
                        </div>
                        {/* anas */}
                        {
                            this.state.containerhidedata == "containers_css" &&
                            <div style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                // background: "gray",
                            }}>
                                <h3>Containers</h3>
                                <div className="input_wrapper">
                                    <label className="fresh-label" for="fresh-request_type">
                                        <span className="fresh-title">
                                            <span className="astrik"> * </span>Container Number
                                        </span>
                                    </label>
                                    <input
                                        id="fresh-container-number"
                                        className="retpack_input"
                                        onChange={this.onchangeContainerNumber}
                                        required={true}
                                        type="text"
                                        value={this.state.container_number}
                                    ></input>
                                    <div style={{ fontSize: 12, color: "red" }}>
                                        {this.state.containernumbererror}
                                    </div>
                                </div>

                                <div className="input_wrapper">
                                    <label className="fresh-label" for="fresh-request_type">
                                        <span className="fresh-title">
                                            <span className="astrik"></span>Port of Discharge
                                        </span>
                                    </label>
                                    <Select options={this.state.port_of_discharge} onChange={this.onchangeportofdischarge} />
                                </div>
                                <div className="input_wrapper">
                                    <label className="fresh-label" for="fresh-request_type">
                                        <span className="fresh-title">
                                            <span className="astrik"></span>Shipping Line
                                        </span>
                                    </label>
                                    <input
                                        id="fresh-shippingline"
                                        className="retpack_input"
                                        onChange={this.onchangeShippingline}
                                        required={true}
                                        type="text"
                                        value={this.state.shipping_line}
                                    ></input>
                                </div>

                                <div className="input_wrapper">
                                    <label className="fresh-label" for="fresh-request_type">
                                        <span className="fresh-title">
                                            <span className="astrik"> * </span> Free Demurrage Days (POD)
                                        </span>
                                    </label>
                                    <input
                                        id="fresh-shippingline"
                                        className="retpack_input"
                                        onChange={this.onchangeDemurrage}
                                        required={true}
                                        type="text"
                                        value={this.state.Demurrage}
                                    ></input>


                                    <div style={{ fontSize: 12, color: "red" }}>
                                        {this.state.DemurrageError}
                                    </div>
                                </div>

                                <div className="input_wrapper">
                                    <label className="fresh-label" for="fresh-request_type">
                                        <span className="fresh-title">
                                            <span className="astrik"> * </span> Free Detention Days (POD)
                                        </span>
                                    </label>
                                    <input
                                        id="fresh-shippingline"
                                        className="retpack_input"
                                        onChange={this.onchangeDetention}
                                        required={true}
                                        type="text"
                                        value={this.state.Detention}
                                    ></input>


                                    <div style={{ fontSize: 12, color: "red" }}>
                                        {this.state.DetentionError}
                                    </div>
                                </div>

                                <div className="input_wrapper">
                                    <label className="fresh-label" for="fresh-request_type">
                                        <span className="fresh-title">
                                            <span className="astrik"> * </span> Free Storage Days (POD)
                                        </span>
                                    </label>
                                    <input
                                        id="fresh-shippingline"
                                        className="retpack_input"
                                        onChange={this.onchangeStorage}
                                        required={true}
                                        type="text"
                                        value={this.state.Storage}
                                    ></input>


                                    <div style={{ fontSize: 12, color: "red" }}>
                                        {this.state.StorageError}
                                    </div>
                                </div>
                                <div className="input_wrapper">
                                    <label className="fresh-label" for="fresh-request_type">
                                        <span className="fresh-title">
                                            <span className="astrik"></span>ETA
                                        </span>
                                    </label>
                                    <input
                                        id="fresh-ETA"
                                        className="retpack_input"
                                        onChange={this.onchangeETA}
                                        required={true}
                                        type="date"
                                        value={this.state.ETA}
                                    ></input>

                                    <div style={{ fontSize: 12, color: "red" }}>
                                        {this.state.ETAError}
                                    </div>
                                </div>
                            </div>

                        }

                        <div className={this.state.truckhidedata}>
                            <h3>Trucks</h3>
                            <div className="input_wrapper">
                                <label className="fresh-label" for="fresh-request_type">
                                    <span className="fresh-title">
                                        <span className="astrik"></span>Date of arrival
                                    </span>
                                </label>
                                <input
                                    id="fresh-date-of-arrival"
                                    className="retpack_input"
                                    onChange={this.onchangedateofarrival}
                                    type="date"
                                    value={this.state.dateofarrival}
                                ></input>
                                <div style={{ fontSize: 12, color: "red" }}> {this.state.DateofArrivalError} </div>
                            </div>
                            <div className="input_wrapper">
                                <label className="fresh-label" for="fresh-request_type">
                                    <span className="fresh-title">
                                        <span className="astrik"></span>Time of arrival
                                    </span>
                                </label>
                                <div>
                                    {/* <TimePicker className="retpack_input"
                                        defaultValue={moment('08:00', format)}
                                        minuteStep={30} format={format}

                                        onChange={this.onchangetimeofarrival}
                                        value={this.state.timeofarrival}
                                        
                                    /> */}
                                    <input
                                        id="fresh-date-of-arrival"
                                        className="retpack_input"
                                        onChange={this.onchangetimeofarrival}
                                        type="time"
                                        value={this.state.timeofarrival}
                                    ></input>
                                </div>
                                <div style={{ fontSize: 12, color: "red" }}> {this.state.TimeOfArrivalError} </div>
                            </div>
                        </div>
                        <div className="input_wrapper">
                            <label className="fresh-label" for="fresh-request_type">
                                <span className="fresh-title">
                                    <span className="astrik"></span>Comments
                                </span>
                            </label>
                            <input
                                id="fresh-comments"
                                className="retpack_input"
                                onChange={this.onChangeComments}
                                required={true}
                                type="text"
                                value={this.state.Comments}
                            ></input>
                        </div>
                        <div className="input_wrapper">
                            <label className="fresh-label" for="fresh-request_type">
                                <span className="fresh-title">
                                    <span className="astrik"> * </span>Attachments
                                </span>
                                <p id="retpack_info"> * Please, upload separately the documents.
                                    Containers- Bill of Lading and Invoice
                                    Trucks - CMR andinj Invoice
                                </p>
                            </label>
                            <button
                                type="button"
                                className="retpack_button"
                                onClick={this.togglePopup.bind(this)}
                            >
                                Upload requested documents
                            </button>

                            <div className="UploaderNreview">
                                {this.state.showPopup ? (
                                    <FileUpload
                                        id="FileUpload"
                                        text='Click "Close Button" to hide popup'
                                        closePopup={this.togglePopup.bind(this)}
                                        handleFileUpload={this.handleFileUpload}
                                        requiredData={{
                                            location_change: this.state.location_change,
                                            country_origin_selected: this.state.country_origin_selected,
                                            customer_name: this.state.customer_name,
                                            type_of_return: this.state.type_of_return,
                                            container_number: this.state.container_number,
                                            dateofarrival: this.state.dateofarrival,

                                        }}
                                    />
                                ) : null}
                            </div>
                            <label>
                                {this.state.uploadedFile}
                            </label>
                        </div>
                        <div style={{ fontSize: 12, color: "red" }}>
                            {this.state.fileuploaderror}
                        </div>
                        {/* <div className={this.state.submitbtnhide}>
                            <button
                                className="retpack_button mr-2"
                                onClick={this.onSubmit}
                            >
                                Submit test
                            </button>

                            <button
                                className="retpack_button mr-2"
                                onClick={this.onCancel}
                            >
                                Cancel
                            </button>
                        </div> */}

                        {/* anas */}
                        <div className={this.state.submitbtnhide}>
                            <Button variant="warning" className="mx-4" onClick={this.onSubmit} disabled={this.isFormSubmitted} >
                                {this.isFormSubmitted && <Spinner animation="border" variant="dark" size="sm" />}
                                {this.isFormSubmitted ? "In Progress..." : "Submit"}
                            </Button>
                            <Button variant="outline-danger" onClick={this.onCancel}>Cancel</Button>
                        </div>

                    </div>
                    <ToastContainer autoClose={5000}></ToastContainer>

                </div>
            )
        }
        else {
            return <div></div>
        }
    }

}
export default RequestForm;