import '../Navbar.css';
import AxiosApi from "../../../Api"
import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import * as ReactBootstrap from 'react-bootstrap';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import Container from 'react-bootstrap/Container';
import NavbarComponent from '../NavbarComponent.js';
import './../CQ.module.css';
import { Card } from 'react-bootstrap';
import Button from 'react-bootstrap/Button'
// import FooterComponent from '../FooterComponent';
import ReactStars from 'react-rating-stars-component';
import { getRatingUtilityClass } from '@mui/material';
import { Link } from 'react-router-dom';

import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';

const { SearchBar, ClearSearchButton } = Search;
const { ExportCSVButton } = CSVExport;

const Allcomplaints = () => {

  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rate, setrate] = useState(0);

  const [toggleCompactView, setToggleCompactView] = useState(false);
  const [filterData, setFilterData] = useState([]);
  var columns;


  if (localStorage.getItem('role') === 'ABI_CQTeam' || localStorage.getItem('role') === 'Manager' || localStorage.getItem('role') === 'ABI_CQ_SPOC') {
    const selectOptions = {
      'Africa': 'Africa',
      'MAZ': 'MAZ',
      'SAZ': 'SAZ',
      'EUR': 'EUR',
      'APAC': 'APAC',
      'NAZ': 'NAZ'
    };
    const newcolumns = [
      {
        dataField: 'acknowledgementNumber',
        text: 'Acknowledgement No.',
        formatter: linkFormatter,
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          // fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          className: 'ram',
          style: { "border": '1px solid grey', 'border-radius': '12px ' }
        })
      },
      {
        dataField: 'complaintNumber',
        text: 'Complaint No.',
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
      {
        dataField: 'companyName',
        text: 'Company',
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
      {
        dataField: 'type',
        text: 'Type',
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
      {
        dataField: 'defectType',
        text: 'Defect Type',
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
      {
        dataField: 'salesOrder',
        text: 'Sales Order',
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
      {
        dataField: 'exportingZone',
        text: 'Exporting Zone',
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: selectFilter({
          options: selectOptions,
          style: { "border": '1px solid grey', 'border-radius': '12px ' }
        }),
      },
      {
        dataField: 'spoc',
        text: 'SPOC',
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
      {
        dataField: 'uploaded',
        text: 'Date of Upload',
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
      {
        dataField: 'registeredBy',
        text: 'Assigned To',
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
      {
        dataField: 'status',
        text: 'Status',
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
      {
        dataField: 'Rating',
        text: 'Rating',
        align: 'center',
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
        formatter: (cell, row) => {
          const parseBoolean = (str) => {
            if (str == 'Yes') {
              return false;
            }
            if (str == 'Yes') {
              return true;
            }
          };
          if (row.status == 'Closed') {
            const ratingChanged = (newRating) => {
              for (var i = 0; i < players.length; i++) {
                if (players[i].acknowledgementNumber === row.acknowledgementNumber) {
                  players[i].Rating = newRating;
                  players[i].isRated = 'Yes';

                }
              }

              setPlayers(players)
              row.Rating = String(newRating);

              row.isRated = 'Yes';

              setPlayers(players)
              AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/save/api/rating', {
                ackno: row.acknowledgementNumber,
                rating: row.Rating,
                israted: row.isRated,
              }).then((response) => {
                setrate(rate + 1)
              });
            };
            if (row)
              if (
                localStorage.getItem('role') == 'External_Customer' ||
                localStorage.getItem('role') == 'Internal_Customer'
              ) {
                return (
                  <div style={{ alignContent: 'center' }}>
                    <ReactStars
                      value={parseInt(row.Rating)}
                      count={5}
                      edit={parseBoolean(row.isRated)}
                      onChange={ratingChanged}
                      size={24}
                      activeColor="#ffd700"
                    />
                  </div>
                );
              }
              else {
                return (
                  <div style={{ alignContent: 'center' }}>
                    {row.Rating}
                  </div>
                )
              }
          }
        },
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
      },
    ];
    columns = newcolumns;
  }
  else if (localStorage.getItem('role') === 'External_Customer' || localStorage.getItem('role') === 'Internal_Customer') {
    const newcolumns = [
      {
        dataField: 'acknowledgementNumber',
        text: 'Acknowledgement No.',
        formatter: linkFormatter,
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' }
        }),
      },
      {
        dataField: 'complaintNumber',
        text: 'Complaint No.',
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
      {
        dataField: 'companyName',
        text: 'Company',
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        // filter:textFilter() ,
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
      {
        dataField: 'type',
        text: 'Type',
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
      {
        dataField: 'defectType',
        text: 'Defect Type',
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
      {
        dataField: 'salesOrder',
        text: 'Sales Order',
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
      {
        dataField: 'PO',
        text: 'Customer PO',
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
      {
        dataField: 'uploaded',
        text: 'Date of Upload',
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
      {
        dataField: 'totalAmountClaimed',
        text: 'Total Amount Claimed',
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
      {
        dataField: 'status',
        text: 'Status',
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
      {
        dataField: 'Rating',
        text: 'Rating',
        align: 'center',
        formatter: (cell, row) => {
          const parseBoolean = (str) => {
            if (str == 'Yes') {
              return false;
            }
            if (str == 'Yes') {
              return true;
            }
          };
          if (row.status == 'Closed') {
            const ratingChanged = (newRating) => {
              for (var i = 0; i < players.length; i++) {
                if (players[i].acknowledgementNumber === row.acknowledgementNumber) {
                  players[i].Rating = newRating;
                  players[i].isRated = 'Yes';

                }
              }
              setPlayers(players)
              row.Rating = String(newRating);

              row.isRated = 'Yes';
              setPlayers(players)
              AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/save/api/rating', {
                ackno: row.acknowledgementNumber,
                rating: row.Rating,
                israted: row.isRated,
              }).then((response) => {

                setrate(rate + 1)
              });
            };
            if (row)
              if (
                localStorage.getItem('role') == 'External_Customer' ||
                localStorage.getItem('role') == 'Internal_Customer'
              ) {
                return (
                  <div style={{ alignContent: 'center' }}>
                    <ReactStars
                      value={parseInt(row.Rating)}
                      count={5}
                      edit={parseBoolean(row.isRated)}
                      onChange={ratingChanged}
                      size={24}
                      activeColor="#ffd700"
                    />
                  </div>
                );
              }
              else {
                return (
                  <div style={{ alignContent: 'center' }}>
                    {row.Rating}
                  </div>
                )
              }
          }
        },
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
      },
    ];
    columns = newcolumns;
  }
  else {
    const newcolumns = [
      {
        dataField: 'acknowledgementNumber',
        text: 'Acknowledgement No.',
        formatter: linkFormatter,
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
      {
        dataField: 'complaintNumber',
        text: 'Complaint No.',
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
      {
        dataField: 'companyName',
        text: 'Company',
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
      {
        dataField: 'type',
        text: 'Type',
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
      {
        dataField: 'defectType',
        text: 'Defect Type',
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
      {
        dataField: 'salesOrder',
        text: 'Sales Order',
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
      {
        dataField: 'PO',
        text: 'Customer PO',
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
      {
        dataField: 'Container',
        text: 'Container',
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
      {
        dataField: 'uploaded',
        text: 'Date of Upload',
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
      {
        dataField: 'registeredBy',
        text: 'Assigned To',
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
      {
        dataField: 'status',
        text: 'Status',
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
      {
        dataField: 'Rating',
        text: 'Rating',
        align: 'center',
        formatter: (cell, row) => {
          const parseBoolean = (str) => {
            if (str == 'Yes') {
              return false;
            }
            if (str == 'Yes') {
              return true;
            }
          };
          if (row.status == 'Closed') {
            const ratingChanged = (newRating) => {
              for (var i = 0; i < players.length; i++) {
                if (players[i].acknowledgementNumber === row.acknowledgementNumber) {
                  players[i].Rating = newRating;
                  players[i].isRated = 'Yes';
                }
              }
              setPlayers(players)
              row.Rating = String(newRating);
              row.isRated = 'Yes';
              setPlayers(players)
              AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/save/api/rating', {
                ackno: row.acknowledgementNumber,
                rating: row.Rating,
                israted: row.isRated,
              }).then((response) => {
                setrate(rate + 1)
              });
            };
            if (row)
              if (
                localStorage.getItem('role') == 'External_Customer' ||
                localStorage.getItem('role') == 'Internal_Customer'
              ) {
                return (
                  <div style={{ alignContent: 'center' }}>
                    <ReactStars
                      value={parseInt(row.Rating)}
                      count={5}
                      edit={parseBoolean(row.isRated)}
                      onChange={ratingChanged}
                      size={24}
                      activeColor="#ffd700"
                    />
                  </div>
                );
              }
              else {
                return (
                  <div style={{ alignContent: 'center' }}>
                    {row.Rating}
                  </div>
                )
              }
          }
        },
        headerStyle: {
          backgroundColor: '#f1c40f',
          color: '#fff',
          fontWeight: '500',
        },
        filter: textFilter({
          placeholder: 'Value',
          style: { "border": '1px solid grey', 'border-radius': '12px ' },

        }),
      },
    ];
    columns = newcolumns;
  }
  function linkFormatter(cell, row, rowIndex, extraData) {
    if (cell) {
      if (
        localStorage.getItem('role') == 'External_Customer' ||
        localStorage.getItem('role') == 'Internal_Customer'
      ) {
        const url = '/complaint-2/num/' + cell;
        return <Link to={url}>{cell}</Link>;
      } else {
        const url = '/complaint/num/' + cell;
        return <Link to={url}>{cell}</Link>;
      }
    }
    return <span></span>;
  }
  var username, role, userId;
  const rowStyle = { fontWeight: '600' };
  let uniqueCategories = [];
  useEffect(() => {
    // GET request using AxiosApi inside useEffect React hook
    username = localStorage.getItem('username');
    role = localStorage.getItem('role');

    AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/fetchUserId?email=${username}`)
      .then((response) => {

        userId = response?.data?.recordset[0]?.userID;
        return AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/fetchSoldTo?userID=${userId}`);
      })
      .then((response) => {
        var isLength = response?.data.recordset.length == 0 ? 1 : response?.data.recordset?.length;

        for (var i = 0; i < isLength; i++) {
          AxiosApi.get(
            `${process.env.REACT_APP_API_ROOT_URL_PREFIX}/database/api/v1/comp/2?email=${username}&role=${role}&soldTo=${response?.data.recordset.length > 0 ? response?.data.recordset[i]['parentAccountNumber'] : ""}`,
          ).then(function (response) {
            // handle success

            if (role === 'ABI_CQ_SPOC' || role === 'ABI_CQTeam' || role === 'Manager') {
              setPlayers(response?.data);

              const arrayUniqueByKey = [...new Map(response?.data.map(item =>
                [item['acknowledgementNumber'], item])).values()];
              setFilterData(arrayUniqueByKey);

            } else setPlayers((players) => [...players, ...response?.data]);
          });
        }
      });

    // setLoading(true);
  }, [rate]);

  function toggleData() {
    setToggleCompactView(!toggleCompactView);
  }

  function clear() {
    window.location.reload()
  }
  const options = {
    onSizePerPageChange: (sizePerPage, page) => {

    },
    onPageChange: (page, sizePerPage) => {

    },
  };
  return (
    <div className="Allcomplaints background">
      {/* {loading ? ( */}
      <div>
        <NavbarComponent />

        <Container>
          <ToolkitProvider
            keyField="name"
            data={(toggleCompactView === false && !(localStorage.getItem('role') == 'External_Customer' || localStorage.getItem('role') == 'Internal_Customer')) ? filterData : players}
            columns={columns}
            pagination={paginationFactory(options)}
            exportCSV={{ onlyExportFiltered: true, exportAll: false }}
            search
          >
            {(props) => (
              <div className="mar">
                <h3 className="complaints-title">All Complaints</h3>
                <hr />
                <div>
                  <SearchBar {...props.searchProps} />
                  {/*   } <ClearSearchButton className="btn ml-1 btn-warning mb-2 text-white"{...props.searchProps} 
                    />*/}

                  <Button variant="warning" className="ml-3" onClick={() => { clear() }}>clear</Button>{' '}
                  {/* compact view button start */}


                  <FormControl component="fieldset" variant="standard" style={{ marginLeft: "50vw", marginTop: "10px" }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch name="jason" defaultChecked />
                        }
                        label="Compact view"
                        onClick={toggleData}

                      />
                    </FormGroup>
                  </FormControl>
                  {/* compact view button end */}
                  <ExportCSVButton
                    {...props.csvProps}
                    className="export-button text-right text-white btn-warning"
                    style={{ textAlign: 'right' }}
                  >
                    Export to CSV
                  </ExportCSVButton>
                </div>
                {/* table compact view started here */}
                <div className="table-up noBorder expanded-container">
                  <BootstrapTable
                    className="table-background-2 bootstrap-table-2"
                    {...props.baseProps}
                    pagination={paginationFactory()}
                    filter={filterFactory()}
                    rowStyle={rowStyle}
                    striped
                    hover
                    condensed
                  />
                </div>
              </div>
            )}
          </ToolkitProvider>
        </Container>
        <div className="bottom-fixed"></div>
      </div>
      {/* ) : (
        <ReactBootstrap.Spinner animation="border" /> */}
      {/* )} */}
    </div>
  );
};

export default Allcomplaints;
