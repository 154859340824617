import React, { useState, useEffect } from 'react'
import { Col, Row, Modal, Button } from "react-bootstrap";
import { IconCalendarEvent, IconBuildingSkyscraper, IconFileInfo, IconSettings, IconExternalLink, IconMessage } from '@tabler/icons-react';
import { useNavigate, Navigate, Link } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';
import axios from 'axios';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import './notification.css';

const Notification = ({ data, showModal, handleModalToggle, setUnreadMessagesCount }) => {

    const navigate = useNavigate();
    const [finalData, setFinalData] = useState([]);
    const [loading, setLoading] = useState(false);

    // new logic 

    async function findNonMatchingData() {

        let freshData = await data?.map((item, index) => ({
            ...item,
            id: index + 1,
            isRead: 0
        }));

        const localStorageData = await JSON.parse(localStorage.getItem('unique_notific'));

        const notReadLocalStorageData = localStorageData.filter(item => item.isRead == 0)
        console.log("🚀 ~ findNonMatchingData ~ notReadLocalStorageData:", notReadLocalStorageData)

        const unMatchData = freshData.filter(newItem => {
            return !localStorageData.some(item => {
                return (
                    item.acknowledgementNumber === newItem.acknowledgementNumber &&
                    item.complaintNumber === newItem.complaintNumber &&
                    item.companyName === newItem.companyName &&
                    item.notificationText === newItem.notificationText &&
                    item.notificationTime === newItem.notificationTime
                );
            });
        });

        setUnreadMessagesCount([...unMatchData]?.length);// for new notifications
        console.log("🚀 ~ findNonMatchingData ~ unMatchData?.length:", [...unMatchData]?.length)
        if (unMatchData?.length > 0) {
            setFinalData([...unMatchData, ...localStorageData]);
            setUnreadMessagesCount([...unMatchData]?.length);
        } else {
            setFinalData(localStorageData);
            setUnreadMessagesCount(notReadLocalStorageData?.length);
            console.log('inside else of findNonMatchingData: ', localStorageData);
            // setFinalData(prevFinalData => [...prevFinalData, ...unMatchData]);
        }
    }



    function setNotificationData() {
        // testin
        const localStorageData = JSON.parse(localStorage.getItem('unique_notific'));
        if (localStorageData && localStorageData?.length > 0) {
            findNonMatchingData();
        } else {
            const modifiedData = data?.map((item, index) => ({
                ...item,
                id: index + 1,
                isRead: 0
            }));
            setFinalData(modifiedData);
            localStorage.setItem('unique_notific', JSON.stringify(modifiedData));
            setUnreadMessagesCount(data?.length);//for the first time user
            console.log("🚀 ~ data?.length:", data?.length)
        }
    }

    async function markRead() {
        setLoading(true);
        const ModifiedData = await finalData?.map((item) => ({
            ...item,
            isRead: 1
        }))
        setFinalData(ModifiedData);
        localStorage.removeItem('unique_notific');
        localStorage.setItem('unique_notific', JSON.stringify(ModifiedData));
        setUnreadMessagesCount(0);
        setLoading(false);
        // window.location.reload();
    }


    function printMessage(text) {
        const regex = /has (uploaded \d+ files|a new message)/;
        const match = text.match(regex);

        if (match) {
            return match[0];
        }
        return "";
    }

    useEffect(() => {
        setNotificationData();
    }, [data]);

    return (
        <>
            <Offcanvas show={showModal} onHide={handleModalToggle}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Notification</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="d-flex justify-content-around align-items-center ">
                        <Button variant="warning" size="sm" onClick={() => navigate('/notificationspage')}>
                            <IconExternalLink size={20} />  See All Complaints
                        </Button>
                        <Button variant="warning" size="sm" disabled={loading} onClick={() => { markRead() }}>
                            {loading ? "Updating Notifications" : "Mark All As Read"}
                            {/* Mark All As Read */}
                        </Button>
                    </div>
                    {
                        finalData?.length == 0 ?
                            "Data is loading" :
                            finalData && finalData?.map(({ acknowledgementNumber, companyName, notificationText, notificationTime, isRead, complaintNumber }, index) => (
                                <div className='notification_card mt-2 p-2 rounded-md' style={{ backgroundColor: isRead == 0 ? '#f1c40f' : 'lightgray' }}                        >
                                    <div key={index * 10} className=''>
                                        <div className='d-flex justify-content-between align-items-center p-2 '>
                                            <h4 className='m-0 p-0 d-flex align-items-center'>{index + 1}.</h4>
                                            <a href={`/complaint/num/${acknowledgementNumber}`}>
                                                <IconExternalLink />
                                                See Complete details
                                            </a>
                                        </div>
                                        <p className='m-0 p-0 d-flex align-items-center'><IconCalendarEvent />{moment(notificationTime).format('lll')}</p>
                                        <p className='m-0 p-0 d-flex align-items-center'> <IconSettings />Ack No: {acknowledgementNumber}</p>

                                        <p className='m-0 p-0 d-flex align-items-center' ><IconFileInfo />Complaint Number: {complaintNumber || "NA"}</p>
                                        <p className='m-0 p-0 d-flex align-items-center' ><IconBuildingSkyscraper />Company Name: {companyName}</p>
                                        <p className='m-0 p-0 d-flex align-items-center font-bold' ><IconMessage />What's New: {printMessage(notificationText) ? `${acknowledgementNumber} ${printMessage(notificationText)}` : ""}</p>
                                    </div>
                                </div>
                            ))
                    }

                </Offcanvas.Body>
            </Offcanvas>
        </>

    );
};


export default Notification