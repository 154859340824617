import React, { useEffect, useRef } from 'react';
import useState from 'react-usestateref';
import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import * as ReactBootstrap from 'react-bootstrap';
import useUnsavedChangesWarning from './useUnsavedChangesWarning';

import { Col, Row } from 'react-bootstrap';
import { Card, FormGroup } from 'react-bootstrap';
import { Form, FormControl, Button, Image } from 'react-bootstrap';
import NavbarComponent from './NavbarComponent';
// import FooterComponent from './FooterComponent'
import { FaEdit } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
// import ComplaintDetailsTable from './Complaint-Details-Table';

import Currencyconverter from './currencyconverter';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { GiDna2 } from 'react-icons/gi';
import { set } from 'date-fns';
import * as moment from 'moment';
import * as businessmoment from 'moment-business-days';
import { useNavigate } from 'react-router-dom';
import FileDownload from 'js-file-download';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import { Tooltip } from 'react-tooltip'
import Dropzone from 'react-dropzone-uploader';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { SignalCellularNull } from '@material-ui/icons';
import './Navbar.css';
import AxiosApi from '../../Api';
// import RenderExpenseTable from './detailComplaintsPages/RenderExpenseTable';
// import RenderExpenseTable4 from './detailComplaintsPages/RenderExpenseTable4';

const ComplaintPag2 = () => {


  // console.log("🚀 ~ myurl:", process.env.REACT_APP_API_ROOT_URL_PREFIX);
  const { ackno } = useParams();
  const pricesorder4 = [
    { id: '2', author: '', date_start: '', owner: '', action: '', comment: '', date_finish: '' },
    { id: '3', author: '', date_start: '', owner: '', action: '', comment: '', date_finish: '' },
    { id: '4', author: '', date_start: '', owner: '', action: '', comment: '', date_finish: '' },
  ];
  const pricesorder3 = [
    {
      id: '1',
      salesOrder: '',
      Container: 'Data Not Loaded',
      PO: '',
      Quantity: '',
      SKU: '',
      batchCode: 'Do not save when data is not loaded',
      plantCost: '',
      ThirdPartyCost: '',
      dateOfGoodsArrival: '',
      isAccepted: '',
    },
    {
      id: '2',
      salesOrder: '',
      Container: 'Data Not Loaded',
      PO: '',
      Quantity: '',
      SKU: '',
      batchCode: 'Do not save when data is not loaded',
      plantCost: '',
      ThirdPartyCost: '',
      dateOfGoodsArrival: '',
      isAccepted: '',
    },
    {
      id: '3',
      salesOrder: '',
      Container: 'Data Not Loaded',
      PO: '',
      Quantity: '',
      SKU: '',
      batchCode: 'Do not save when data is not loaded',
      plantCost: '',
      ThirdPartyCost: '',
      dateOfGoodsArrival: '',
      isAccepted: '',
    },
  ];
  const navigate = useNavigate();

  const [del, setdel, refdel] = useState(0);
  const [filesfinal, setfilesfinal, reffilesfinal] = useState([]);
  const [loaded, setloaded] = useState(false);
  const [loadingfinal, setloadingfinal] = useState(false);

  const [invalidmess, setinvalidmess] = useState('');
  const [submitdisabled, setsubmitdisabled] = useState(true);
  const [sessionid12, setsessionid12, refsessionid12] = useState([]);
  const [partiallyreason, setpartiallyreason] = useState();

  const [email, setemail] = useState();
  const [key, setKey] = useState('home');

  const [modalcostclaim, setmodalcostclaim, modalcclaim] = useState();
  const [modalcostclaimcurr, setmodalcostclaimcurr, modalcclaimcurr] = useState();

  const [fileslist2, setfileslist2] = useState([]);
  const [tempnull, settempnull] = useState('');
  const [duedate, setduedate, refduedate] = useState();
  const [currdate, setcurrdate, refcurrdate] = useState();
  const [diffofdays, setdiffofdays, refdiffofdays] = useState();
  const [color, setcolor, refcolor] = useState();
  const [dropextraduedate, setdropextraduedate] = useState();
  const [showaddextradays, setshowaddextradays] = useState(false);
  const [companyName, setcompanyName] = useState();
  const [acknowledgementNumber, setackno] = useState();
  const [extradocrequested, setextradocrequested] = useState();
  const [complaintNumber, setcomplaintno] = useState();
  const [oldComplaintNumber, setoldcomplaintno] = useState();
  const [complaintDesc, setcomplaintdesc] = useState();
  const [complaintType, setcomplaintType] = useState();
  const [extra_dueDate, setextradue] = useState(0);
  const [uploaded, setupload, refuploaded] = useState();
  const [complaintSubmission, setcomplaintsubmission] = useState();
  const [curruploaded, setcurrupload, refcurruploaded] = useState();
  const [currextradays, setcurrextradays, refcurrextradays] = useState();
  const [currregistered, setcurrregister, refcurrregistered] = useState();
  const [registeredBy, setregisteredby] = useState();
  const [cqAgent, setcqagent] = useState();
  const [defectType, setdefect] = useState();
  const [customerContacted, setcusdate, refcustomerContacted] = useState();
  const [ownerContacted, setownerdate, refownerContacted] = useState();
  const [closedDate, setclosedate, refclosedDate] = useState();
  const [comment, setcomment] = useState();
  const [note, setnote] = useState();
  const [status, setstatus] = useState();
  const [accepted, setaccepted] = useState();
  const [acceptedBy, setacceptedBy] = useState();
  const [registrationDate, setregdate, refregistrationDate] = useState();
  const [reasonofrejection123, setreasonofrejection123] = useState();
  const [type, settype] = useState();
  const [subtype, setsubtype] = useState();
  const [costClaimed, setcostclaim] = useState();
  const [currencyImporter, setcurrimport] = useState();
  const [currencyClaimed, setcurrclaim, currclaim] = useState();
  const [currencyPlant, setcurrplant] = useState();
  const [extraFeesPlant, setextrafeesplant] = useState();
  const [resolution, setresolution] = useState();
  const [managermessage, setmanagermessage] = useState();
  const [rulesOfEngagement, setrules, refrulesOfEngagement] = useState();
  const [headercomplainttype, setheadercomplainttype] = useState([]);
  const [checkedrulesOfEngagement, setcheckedrules, refcheckedrulesOfEngagement] = useState(null);
  const [acceptedByAm, setacceptedByAm, refacceptedByAm] = useState();
  const [checkedByAm, setcheckedByAm, refcheckedByAm] = useState(null);
  const [checkedByVlc, setcheckedByVlc, refcheckedByVlc] = useState(null);
  const [checkedByPlantLogi, setcheckedByPlantLogi, refcheckedByPlantLogi] = useState(null);
  const [acceptedByVlc, setacceptedByVlc, refacceptedByVlc] = useState();
  const [acceptedByPlantLogi, setacceptedByPlantLogi, refacceptedByPlantLogi] = useState();
  const [costAcceptedByAm, setcostAcceptedByAm] = useState();
  const [costAcceptedByVlc, setcostAcceptedByVlc] = useState();
  const [costAcceptedByPlantLogi, setcostAcceptedByPlantLogi] = useState();
  const [currAccAm, setcurrAccAm] = useState();
  const [currAccVlc, setcurrAccVlc] = useState();
  const [currAccPl, setcurrAccPl] = useState();
  const [currExtraAm, setcurrExtraAm] = useState();
  const [currExtraVlc, setcurrExtraVlc] = useState();
  const [currExtraPl, setcurrExtraPl] = useState();
  const [feesAcceptedByAm, setfeesAcceptedByAm] = useState();
  const [feesAcceptedByVlc, setfeesAcceptedByVlc] = useState();
  const [feesAcceptedbyPl, setfeesAcceptedbyPl] = useState();
  const [custname, setcustname] = useState();
  const [billto, setbillto] = useState();
  const [shipto, setshipto] = useState();
  const [soldto, setsoldto] = useState();
  const [payername, setpayername] = useState();
  const [billtocust, setbilltocust] = useState();
  const [shiptocust, setshiptocust] = useState();
  const [shiptosalesorg, setshiptosalesorg] = useState();
  const [code, setcode] = useState();
  const [destination, setdestination] = useState();
  const [importzone, setimportzone] = useState();
  const [billtosalesorg, setbilltosalesorg] = useState();
  const [incoterm1, setincoterm1] = useState();
  const [incoterm2, setincoterm2] = useState();
  const [exportzone, setexportzone] = useState();
  const [loadingpt1, setloadingpt1] = useState();
  const [loadingpt2, setloadingpt2] = useState();
  const [typeofcomplaint, settypeofcomplaint] = useState();
  /*let [accAm,setaccAm] = useState();
    let[accVlc,setaccVlc] = useState();
    let[accPli,setaccPli] = useState();*/

  const [order4, setorder4] = useState([...pricesorder4]);
  const [order3, setorder3] = useState([]);
  const [checkdnpending, setcheckdnpending, refcheckdnpending] = useState(false);
  const [dnpending, setdnpending, refdnpending] = useState();
  const [dnbooked, setdnbooked] = useState();
  const [dncostcenter, setdncostcenter] = useState();
  const [dnFinalInvoice, setdnFinalInvoice] = useState();
  const [dngl, setdngl] = useState();
  const [dnrequested, setdnrequested] = useState();
  const [costbooked, setcostbooked] = useState();
  const [costCMAmount, setcostCMAmount] = useState();
  const [costCMAmountCurrency, setcostCMAmountCurrency] = useState();
  const [costCMNumber, setcostCMNumber] = useState();
  const [costRequested, setcostRequested] = useState();
  const [cmbooked, setcmbooked] = useState();
  const [feesCMAmount, setfeesCMAmount] = useState();
  const [feesCMAmountCurrency, setfeesCMAmountCurrency] = useState();
  const [feesCMNumber, setfeesCMNumber] = useState();
  const [feesRequested, setfeesRequested] = useState();
  const [profitCenter, setprofitCenter] = useState();
  const [feesBooked, setfeesBooked] = useState();

  const [bscdefect, setbscdefect] = useState();
  const [bscnote, setbscnote] = useState();
  const [preventive, setpreventive] = useState();
  const [processValue, setprocess] = useState();
  const [root, setroot] = useState();
  const [Teamlead, setTeamlead] = useState();

  const [bscdefectlist, setbscdefectlist] = useState([]);
  const [bscprocesslist, setbscprocesslist] = useState([]);

  const [importtaxesextraclaims, setimporttaxesextraclaims] = useState();
  const [
    importtaxesextraclaimscurrency,
    setimporttaxesextraclaimscurrency,
    importtaxesexclaimscurr
  ] = useState();
  const [inspectionextraclaims, setinspectionextraclaims] = useState();
  const [inspectionextraclaimscurrency, setinspectionextraclaimscurrency, inspectionexclaimscurr] =
    useState();
  const [reworkingrestackingextraclaims, setreworkingrestackingextraclaims] = useState();
  const [
    reworkingrestackingextraclaimscurrency,
    setreworkingrestackingextraclaimscurrency,
    reworkingrestackingexclaimscurr,
  ] = useState();
  const [destructiondisposalextraclaims, setdestructiondisposalextraclaims] = useState();
  const [
    destructiondisposalextraclaimscurrency,
    setdestructiondisposalextraclaimscurrency,
    destructiondisposalexclaimscurr,
  ] = useState();
  const [relabellingextraclaims, setrelabellingextraclaims] = useState();
  const [
    relabellingextraclaimscurrency,
    setrelabellingextraclaimscurrency,
    relabellingexclaimscurr,
  ] = useState();
  const [transportationextraclaims, settransportationextraclaims] = useState();
  const [
    transportationextraclaimscurrency,
    settransportationextraclaimscurrency,
    transportationexclaimscurr,
  ] = useState();
  const [productdiscountsextraclaims, setproductdiscountsextraclaims] = useState();
  const [
    productdiscountsextraclaimscurrency,
    setproductdiscountsextraclaimscurrency,
    productdiscountsexclaimscurr,
  ] = useState();
  const [oceanfreightextraclaims, setoceanfreightextraclaims] = useState();
  const [
    oceanfreightextraclaimscurrency,
    setoceanfreightextraclaimscurrency,
    oceanfreightexclaimscurr,
  ] = useState();
  const [demurrageextraclaims, setdemurrageextraclaims] = useState();
  const [demurrageextraclaimscurrency, setdemurrageextraclaimscurrency, demurrageexclaimscurr] =
    useState();
  const [customsfeeextraclaims, setcustomsfeeextraclaims] = useState();
  const [customsfeeextraclaimscurrency, setcustomsfeeextraclaimscurrency, customsfeeexclaimscurr] =
    useState();
  const [otherextraclaims, setotherextraclaims] = useState();
  const [otherextraclaimscurrency, setotherextraclaimscurrency, otherexclaimscurr] = useState();

  const [modalimporttaxesextraclaims, setmodalimporttaxesextraclaims, modalimporttaxesexclaims] =
    useState();
  const [modalinspectionextraclaims, setmodalinspectionextraclaims, modalinspectionexclaims] =
    useState();
  const [
    modalreworkingrestackingextraclaims,
    setmodalreworkingrestackingextraclaims,
    modalreworkingrestackingexclaims,
  ] = useState();
  const [
    modaldestructiondisposalextraclaims,
    setmodaldestructiondisposalextraclaims,
    modaldestructiondisposalexclaims,
  ] = useState();
  const [modalrelabellingextraclaims, setmodalrelabellingextraclaims, modalrelabellingexclaims] =
    useState();
  const [
    modaltransportationextraclaims,
    setmodaltransportationextraclaims,
    modaltransportationexclaims,
  ] = useState();
  const [
    modalproductdiscountsextraclaims,
    setmodalproductdiscountsextraclaims,
    modalproductdiscountsexclaims,
  ] = useState();
  const [modaloceanfreightextraclaims, setmodaloceanfreightextraclaims, modaloceanfreightexclaims] =
    useState();
  const [modaldemurrageextraclaims, setmodaldemurrageextraclaims, modaldemurrageexclaims] =
    useState();
  const [modalcustomsfeeextraclaims, setmodalcustomsfeeextraclaims, modalcustomsfeeexclaims] =
    useState();
  const [modalotherextraclaims, setmodalotherextraclaims, modalotherexclaims] = useState();

  let [convertedimporttaxesextraclaims, setconvertedimporttaxesextraclaims, convimporttaxes] =
    useState(0);
  let [convertedinspectionextraclaims, setconvertedinspectionextraclaims, convinspection] =
    useState(0);
  let [
    convertedreworkingrestackingextraclaims,
    setconvertedreworkingrestackingextraclaims,
    convreworkingrestacking,
  ] = useState(0);
  let [
    converteddestructiondisposalextraclaims,
    setconverteddestructiondisposalextraclaims,
    convdestructiondisposal,
  ] = useState(0);
  let [convertedrelabellingextraclaims, setconvertedrelabellingextraclaims, convrelabelling] =
    useState(0);
  let [
    convertedtransportationextraclaims,
    setconvertedtransportationextraclaims,
    convtransportation,
  ] = useState(0);
  let [
    convertedproductdiscountsextraclaims,
    setconvertedproductdiscountsextraclaims,
    convproductdiscounts,
  ] = useState(0);
  let [convertedoceanfreightextraclaims, setconvertedoceanfreightextraclaims, convoceanfreight] =
    useState(0);
  let [converteddemurrageextraclaims, setconverteddemurrageextraclaims, convdemurrage] =
    useState(0);
  let [convertedcustomsfeeextraclaims, setconvertedcustomsfeeextraclaims, convcustomsfee] =
    useState(0);
  let [convertedotherextraclaims, setconvertedotherextraclaims, convother] = useState(0);

  //Cost Paid

  const [importtaxescostpaid, setimporttaxescostpaid, refimporttaxescostpaid] = useState();
  const [importtaxescostpaidcurrency, setimporttaxescostpaidcurrency] = useState();
  const [inspectioncostpaid, setinspectioncostpaid, refinspectioncostpaid] = useState();
  const [inspectioncostpaidcurrency, setinspectioncostpaidcurrency] = useState();
  const [
    reworkingrestackingcostpaid,
    setreworkingrestackingcostpaid,
    refreworkingrestackingcostpaid,
  ] = useState();
  const [reworkingrestackingcostpaidcurrency, setreworkingrestackingcostpaidcurrency] = useState();
  const [
    destructiondisposalcostpaid,
    setdestructiondisposalcostpaid,
    refdestructiondisposalcostpaid,
  ] = useState();
  const [destructiondisposalcostpaidcurrency, setdestructiondisposalcostpaidcurrency] = useState();
  const [relabellingcostpaid, setrelabellingcostpaid, refrelabellingcostpaid] = useState();
  const [relabellingcostpaidcurrency, setrelabellingcostpaidcurrency] = useState();
  const [transportationcostpaid, settransportationcostpaid, reftransportationcostpaid] = useState();
  const [transportationcostpaidcurrency, settransportationcostpaidcurrency] = useState();
  const [productdiscountscostpaid, setproductdiscountscostpaid, refproductdiscountscostpaid] =
    useState();
  const [productdiscountscostpaidcurrency, setproductdiscountscostpaidcurrency] = useState();
  const [oceanfreightcostpaid, setoceanfreightcostpaid, refoceanfreightcostpaid] = useState();
  const [oceanfreightcostpaidcurrency, setoceanfreightcostpaidcurrency] = useState();
  const [demurragecostpaid, setdemurragecostpaid, refdemurragecostpaid] = useState();
  const [demurragecostpaidcurrency, setdemurragecostpaidcurrency] = useState();
  const [customsfeecostpaid, setcustomsfeecostpaid, refcustomsfeecostpaid] = useState();
  const [customsfeecostpaidcurrency, setcustomsfeecostpaidcurrency] = useState();
  const [othercostpaid, setothercostpaid, refothercostpaid] = useState();
  const [othercostpaidcurrency, setothercostpaidcurrency] = useState();

  const [plantcostpaidcurr, setplantcostpaidcurr] = useState();
  const [importercostpaidcurr, setimportercostpaidcurr] = useState();

  const [modalimporttaxescostpaid, setmodalimporttaxescostpaid] = useState();
  const [modalinspectioncostpaid, setmodalinspectioncostpaid] = useState();
  const [modalreworkingrestackingcostpaid, setmodalreworkingrestackingcostpaid] = useState();
  const [modaldestructiondisposalcostpaid, setmodaldestructiondisposalcostpaid] = useState();
  const [modalrelabellingcostpaid, setmodalrelabellingcostpaid] = useState();
  const [modaltransportationcostpaid, setmodaltransportationcostpaid] = useState();
  const [modalproductdiscountscostpaid, setmodalproductdiscountscostpaid] = useState();
  const [modaloceanfreightcostpaid, setmodaloceanfreightcostpaid] = useState();
  const [modaldemurragecostpaid, setmodaldemurragecostpaid] = useState();
  const [modalcustomsfeecostpaid, setmodalcustomsfeecostpaid] = useState();
  const [modalothercostpaid, setmodalothercostpaid] = useState();
  const [customerfollowup, setcustomerfollowup] = useState([]);

  //Plant Accepted
  const [importtaxesplantaccepted, setimporttaxesplantaccepted, refimporttaxesplantaccepted] =
    useState();
  const [importtaxesplantacceptedcurrency, setimporttaxesplantacceptedcurrency] = useState();
  const [inspectionplantaccepted, setinspectionplantaccepted, refinspectionplantaccepted] =
    useState();
  const [inspectionplantacceptedcurrency, setinspectionplantacceptedcurrency] = useState();
  const [
    reworkingrestackingplantaccepted,
    setreworkingrestackingplantaccepted,
    refreworkingrestackingplantaccepted,
  ] = useState();
  const [reworkingrestackingplantacceptedcurrency, setreworkingrestackingplantacceptedcurrency] =
    useState();
  const [
    destructiondisposalplantaccepted,
    setdestructiondisposalplantaccepted,
    refdestructiondisposalplantaccepted,
  ] = useState();
  const [destructiondisposalplantacceptedcurrency, setdestructiondisposalplantacceptedcurrency] =
    useState();
  const [relabellingplantaccepted, setrelabellingplantaccepted, refrelabellingplantaccepted] =
    useState();
  const [relabellingplantacceptedcurrency, setrelabellingplantacceptedcurrency] = useState();
  const [
    transportationplantaccepted,
    settransportationplantaccepted,
    reftransportationplantaccepted,
  ] = useState();
  const [transportationplantacceptedcurrency, settransportationplantacceptedcurrency] = useState();
  const [
    productdiscountsplantaccepted,
    setproductdiscountsplantaccepted,
    refproductdiscountsplantaccepted,
  ] = useState();
  const [productdiscountsplantacceptedcurrency, setproductdiscountsplantacceptedcurrency] =
    useState();
  const [oceanfreightplantaccepted, setoceanfreightplantaccepted, refoceanfreightplantaccepted] =
    useState();
  const [oceanfreightplantacceptedcurrency, setoceanfreightplantacceptedcurrency] = useState();
  const [demurrageplantaccepted, setdemurrageplantaccepted, refdemurrageplantaccepted] = useState();
  const [demurrageplantacceptedcurrency, setdemurrageplantacceptedcurrency] = useState();
  const [customsfeeplantaccepted, setcustomsfeeplantaccepted, refcustomsfeeplantaccepted] =
    useState();
  const [customsfeeplantacceptedcurrency, setcustomsfeeplantacceptedcurrency] = useState();
  const [otherplantaccepted, setotherplantaccepted, refotherplantaccepted] = useState();
  const [otherplantacceptedcurrency, setotherplantacceptedcurrency] = useState();

  const [modalimporttaxesplantaccepted, setmodalimporttaxesplantaccepted] = useState();
  const [modalinspectionplantaccepted, setmodalinspectionplantaccepted] = useState();
  const [modalreworkingrestackingplantaccepted, setmodalreworkingrestackingplantaccepted] =
    useState();
  const [modaldestructiondisposalplantaccepted, setmodaldestructiondisposalplantaccepted] =
    useState();
  const [modalrelabellingplantaccepted, setmodalrelabellingplantaccepted] = useState();
  const [modaltransportationplantaccepted, setmodaltransportationplantaccepted] = useState();
  const [modalproductdiscountsplantaccepted, setmodalproductdiscountsplantaccepted] = useState();
  const [modaloceanfreightplantaccepted, setmodaloceanfreightplantaccepted] = useState();
  const [modaldemurrageplantaccepted, setmodaldemurrageplantaccepted] = useState();
  const [modalcustomsfeeplantaccepted, setmodalcustomsfeeplantaccepted] = useState();
  const [modalotherplantaccepted, setmodalotherplantaccepted] = useState();

  const [salestabledata, setsalestabledata] = useState([]);

  const [finallink, setfinallink] = useState();
  const [sumextraclaims, setsumextraclaims] = useState();
  const [sumextraclaims2, setsumextraclaims2] = useState();
  const [sumcostpaid, setsumcostpaid, refsumcostpaid] = useState();
  const [sumplantaccepted, setsumplantaccepted, refsumplantaccepted] = useState();
  const [abiv,setabiv]=useState(0)
  const [checkacknopresentcqteam, setcheckacknopresentcqteam] = useState();

  const [checkacknopresentspoc, setcheckacknopresentspoc] = useState();

  const [totalextraclaims, settotalextraclaims] = useState();
  const [totalcostpaid, settotalcostpaid] = useState();
  const [totalplantaccepted, settotalplantaccepted] = useState();
  const [sum2, setsum2, refsum2] = useState();
  const [contaffected, setcontaffected] = useState();
  const [sum3, setsum3, refsum3] = useState();
  const [totalhl, settotalhl] = useState();

  const [owner1, setowner1] = useState('');
  const [cqaction1, setcqaction1] = useState('General');
  const [comment1, setcomment1] = useState('');
  const [reloadstate, setreloadstate] = useState(1);

  const [author2, setauthor2] = useState('');
  const [date_start2, setdate_start2] = useState('');
  const [date_finish2, setdate_finish2] = useState('');
  const [owner2, setowner2] = useState('');
  const [cqaction2, setcqaction2] = useState('');
  const [comment2, setcomment2] = useState('');

  const [salesorder1, setsalesorder1] = useState('');
  const [container1, setcontainer1] = useState('');
  const [po1, setpo1] = useState('');
  const [bookingnum1, setbookingnum1] = useState('');
  const [quantity1, setquantity1] = useState('');
  const [sku1, setsku1] = useState('');
  const [batchcode1, setbatchcode1] = useState('');
  const [dateOfGoodsArrival1, setdateOfGoodsArrival1] = useState('');

  const [prodcost, setprodcost] = useState();
  const [diffquantityclaimed, setdiffquantityclaimed] = useState();

  const [actionslist, setactionslist] = useState([]);
  const [ownerslist, setownerslist] = useState([]);
  const [spoclist, setspoclist] = useState([]);
  const [cqteam, setcqteam] = useState([]);

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('1');
  const [show1, setShow1] = useState(false);
  const [showcurr, setshowcurr] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);

  const [show5, setShow5] = useState(false);

  const [show6, setShow6] = useState(false);
  const [selected, setselected, refselected] = useState(false);
  const [isdisabled, setisdisabled] = useState(true);
  const [dropdisabled, setdropdisabled] = useState();
  const [ifdisabled, setifdisabled] = useState(false);
  // const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();
  const [setDirty, setPristine] = useUnsavedChangesWarning();
  //declared by ANAS
  const [differentquantity, setdifferentquantity] = useState(false);
  const [isOpened, setisOpened] = useState('');
  const [fcount, setfcount] = useState();
  const [isvalid, setisvalid] = useState(false);
  const [iselected, setisselected] = useState(false);
  const [sum, setsum, refsum] = useState(0);
  const [orders3sum, setorders3sum, reforders3sum] = useState(0);
  const [typecheck, settypecheck] = useState(false);
  const [show7, setShow7] = useState(false);
  const [show9, setShow9] = useState(false);

  const [show11, setShow11] = useState(false);
  const [deletethisrow, setdeletethisrow] = useState([]); /// to delete row variable
  const [deletethissalesorder, setdeletethissalesorder] = useState([]); //delete this sales order
  const [salesorderdeletemodal, setsalesorderdeletemodal] = useState(false);





  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [modalInfo, setmodalInfo] = useState([]);
  const handleClose12 = () => { setShow12(false) };


  const handleShow12 = () => setShow12(true);
  const [show12, setShow12] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);



  const [show14, setShow14] = useState(false);
  const [show10, setShow10] = useState(false);

  const handleShow5 = () => setShow5(true);
  const handleClose5 = () => setShow5(false);

  const handleShow6 = () => setShow6(true);
  const handleClose6 = () => setShow6(false);



  const handleUnload = (event) => {
    if (refdel?.current == 0) {
      let tempemailid = localStorage.getItem('username');
      AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/storefileinfo/removefilesfromdb', {
        session: refsessionid12?.current,
        filenames: reffilesfinal?.current,
        email: tempemailid,
      }).then((response) => {
        let message = response;
      });
    }
    const uploadurl2 = process.env.REACT_APP_API_ROOT_URL_PREFIX + '/delete/api' + '/' + refsessionid12?.current;
    AxiosApi.post(uploadurl2).then(function (response) {
      let folder = response;

    });
    AxiosApi.get(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/session/api/id2')
      .then(function (response) {
        // handle success
        let ses = response?.data;
        setsessionid12(ses);
      })
      .catch(function (error) {
        // handle error
      });
  };

  // useEffect(() => {
  //   window.addEventListener('unload', handleUnload);

  //   // cleanup this component
  //   return () => {
  //     window.removeEventListener('unload', handleUnload);
  //   };
  // }, []);

  useEffect(() => {

    // console.log("🚀 ~ useEffect ~ process.env.REACT_APP_API_ROOT_URL_PREFIX:", process.env.REACT_APP_API_ROOT_URL_PREFIX)
    AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/session/api/id2`)
      .then(function (response) {
        // handle success
        let ses = response?.data;
        setsessionid12(ses);
      })
      .catch(function (error) {
        // handle error
      });
  }, []);


  useEffect(() => {
    new Promise(function (resolve, reject) {
      try {
        AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/getduedate', { ackno: { ackno } }).then((response) => {
          try {
            setcurrupload(response?.data[0]['uploaded']);
            setcurrextradays(response?.data[0]['extra_dueDate']);

          } catch (e) {
            console.log("🚀 ~ AxiosApi.post ~ e:", e);
          }
        });
      } catch (e) {
        console.log("🚀 ~ e:", e);

      }
      setTimeout(() => resolve(1), 2000); // (*)
    })
      .then(function (result) {
        return new Promise((resolve, reject) => {
          setTimeout(() => resolve(result * 2), 2000);
          AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/getduedate', { ackno: { ackno } }).then((response) => {
            try {
              setcurrupload(response?.data[0]['uploaded']);
              setcurrextradays(response?.data[0]['extra_dueDate']);

            } catch (e) {
              console.log("🚀 ~ AxiosApi.post ~ e:", e)
            }
          });
          AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/getregdate', { ackno: { ackno } }).then((response) => {
            try {
              setcurrregister(response?.data[0]['registrationDate']);

            } catch (e) {
              console.log("🚀 ~ AxiosApi.post ~ e:", e);
            }
          });
        });
      })
      .then(function (result) {
        return new Promise((resolve, reject) => {
          setTimeout(() => resolve(result * 2), 2000);

          setduedate(businessmoment(refcurrregistered?.current, 'DD-MM-YYYY').businessAdd(16)._d);
          setduedate(
            businessmoment(refduedate?.current, 'DD-MM-YYYY').businessAdd(
              parseInt(refcurrextradays?.current),
            )._d,
          );
          // console.log(businessmoment(refcurruploaded?.current, 'DD-MM-YYYY').businessAdd(16)._d);
        });
      })
      .then(function (result) {
        return new Promise((resolve, reject) => {
          setTimeout(() => resolve(result * 2), 1000);
          try {
            // setcurrdate(new Date().toJSON().slice(0, 10));
            // console.log(new Date());
          } catch (e) {
            console.log("🚀 ~ returnnewPromise ~ e:", e)
          }
        });
      })
      .then(function (result) {
        return new Promise((resolve, reject) => {
          setTimeout(() => resolve(result * 2), 2000);
          try {
            setdiffofdays(
              businessmoment(refcurrdate?.current, 'YYYY-MM-DD').businessDiff(
                businessmoment(refcurrregistered?.current, 'DD-MM-YYYY'),
              ),
            );

          } catch (e) {
            console.log("🚀 ~ returnnewPromise ~ e:", e);
          }
        });
      })
      .then(function (result) {
        return new Promise((resolve, reject) => {

          if (refcurrregistered?.current != null || refcurrregistered?.current != undefined) {
            setTimeout(() => resolve(result * 2), 1000);
            if (refdiffofdays?.current <= 4) {
              setcolor('#0dff00');
            } else if (refdiffofdays?.current > 4 && refdiffofdays?.current <= 7) {
              setcolor('#ff8400');
            } else if (refdiffofdays?.current >= 8) {
              setcolor('#ff0000');
            }
          }
        });
      });
  }, []);

  useEffect(() => {
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/cndn', { ackno: { ackno } })
      .then((response) => {
        setdnpending(response?.data[0]['DN Pending']);
        if (refdnpending?.current == 'Y') {
          setcheckdnpending(true);
        }
        if (response?.data[0]['DNBooked'] != null) {
          setdnbooked((response?.data[0]['DNBooked']).split('-').reverse().join('-'));
        }
        setdncostcenter(response?.data[0]['DNCostCenter']);
        setdnFinalInvoice(response?.data[0]['DNFinalInvoice']);
        setdngl(response?.data[0]['DNGL']);

        if (response?.data[0]['DNRequested'] != null) {
          setdnrequested((response?.data[0]['DNRequested']).split('-').reverse().join('-'));

        }
        if (response?.data[0]['costBooked'] != null) {
          setcostbooked((response?.data[0]['costBooked']).split('-').reverse().join('-'));
        }
        setcostCMAmount(response?.data[0]['costCMAmount']);
        setcostCMAmountCurrency(response?.data[0]['costCMAmountCurrency']);
        setcostCMNumber(response?.data[0]['costCMNumber']);
        if (response?.data[0]['costRequested'] != null) {
          setcostRequested((response?.data[0]['costRequested']).split('-').reverse().join('-'));
        }
        if (response?.data[0]['costRequested'] != null) {

        }
        if (response?.data[0]['feesBooked'] != null) {
          setfeesBooked((response?.data[0]['feesBooked']).split('-').reverse().join('-'));
        }
        setfeesCMAmount(response?.data[0]['feesCMAmount']);
        setfeesCMAmountCurrency(response?.data[0]['feesCMAmountCurrency']);
        setfeesCMNumber(response?.data[0]['feesCMNumber']);
        if (response?.data[0]['feesRequested'] != null) {
          setfeesRequested((response?.data[0]['feesRequested']).split('-').reverse().join('-'));
        }

      })
      .catch(function (error) {
        console.log("🚀 ~ useEffect ~ error:", error);

      });
  }, []);

  useEffect(() => {
    // console.log('This is ackno');
    // console.log(ackno);
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/bsc', { ackno: { ackno } })
      .then((response) => {
        // console.log(response?.data);
        setbscdefect(response?.data[0]['Defect']);
        // console.log(response?.data[0]['Defect']);
        setbscnote(response?.data[0]['Note']);
        setpreventive(response?.data[0]['Preventive']);
        setprocess(response?.data[0]['Process']);
        setroot(response?.data[0]['Root']);
        setTeamlead(response?.data[0]['Teamlead']);
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
      });
  }, []);

  useEffect(() => {
    // console.log('This is ackno');
    // console.log(ackno);
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/bsc', { ackno: { ackno } })
      .then((response) => {
        // console.log(response?.data);
        setbscdefect(response?.data[0]['Defect']);
        // console.log(response?.data[0]['Defect']);
        setbscnote(response?.data[0]['Note']);
        setpreventive(response?.data[0]['Preventive']);
        setprocess(set(response?.data[0]['Process']));
        setroot(response?.data[0]['Root']);
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
      });
  }, []);

  useEffect(() => {
    // console.log('This is ackno');
    // console.log(ackno);
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/salesorder', { ackno: { ackno } })
      .then((response) => {
        // console.log(response?.data);
        setcustname(response?.data['name']);
        setshiptocust(response?.data['shiptocust']);
        setshiptosalesorg(response?.data['shiptosalesorg']);
        setcode(response?.data['code']);
        setdestination(response?.data['destination']);
        setimportzone(response?.data['importzone']);
        setexportzone(response?.data['exportzone']);
        setincoterm1(response?.data['incoterm1']);
        setincoterm2(response?.data['incoterm2']);
        setbilltosalesorg(response?.data['billtosalesorg']);
        setloadingpt1(response?.data['loadingpt1']);
        setloadingpt2(response?.data['loadingpt2']);
        setbillto(response?.data['billto']);
        setshipto(response?.data['shipto']);
        setsoldto(response?.data['soldto']);
        setprofitCenter(response?.data['profitcenter']);
        setpayername(response?.data['payername']);
        // console.log(response?.data['profitcenter']);
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
      });
  }, []);
  useEffect(() => {
    AxiosApi.get(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/bscdefects')
      .then((response) => {
        // console.log(response?.data);
        // console.log('Hi response');
        setbscdefectlist(response?.data);
        // console.log(bscdefectlist);
        // console.log('Hello');
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
      });
  }, []);

  useEffect(() => {
    AxiosApi.get(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/processtypes')
      .then((response) => {
        // console.log(response?.data);
        // console.log('Hi response');
        setbscprocesslist(response?.data);
        // console.log(bscprocesslist);
        // console.log('Hello');
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
      });
  }, []);

  useEffect(() => {
    async function fetchData() {
      await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/salestabledata', { ackno: { ackno } })
        .then((response) => {
          let finaldata = response?.data;
          new Promise(function (resolve, reject) {
            try {
              // console.log('Salestable data:', finaldata);
            } catch (e) {
              // console.log(e);
            }
            setTimeout(() => resolve(1), 2000); // (*)
          }).then(function (result) {
            return new Promise((resolve, reject) => {
              setTimeout(() => resolve(result * 2), 2000);
              try {
                if (finaldata?.salesordertable.length > 0) {
                  setorder3(finaldata?.salesordertable);
                  setsubmitdisabled(false);
                  setsum2(finaldata.prodcostpaid);
                  setsum3(finaldata.plantcostpaid);
                  settotalhl(finaldata.totalhl);
                  setcontaffected(finaldata.contaffected);
                  setloaded(true);
                }
              } catch (e) {
                // console.log(e);
              }
            });
          });
        })
        .catch(function (error) {
          // handle error
          // console.log(error);
        });
    };
    fetchData();

  }, []);

  useEffect(() => {
    new Promise(async function (resolve, reject) {
      try {
        await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafees', { ackno: { ackno } })
          .then(async (response) => {
            let result3 = response?.data;
            setimporttaxesextraclaims(parseFloat(result3[3]?.extraFeesCosts).toFixed(2));
            // console.log('Ex fees');
            // console.log(parseFloat(result3[0]?.extraFeesCosts).toFixed(2));
            setinspectionextraclaims(parseFloat(result3[4]?.extraFeesCosts).toFixed(2));
            setreworkingrestackingextraclaims(parseFloat(result3[9].extraFeesCosts).toFixed(2));
            setdestructiondisposalextraclaims(parseFloat(result3[2]?.extraFeesCosts).toFixed(2));
            setrelabellingextraclaims(parseFloat(result3[8].extraFeesCosts).toFixed(2));
            settransportationextraclaims(parseFloat(result3[10].extraFeesCosts).toFixed(2));
            setproductdiscountsextraclaims(parseFloat(result3[7].extraFeesCosts).toFixed(2));
            setoceanfreightextraclaims(parseFloat(result3[5].extraFeesCosts).toFixed(2));
            setcustomsfeeextraclaims(parseFloat(result3[0].extraFeesCosts).toFixed(2));
            setdemurrageextraclaims(parseFloat(result3[1].extraFeesCosts).toFixed(2));
            setotherextraclaims(parseFloat(result3[6].extraFeesCosts).toFixed(2));

            setimporttaxesextraclaimscurrency(result3[3]?.extraFeesCurrency);
            setinspectionextraclaimscurrency(result3[4]?.extraFeesCurrency);
            setreworkingrestackingextraclaimscurrency(result3[9].extraFeesCurrency);
            setdestructiondisposalextraclaimscurrency(result3[2]?.extraFeesCurrency);
            setrelabellingextraclaimscurrency(result3[8].extraFeesCurrency);
            settransportationextraclaimscurrency(result3[10].extraFeesCurrency);
            setproductdiscountsextraclaimscurrency(result3[7].extraFeesCurrency);
            setoceanfreightextraclaimscurrency(result3[5].extraFeesCurrency);
            setcustomsfeeextraclaimscurrency(result3[0].extraFeesCurrency);
            setdemurrageextraclaimscurrency(result3[1].extraFeesCurrency);
            setotherextraclaimscurrency(result3[6].extraFeesCurrency);

            setmodalimporttaxesextraclaims(parseFloat(result3[3]?.extraFeesCosts).toFixed(2));
            setmodalinspectionextraclaims(parseFloat(result3[4]?.extraFeesCosts).toFixed(2));
            setmodalreworkingrestackingextraclaims(
              parseFloat(result3[9].extraFeesCosts).toFixed(2),
            );
            setmodaldestructiondisposalextraclaims(
              parseFloat(result3[2]?.extraFeesCosts).toFixed(2),
            );
            setmodalrelabellingextraclaims(parseFloat(result3[8].extraFeesCosts).toFixed(2));
            setmodaltransportationextraclaims(parseFloat(result3[10].extraFeesCosts).toFixed(2));
            setmodalproductdiscountsextraclaims(parseFloat(result3[7].extraFeesCosts).toFixed(2));
            setmodaloceanfreightextraclaims(parseFloat(result3[5].extraFeesCosts).toFixed(2));
            setmodalcustomsfeeextraclaims(parseFloat(result3[0].extraFeesCosts).toFixed(2));
            setmodaldemurrageextraclaims(parseFloat(result3[1].extraFeesCosts).toFixed(2));
            setmodalotherextraclaims(parseFloat(result3[6].extraFeesCosts).toFixed(2));

            await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/currclaimed', { ackno: { ackno } })
              .then((response) => {
                // console.log('This is curr claim');
                console.log('anas::', response);
                setcurrclaim(response?.data[0]['currencyClaimed']);
              })
              .catch(function (error) {
                // handle error
                // console.log(error);
              });
          })
          .catch(function (error) {
            // handle error
            // console.log(error);
          });
        await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/prodcost', { ackno: { ackno } })
          .then((response) => {
            setprodcost(response?.data[0]['costClaimed']);
          })
          .catch(function (error) {
            // handle error
            // console.log(error);
          });
      } catch (e) {
        // console.log(e);
      }
      setTimeout(() => resolve(1), 2000); // (*)
    })
      .then(async function (result) {
        return new Promise(async (resolve, reject) => {
          setTimeout(() => resolve(result * 2), 2000);
          try {

            await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
              fromcurrency: importtaxesexclaimscurr.current,
              value: modalimporttaxesexclaims.current,
              tocurrency: currclaim.current,
              ackno: ackno
            }).then((response) => {
              // console.log(response?.data);
              setconvertedimporttaxesextraclaims(parseFloat(response?.data).toFixed(2));
            });
            await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
              fromcurrency: inspectionexclaimscurr?.current,
              value: modalinspectionexclaims?.current,
              tocurrency: currclaim?.current,
              ackno: ackno,
            }).then((response) => {
              // console.log(response?.data);
              setconvertedinspectionextraclaims(parseFloat(response?.data).toFixed(2));
            });
            await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
              fromcurrency: reworkingrestackingexclaimscurr?.current,
              value: modalreworkingrestackingexclaims?.current,
              tocurrency: currclaim?.current,
              ackno: ackno,
            }).then((response) => {
              // console.log(response?.data);
              setconvertedreworkingrestackingextraclaims(parseFloat(response?.data).toFixed(2));
            });
            await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
              fromcurrency: destructiondisposalexclaimscurr?.current,
              value: modaldestructiondisposalexclaims?.current,
              tocurrency: currclaim?.current,
              ackno: ackno,
            }).then((response) => {
              // console.log(response?.data);
              setconverteddestructiondisposalextraclaims(parseFloat(response?.data).toFixed(2));
            });
            await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
              fromcurrency: relabellingexclaimscurr?.current,
              value: modalrelabellingexclaims?.current,
              tocurrency: currclaim?.current,
              ackno: ackno,
            }).then((response) => {
              // console.log(response?.data);
              setconvertedrelabellingextraclaims(parseFloat(response?.data).toFixed(2));
            });
            await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
              fromcurrency: transportationexclaimscurr?.current,
              value: modaltransportationexclaims?.current,
              tocurrency: currclaim?.current,
              ackno: ackno,
            }).then((response) => {
              // console.log(response?.data);
              setconvertedtransportationextraclaims(parseFloat(response?.data).toFixed(2));
            });
            await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
              fromcurrency: productdiscountsexclaimscurr?.current,
              value: modalproductdiscountsexclaims?.current,
              tocurrency: currclaim?.current,
              ackno: ackno,
            }).then((response) => {
              // console.log(response?.data);
              setconvertedproductdiscountsextraclaims(parseFloat(response?.data).toFixed(2));
            });
            await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
              fromcurrency: oceanfreightexclaimscurr?.current,
              value: modaloceanfreightexclaims?.current,
              tocurrency: currclaim?.current,
              ackno: ackno,
            }).then((response) => {
              // console.log(response?.data);
              setconvertedoceanfreightextraclaims(parseFloat(response?.data).toFixed(2));
            });
            await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
              fromcurrency: customsfeeexclaimscurr?.current,
              value: modalcustomsfeeexclaims?.current,
              tocurrency: currclaim?.current,
              ackno: ackno,
            }).then((response) => {
              // console.log(response?.data);
              setconvertedcustomsfeeextraclaims(parseFloat(response?.data).toFixed(2));
            });
            await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
              fromcurrency: demurrageexclaimscurr?.current,
              value: modaldemurrageexclaims?.current,
              tocurrency: currclaim?.current,
              ackno: ackno,
            }).then((response) => {
              // console.log(response?.data);
              setconverteddemurrageextraclaims(parseFloat(response?.data).toFixed(2));
            });
            await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
              fromcurrency: otherexclaimscurr?.current,
              value: modalotherexclaims?.current,
              tocurrency: currclaim?.current,
              ackno: ackno,
            }).then((response) => {
              // console.log(response?.data);
              setconvertedotherextraclaims(parseFloat(response?.data).toFixed(2));
              // console.log(parseFloat(response?.data).toFixed(2));
            });
          } catch (e) {
            // console.log(e);
          }
        });
      })
      .then(function (result) {
        return new Promise((resolve, reject) => {
          // (*)
          setTimeout(() => resolve(result * 2), 1000);
          // console.log('This is the list');
          // console.log(parseFloat(convertedimporttaxesextraclaims));
          // console.log(parseFloat(convertedinspectionextraclaims));
          // console.log(parseFloat(convertedreworkingrestackingextraclaims));
          setsumextraclaims(
            (
              parseFloat(convimporttaxes?.current) +
              parseFloat(convinspection?.current) +
              parseFloat(convreworkingrestacking?.current) +
              parseFloat(convdestructiondisposal?.current) +
              parseFloat(convrelabelling?.current) +
              parseFloat(convtransportation?.current) +
              parseFloat(convproductdiscounts?.current) +
              parseFloat(convoceanfreight?.current) +
              parseFloat(convcustomsfee?.current) +
              parseFloat(convdemurrage?.current) +
              parseFloat(convother?.current)
            ).toFixed(2),
          );
          // console.log(sumextraclaims);
        });
      })
      .then(function (result) {
        return new Promise((resolve, reject) => {
          // (*)
          setTimeout(() => resolve(result * 2), 1000);
          setsumextraclaims2(sumextraclaims);
          // console.log(sumextraclaims);
        });
      })
      .then(function (result) {
        return new Promise((resolve, reject) => {
          settotalextraclaims(
            (
              parseFloat(convimporttaxes?.current) +
              parseFloat(convinspection?.current) +
              parseFloat(convreworkingrestacking?.current) +
              parseFloat(convdestructiondisposal?.current) +
              parseFloat(convrelabelling?.current) +
              parseFloat(convtransportation?.current) +
              parseFloat(convproductdiscounts?.current) +
              parseFloat(convoceanfreight?.current) +
              parseFloat(convcustomsfee?.current) +
              parseFloat(convdemurrage?.current) +
              parseFloat(convother?.current) +
              parseFloat(modalcclaim?.current)
            ).toFixed(2),
          );
          // console.log('The total extra');
        });
      });
  }, []);

  useEffect(() => {
    // console.log('hello result 5');
    new Promise(async function (resolve, reject) {
    await  AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/costpaid', { ackno: { ackno } })
        .then((response) => {
          let result5 = response?.data;
          // console.log('This is result 4:');
          // console.log(result5);
          setimporttaxescostpaid(parseFloat(result5[3]?.extraFeesCosts).toFixed(2));
          setinspectioncostpaid(parseFloat(result5[4]?.extraFeesCosts).toFixed(2));
          setreworkingrestackingcostpaid(parseFloat(result5[9]?.extraFeesCosts).toFixed(2));
          setdestructiondisposalcostpaid(parseFloat(result5[2]?.extraFeesCosts).toFixed(2));
          setrelabellingcostpaid(parseFloat(result5[8].extraFeesCosts).toFixed(2));
          settransportationcostpaid(parseFloat(result5[10].extraFeesCosts).toFixed(2));
          setproductdiscountscostpaid(parseFloat(result5[7].extraFeesCosts).toFixed(2));
          setoceanfreightcostpaid(parseFloat(result5[5].extraFeesCosts).toFixed(2));
          setcustomsfeecostpaid(parseFloat(result5[0].extraFeesCosts).toFixed(2));
          setdemurragecostpaid(parseFloat(result5[1].extraFeesCosts).toFixed(2));
          setothercostpaid(parseFloat(result5[6].extraFeesCosts).toFixed(2));

          setimporttaxescostpaidcurrency(result5[3]?.extraFeesCurrency);
          setinspectioncostpaidcurrency(result5[4]?.extraFeesCurrency);
          setreworkingrestackingcostpaidcurrency(result5[9]?.extraFeesCurrency);
          setdestructiondisposalcostpaidcurrency(result5[2]?.extraFeesCurrency);
          setrelabellingcostpaidcurrency(result5[8].extraFeesCurrency);
          settransportationcostpaidcurrency(result5[10].extraFeesCurrency);
          setproductdiscountscostpaidcurrency(result5[7].extraFeesCurrency);
          setoceanfreightcostpaidcurrency(result5[5].extraFeesCurrency);
          setcustomsfeecostpaidcurrency(result5[0].extraFeesCurrency);
          setdemurragecostpaidcurrency(result5[1].extraFeesCurrency);
          setothercostpaidcurrency(result5[6].extraFeesCurrency);

          setmodalimporttaxescostpaid(parseFloat(result5[3]?.extraFeesCosts).toFixed(2));
          setmodalinspectioncostpaid(parseFloat(result5[4]?.extraFeesCosts).toFixed(2));
          setmodalreworkingrestackingcostpaid(parseFloat(result5[9]?.extraFeesCosts).toFixed(2));
          setmodaldestructiondisposalcostpaid(parseFloat(result5[2]?.extraFeesCosts).toFixed(2));
          setmodalrelabellingcostpaid(parseFloat(result5[8].extraFeesCosts).toFixed(2));
          setmodaltransportationcostpaid(parseFloat(result5[10].extraFeesCosts).toFixed(2));
          setmodalproductdiscountscostpaid(parseFloat(result5[7].extraFeesCosts).toFixed(2));
          setmodaloceanfreightcostpaid(parseFloat(result5[5].extraFeesCosts).toFixed(2));
          setmodalcustomsfeecostpaid(parseFloat(result5[0].extraFeesCosts).toFixed(2));
          setmodaldemurragecostpaid(parseFloat(result5[1].extraFeesCosts).toFixed(2));
          setmodalothercostpaid(parseFloat(result5[6].extraFeesCosts).toFixed(2));
        })
        .catch(function (error) {
          // handle error
          // console.log(error);
        });
      setTimeout(() => resolve(1), 2000);
    }).then( async function (result) {
      return new Promise(async (resolve, reject) => {
        // (*)
        setTimeout(() => resolve(result * 2), 3000);
      await  AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/salestabledata', { ackno: { ackno } })
          .then(async(response) => {
            setsum2(response?.data.prodcostpaid);
            setsum3(response?.data.plantcostpaid);
            settotalhl(response?.data.totalhl);
            setcontaffected(response?.data.contaffected);
          })
          .catch(function (error) {
            // handle error
            // console.log(error);
          });
          console.log(refothercostpaid?.current,"anas")
        setsumcostpaid(
          (
            parseFloat(refimporttaxescostpaid?.current) +
            parseFloat(refinspectioncostpaid?.current) +
            parseFloat(refreworkingrestackingcostpaid?.current) +
            parseFloat(refdestructiondisposalcostpaid?.current) +
            parseFloat(refrelabellingcostpaid?.current) +
            parseFloat(reftransportationcostpaid?.current) +
            parseFloat(refproductdiscountscostpaid?.current) +
            parseFloat(refoceanfreightcostpaid?.current) +
            parseFloat(refcustomsfeecostpaid?.current) +
            parseFloat(refdemurragecostpaid?.current) +
            parseFloat(refothercostpaid?.current)
          ).toFixed(2),
        );
        setsumcostpaid(prev => prev);
      }).then(function (result) {
        return new Promise(async (resolve, reject) => {
         // setsumcostpaid(prev => prev);
        await  settotalcostpaid(
            (parseFloat(refsumcostpaid?.current) + parseFloat(refsum2?.current)).toFixed(2),
          );

          // setsum(parseFloat(0).toFixed(2));
          // setorders3sum(parseFloat(0).toFixed(2));
          //                 for(let i=0;i<order3.length;i++){
          //                   if(order3[i].isAccepted == 'Y'){
          //                     new Promise(function(resolve, reject) {
          //                       setsum((parseFloat(refsum?.current) + parseFloat(order3[i].ImporterCost)).toFixed(2));
          //                       setorders3sum((parseFloat(reforders3sum?.current) + parseFloat(order3[i].PlantCost)).toFixed(2));
          //                       settotalcostpaid((parseFloat(refsumcostpaid?.current) + parseFloat(order3[i].ImporterCost)).toFixed(2));
          //                       settotalplantaccepted((parseFloat(refsumplantaccepted?.current) + parseFloat(reforders3sum?.current)).toFixed(2));
          //                       setTimeout(() => resolve(1), 2000); // (*)
          //                       })
          //                     }}
        });
      });
    });
  }, []);

  useEffect(() => {
    console.log("javat",sumcostpaid,totalcostpaid)
    settotalcostpaid(
      (parseFloat(refsumcostpaid?.current) + parseFloat(refsum2?.current)).toFixed(2),
    );
  },[sumcostpaid,totalcostpaid,sum2])

  useEffect(() => {
    // console.log('hello result 3');

    new Promise(async function (resolve, reject) {
    await  AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/plantaccepted', { ackno: { ackno } })
        .then(async (response) => {
          let result4 = response?.data;

          

          setimporttaxesplantaccepted(parseFloat(result4[3]?.extraFeesCosts).toFixed(2));
          setinspectionplantaccepted(parseFloat(result4[4]?.extraFeesCosts).toFixed(2));
          setreworkingrestackingplantaccepted(parseFloat(result4[9].extraFeesCosts).toFixed(2));
          setdestructiondisposalplantaccepted(parseFloat(result4[2]?.extraFeesCosts).toFixed(2));
          setrelabellingplantaccepted(parseFloat(result4[8].extraFeesCosts).toFixed(2));
          settransportationplantaccepted(parseFloat(result4[10].extraFeesCosts).toFixed(2));
          setproductdiscountsplantaccepted(parseFloat(result4[7].extraFeesCosts).toFixed(2));
          setoceanfreightplantaccepted(parseFloat(result4[5].extraFeesCosts).toFixed(2));
          setcustomsfeeplantaccepted(parseFloat(result4[0].extraFeesCosts).toFixed(2));
          setdemurrageplantaccepted(parseFloat(result4[1].extraFeesCosts).toFixed(2));
          setotherplantaccepted(parseFloat(result4[6].extraFeesCosts).toFixed(2));

          setimporttaxesplantacceptedcurrency(result4[3]?.extraFeesCurrency);
          setinspectionplantacceptedcurrency(result4[4]?.extraFeesCurrency);
          setreworkingrestackingplantacceptedcurrency(result4[9].extraFeesCurrency);
          setdestructiondisposalplantacceptedcurrency(result4[2]?.extraFeesCurrency);
          setrelabellingplantacceptedcurrency(result4[8].extraFeesCurrency);
          settransportationplantacceptedcurrency(result4[10].extraFeesCurrency);
          setproductdiscountsplantacceptedcurrency(result4[7].extraFeesCurrency);
          setoceanfreightplantacceptedcurrency(result4[5].extraFeesCurrency);
          setcustomsfeeplantacceptedcurrency(result4[0].extraFeesCurrency);
          setdemurrageplantacceptedcurrency(result4[1].extraFeesCurrency);
          setotherplantacceptedcurrency(result4[6].extraFeesCurrency);

          setmodalimporttaxesplantaccepted(parseFloat(result4[3]?.extraFeesCosts).toFixed(2));
          setmodalinspectionplantaccepted(parseFloat(result4[4]?.extraFeesCosts).toFixed(2));
          setmodalreworkingrestackingplantaccepted(
            parseFloat(result4[9].extraFeesCosts).toFixed(2),
          );
          setmodaldestructiondisposalplantaccepted(
            parseFloat(result4[2]?.extraFeesCosts).toFixed(2),
          );
          setmodalrelabellingplantaccepted(parseFloat(result4[8].extraFeesCosts).toFixed(2));
          setmodaltransportationplantaccepted(parseFloat(result4[10].extraFeesCosts).toFixed(2));
          setmodalproductdiscountsplantaccepted(parseFloat(result4[7].extraFeesCosts).toFixed(2));
          setmodaloceanfreightplantaccepted(parseFloat(result4[5].extraFeesCosts).toFixed(2));
          setmodalcustomsfeeplantaccepted(parseFloat(result4[0].extraFeesCosts).toFixed(2));
          setmodaldemurrageplantaccepted(parseFloat(result4[1].extraFeesCosts).toFixed(2));
          setmodalotherplantaccepted(parseFloat(result4[6].extraFeesCosts).toFixed(2));
        })
        .catch(function (error) {
          // handle error
          // console.log(error);
        });
      setTimeout(() => resolve(1), 2000);
    }).then(async function (result) {
      return new Promise(async (resolve, reject) => {
        // (*)
        setTimeout(() => resolve(result * 2), 3000);
      await  AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/salestabledata', { ackno: { ackno } })
          .then((response) => {

            setsum2(response?.data.prodcostpaid);
            setsum3(response?.data.plantcostpaid);
            settotalhl(response?.data.totalhl);
            setcontaffected(response?.data.contaffected);
          })
          .catch(function (error) {
            // handle error
            // console.log(error);
          });
//console.log()
console.log("importtaxesplantaccepted:", importtaxesplantaccepted);
console.log("inspectionplantaccepted:", inspectionplantaccepted);
// Log other state variables

        setsumplantaccepted(
          (
            parseFloat(refimporttaxesplantaccepted?.current) +
            parseFloat(refinspectionplantaccepted?.current) +
            parseFloat(refreworkingrestackingplantaccepted?.current) +
            parseFloat(refdestructiondisposalplantaccepted?.current) +
            parseFloat(refrelabellingplantaccepted?.current) +
            parseFloat(reftransportationplantaccepted?.current) +
            parseFloat(refproductdiscountsplantaccepted?.current) +
            parseFloat(refoceanfreightplantaccepted?.current) +
            parseFloat(refcustomsfeeplantaccepted?.current) +
            parseFloat(refdemurrageplantaccepted?.current) +
            parseFloat(refotherplantaccepted?.current)
           
          ).toFixed(2),
        );
      }).then(function (result) {
        return new Promise((resolve, reject) => {
          // (*)
          setTimeout(() => resolve(result * 2), 1000);

          settotalplantaccepted(
            (parseFloat(refsumplantaccepted?.current) + parseFloat(refsum3?.current)).toFixed(2),
          );
         // setabiv(1)
        });
      });
    });
  }, []);

  useEffect(() => {
    console.log("javatplant",sumplantaccepted,totalplantaccepted)
    settotalplantaccepted(
      (parseFloat(refsumplantaccepted?.current) + parseFloat(refsum3?.current)).toFixed(2),
    );
  },[sumplantaccepted,totalplantaccepted,sum3])

  useEffect(() => {
    // console.log('Getting session id');
    AxiosApi.get(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/session/api/id2')
      .then(function (response) {
        // handle success
        let ses = response?.data;
        setsessionid12(ses);
        // console.log(ses);
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
      });
  }, []);

  useEffect(() => {
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + `/download/creditnote/${ackno}`).then(function (response) {
      // console.log(response?.data);
      setfileslist2(response?.data);
      // console.log('Files::', response?.data);
    });
  }, []);

  useEffect(() => {
    // console.log('Getting followups');
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/followup/customerfollowups', { ackno: { ackno } })
      .then(function (response) {
        setcustomerfollowup(response?.data);
        // console.log(response?.data);
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
      });
  }, [reloadstate]);

  useEffect(() => {
    // console.log('This is ackno');
    // console.log(ackno);
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/no', { ackno: { ackno } })
      .then((response) => {
        // console.log('Getting the posted data');
        // console.log(response);
        // console.log(response?.data);
        let result1 = response?.data[0];
        // console.log('Final result');
        // console.log(result1);
        // console.log('Above is the final result');
        setcompanyName(result1['companyName']);
        setackno(result1['acknowledgementNumber']);
        setcomplaintno(result1['complaintNumber']);
        setheadercomplainttype(result1['typeOfComplaint']);
        settypeofcomplaint(result1['typeOfComplaint']);
        setoldcomplaintno(result1['oldcomplaintNumber']);
        setcomplaintdesc(result1['complaintDesc']);
        setreasonofrejection123(result1['reasonRejection']);
        setpartiallyreason(result1['reasonPartially']);
        setextradocrequested(result1['extraDocRequested']);
        setinvalidmess(result1['invalidReason']);
        if (result1['uploaded'] != null) {
          setupload(result1['uploaded'].split('-').reverse().join('-'));
        }
        if (
          result1['complaintNumber'] != 'NULL' &&
          result1['complaintNumber'] != 0 &&
          result1['complaintNumber'] != null
        ) {
          let ackno1 = result1['acknowledgementNumber'];
          let year = ackno1.toString().slice(-7, -3);
          setfinallink(
            `${process.env.REACT_APP_SHAREPOINT_URL}/${year}/${result1['companyName']}/${result1['complaintNumber']}`,
          );
        } else {
          let ackno1 = result1['acknowledgementNumber'];
          let year = ackno1.toString().slice(-7, -3);
          setfinallink(
            `${process.env.REACT_APP_SHAREPOINT_URL}/${year}/${result1['companyName']}/${result1['acknowledgementNumber']}`,
          );
        }
        console.log("setfinallink", setfinallink)
        console.log("finallink", finallink)
        if (result1['complaintReceivedDate'] != null) {
          setcomplaintsubmission(result1['complaintReceivedDate'].split('-').reverse().join('-'));
        }
        setextradue(result1['extra_dueDate']);
        setdropextraduedate(result1['extra_dueDate']);
        setregisteredby(result1['registeredBy']);
        setcqagent(result1['cqAgent']);
        setdefect(result1['defectType']);
        if (result1['customerContacted'] != null) {
          setcusdate(result1['customerContacted'].split('-').reverse().join('-'));
        }
        setownerdate(result1['ownerContacted']);
        setdiffquantityclaimed(result1['differentQuantityClaimed']);
        if (result1['closedDate'] != null) {
          setclosedate(result1['closedDate'].split('-').reverse().join('-'));
        }
        setcomment(result1['comment']);
        setnote(result1['note']);
        setstatus(result1['status']);
        setaccepted(result1['accepted']);
        setacceptedBy(result1['acceptedBy']);
        if (result1['registrationDate'] != null) {
          setregdate(result1['registrationDate'].split('-').reverse().join('-'));
        }
        settype(result1['type']);
        setsubtype(result1['subtype']);
        setcostclaim(parseFloat(result1['costClaimed']).toFixed(2));
        setmodalcostclaim(parseFloat(result1['costClaimed']).toFixed(2));
        setimportercostpaidcurr(result1['currencyImporter']);
        setmodalcostclaimcurr(result1['currencyClaimed']);
        setcurrclaim(result1['currencyClaimed']);
        setplantcostpaidcurr(result1['currencyPlant']);
        setextrafeesplant(result1['extraFeesPlant']);
        setresolution(result1['resolution']);
        setmanagermessage(result1['managerMessage']);
        setrules(result1['rulesOfEngagement']);
        setacceptedByAm(result1['acceptedByAm']);
        setacceptedByVlc(result1['acceptedByVlc']);
        setacceptedByPlantLogi(result1['acceptedByPlantLogi']);
        setemail(result1['email']);
        if (refacceptedByAm?.current == 'Y') {
          setcheckedByAm(true);
        }
        if (refacceptedByVlc?.current == 'Y') {
          setcheckedByVlc(true);
        }
        if (refacceptedByPlantLogi?.current == 'Y') {
          setcheckedByPlantLogi(true);
        }
        if (refrulesOfEngagement?.current == 'Y') {
          setcheckedrules(true);
        }
        setcostAcceptedByAm(result1['costAcceptedByAm']);
        setcostAcceptedByVlc(result1['costAcceptedByVlc']);
        setcostAcceptedByPlantLogi(result1['costAcceptedByPlantLogi']);
        setcurrAccAm(result1['currAccAm']);
        setcurrAccVlc(result1['currAccVlc']);
        setcurrAccPl(result1['currAccPl']);
        setcurrExtraAm(result1['currExtraAm']);
        setcurrExtraVlc(result1['currExtraVlc']);
        setcurrExtraPl(result1['currExtraPl']);
        // console.log('This is the currExtraPl');
        // console.log(result1['currExtraPl']);
        setfeesAcceptedByAm(result1['feesAcceptedByAm']);
        setfeesAcceptedByVlc(result1['feesAcceptedByVlc']);
        setfeesAcceptedbyPl(result1['feesAcceptedbyPl']);
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
      });
  }, []);


  useEffect(() => {
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/internalfollowups', { ackno: ackno })
      .then((response) => {
        setorder4(response?.data);
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
      });
  }, []);

  const options = [
    {
      value: 'EUR',
      label: 'EUR'
    }, {
      value: 'USD',
      label: 'USD'
    }, {
      value: 'GBP',
      label: 'GBP'
    }, {
      value: 'MXN',
      label: 'MXN'
    }, {
      value: 'AED',
      label: 'AED'
    }, {
      value: 'AOA',
      label: 'AOA'
    }, {
      value: 'ARS',
      label: 'ARS'
    }, {
      value: 'AUD',
      label: 'AUD'
    }, {
      value: 'BAM',
      label: 'BAM'
    }, {
      value: 'BBD',
      label: 'BBD'
    }, {
      value: 'BGN',
      label: 'BGN'
    }, {
      value: 'BHD',
      label: 'BHD'
    }, {
      value: 'BOB',
      label: 'BOB'
    }, {
      value: 'BRL',
      label: 'BRL'
    }, {
      value: 'BWP',
      label: 'BWP'
    }, {
      value: 'BYN',
      label: 'BYN'
    }, {
      value: 'CAD',
      label: 'CAD'
    }, {
      value: 'CHF',
      label: 'CHF'
    }, {
      value: 'CLP',
      label: 'CLP'
    }, {
      value: 'CNY',
      label: 'CNY'
    }, {
      value: 'COP',
      label: 'COP'
    }, {
      value: 'CRC',
      label: 'CZK'
    }, {
      value: 'DKK',
      label: 'DKK'
    }, {
      value: 'DJF',
      label: 'DJF'
    }, {
      value: 'DOP',
      label: 'DOP'
    }, {
      value: 'DZD',
      label: 'DZD'
    }, {
      value: 'ETB',
      label: 'ETB'
    }, {
      value: 'XCD',
      label: 'XCD'
    }, {
      value: 'GHS',
      label: 'GHS'
    }, {
      value: 'GTQ',
      label: 'GTQ'
    }, {
      value: 'HKD',
      label: 'HKD'
    }, {
      value: 'HNL',
      label: 'HNL'
    }, {
      value: 'HRK',
      label: 'HRK'
    }, {
      value: 'HUF',
      label: 'HUF'
    }, {
      value: 'INR',
      label: 'INR'
    }, {
      value: 'ISK',
      label: 'ISK'
    }, {
      value: 'ILS',
      label: 'ILS'
    }, {
      value: 'JPY',
      label: 'JPY'
    }, {
      value: 'KES',
      label: 'KES'
    }, {
      value: 'KRW',
      label: 'KRW'
    }, {
      value: 'KZT',
      label: 'KZT'
    }, {
      value: 'LSL',
      label: 'LSL'
    }, {
      value: 'MAD',
      label: 'MAD'
    }, {
      value: 'MUR',
      label: 'MUR'
    }, {
      value: 'MWK',
      label: 'MWK'
    }, {
      value: 'MYR',
      label: 'MYR'
    }, {
      value: 'MZN',
      label: 'MZN'
    }, {
      value: 'NAD',
      label: 'NAD'
    }, {
      value: 'NGN',
      label: 'NGN'
    }, {
      value: 'NOK',
      label: 'NOK'
    }, {
      value: 'NZD',
      label: 'NZD'
    }, {
      value: 'OMR',
      label: 'OMR'
    }, {
      value: 'PEN',
      label: 'PEN'
    }, {
      value: 'PHP',
      label: 'PHP'
    }, {
      value: 'PLN',
      label: 'PLN'
    }, {
      value: 'RON',
      label: 'RON'
    }, {
      value: 'RSD',
      label: 'RSD'
    }, {
      value: 'RUB',
      label: 'RUB'
    }, {
      value: 'SAR',
      label: 'SAR'
    }, {
      value: 'SDG',
      label: 'SDG'
    }, {
      value: 'SEK',
      label: 'SEK'
    }, {
      value: 'SGD',
      label: 'SGD'
    }, {
      value: 'SSP',
      label: 'SSP'
    }, {
      value: 'SZL',
      label: 'SZL'
    }, {
      value: 'THB',
      label: 'THB'
    }, {
      value: 'TRY',
      label: 'TRY'
    }, {
      value: 'TZS',
      label: 'TZS'
    }, {
      value: 'UAH',
      label: 'UAH'
    }, {
      value: 'UGX',
      label: 'UGX'
    }, {
      value: 'UYU',
      label: 'UYU'
    }, {
      value: 'VEF',
      label: 'VEF'
    }, {
      value: 'VND',
      label: 'VND'
    }, {
      value: 'ZAR',
      label: 'ZAR'
    }, {
      value: 'ZMW',
      label: 'ZMW'
    },];






  //cq team and spoc check
  function handleAccepted(event) {
    setaccepted(event.target.value);
  }
  function handleSetType(e) {
    settype(e.target.value);
    // console.log('settype:', e.target.value, type);
  }

  window.addEventListener('unload', function (event) {
    const uploadurl2 = process.env.REACT_APP_API_ROOT_URL_PREFIX + '/delete/api' + '/' + refsessionid12?.current;
    AxiosApi.post(uploadurl2).then(function (response) {
      let folder = response;
      // console.log(folder);
    });
    AxiosApi.get(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/session/api/id2')
      .then(function (response) {
        // handle success
        let ses = response?.data;
        setsessionid12(ses);
        // console.log(ses);
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
      });
  });

  useEffect(() => {
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/complainttype', { ackno: ackno })
      .then(function (response) {
        // handle success
        // console.log(response?.data);
        setcomplaintType(response?.data[0].L1);
        // console.log('This is complaint type');
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
      });
  }, []);

  useEffect(() => {
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/actions', { ackno: ackno })
      .then(function (response) {
        // handle success
        // console.log(response?.data);
        setactionslist(response?.data);
        // console.log('This is complaint type');
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
      });
  }, []);

  useEffect(() => {
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/owners', { ackno: ackno })
      .then(function (response) {
        // handle success
        // console.log(response?.data);
        setownerslist(response?.data);
        // console.log('This is complaint type');
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
      });
  }, []);

  useEffect(() => {
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/spoclist', { ackno: ackno })
      .then(function (response) {
        // handle success
        // console.log(response?.data);
        setspoclist(response?.data);
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
      });
  }, []);

  useEffect(() => {
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/cqteam', { ackno: ackno })
      .then(function (response) {
        // handle success
        // console.log('cqteam');
        // console.log(response?.data);
        setcqteam(response?.data);
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
      });
  }, []);

  let username, role;

  useEffect(() => {
    // GET request using AxiosApi inside useEffect React hook
    // console.log('Getting user infro for cq team ');
    username = localStorage.getItem('username');
    role = localStorage.getItem('role');

    AxiosApi.get(process.env.REACT_APP_API_ROOT_URL_PREFIX + `/checkacknopresent?ackno=${ackno}&email=${username}&role=${role}&parentAccountNumber=""`,
    ).then(function (response) {
      // handle success
      // console.log(response?.data);
      setcheckacknopresentspoc(response?.data);
      setcheckacknopresentcqteam(response?.data);
      // console.log('This is checkacno prsent');
    }).catch(function (error) {
      // handle error
      // console.log(error);
    });
  }, []);


  function Downloadfile(finalfilename) {
    // console.log(e.target.outerText);
    AxiosApi({
      url: process.env.REACT_APP_API_ROOT_URL_PREFIX + '/download2/files',
      method: 'POST',
      responseType: 'arraybuffer', // Important
      data: {
        ackno: acknowledgementNumber,
        compno: complaintNumber,
        compname: companyName,
        filename: finalfilename,
        typeofcomp: type,
      },
    }).then((response) => {
      FileDownload(response?.data, `${finalfilename}`);
    });
  }

  /*
    
    let result2 = obj.extrafees;
    setextraclaimsfeelist(result2);
    setmodalextraclaimsfeelist(result2);
    
    // console.log("This is result 2:")
    // console.log(result2);
    */


  const orders4 = (action) => {
    if (!action) {
      return order4;
    } else {
      switch (action.actionType) {
        case 'addRow':
          let newRow = {};
          newRow.id = order4?.length + 1;
          newRow.author = 'Author';
          newRow.date_start = ``;
          newRow.owner = `${owner2}`;
          newRow.action = `${cqaction2}`;
          newRow.comment = `${comment2}`;
          newRow.date_finish = `${String(moment(date_finish2).format('DD-MM-YYYY'))}`;
          setorder4([...order4, newRow]);
          // console.log('addrow');
          return order4;
        case 'deleteRow':
          //this delets different rows only
          let new_state = order4.filter(
            (row) => row.id !== action.row || row.salesorder4 !== action.salesorder4,
          );
          // console.log('deleterow');
          setorder4([...new_state]);
          return order4;
        default:
          return order4;
      }
    }
  };

  const handleDeleteComplaint = () => {
    // console.log('Hey Delete');
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/deletecomplaint', { ackno: { ackno } })
      .then((response) => {
        // console.log(response?.data);
        // console.log('Complaint deleted');
      })
      .then(() => {
        AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/deletecomplaintdesc', { ackno: { ackno } }).then((response) => {
          // console.log(response?.data);
          // console.log('Complaint deleted');
        });
      })
      .then(() => {
        AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/deleteacceptedextrafees', { ackno: { ackno } }).then(
          (response) => {
            // console.log(response?.data);
            // console.log('Complaint deleted');
          },
        );
      })
      .then(() => {
        AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/deletebsc', { ackno: { ackno } }).then((response) => {
          // console.log(response?.data);
          // console.log('Complaint deleted');
        });
      })
      .then(() => {
        AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/deletecndn', { ackno: { ackno } }).then((response) => {
          // console.log(response?.data);
          // console.log('Complaint deleted');
        });
      })
      .then(() => {
        AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/deleteextrafees', { ackno: { ackno } }).then((response) => {
          // console.log(response?.data);
          // console.log('Complaint deleted');
        });
      })
      .then(() => {
        AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/deletefollowup', { ackno: { ackno } }).then((response) => {
          // console.log(response?.data);
          // console.log('Complaint deleted');
        });
      })
      .then(() => {
        AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/deleteplantextrafees', { ackno: { ackno } }).then((response) => {
          // console.log(response?.data);
          // console.log('Complaint deleted');
        });
      })
      .then(() => {
        let tempemailid = localStorage.getItem('username');
        AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/deletefileuploads', {
          ackno: { ackno },
          email: { tempemailid },
        }).then((response) => {
          // console.log(response?.data);
          // console.log('Files deleted');
        });
      })
      .then(() => {
        alert('This complaint has been successsfully deleted');
      })
      .then(() => {
        // console.log('All complaint data deleted');
        navigate('/complaints');
      });
  };

  const handleChangetab = (event, newValue) => {
    setValue(newValue);
  };

  const handleaddextrachange = () => {
    setshowaddextradays(!showaddextradays);
  };
  const handleOnChange1 = () => {
    setcheckedByAm(!checkedByAm);
    if (refcheckedByAm?.current) {
      setacceptedByAm('Y');
      // console.log(refacceptedByAm?.current);
    } else {
      setacceptedByAm('N');
      // console.log(refacceptedByAm?.current);
    }
  };
  const handleOnChange2 = () => {
    setcheckedByVlc(!checkedByVlc);
    if (refcheckedByVlc?.current) {
      setacceptedByVlc('Y');
      // console.log(refacceptedByVlc?.current);
    } else {
      setacceptedByVlc('N');
      // console.log(refacceptedByVlc?.current);
    }
  };
  const handleOnChange3 = () => {
    setcheckedByPlantLogi(!checkedByPlantLogi);
    if (refcheckedByPlantLogi?.current) {
      setacceptedByPlantLogi('Y');
      // console.log(refacceptedByPlantLogi?.current);
    } else {
      setacceptedByPlantLogi('N');
      // console.log(refacceptedByPlantLogi?.current);
    }
  };

  const handleOnChange4 = () => {
    setcheckedrules(!checkedrulesOfEngagement);
    if (refcheckedrulesOfEngagement?.current) {
      setrules('Y');
      // console.log(refrulesOfEngagement?.current);
    } else {
      setrules('N');
      // console.log(refrulesOfEngagement?.current);
    }
  };
  const handleOnChange5 = () => {
    setcheckdnpending(!checkdnpending);
    if (refcheckdnpending?.current) {
      setdnpending('Y');
      // console.log(refdnpending?.current);
    } else {
      setdnpending('N');
      // console.log(refdnpending?.current);
    }
  };
  const handleaddextradays = () => {
    setextradue(parseInt(dropextraduedate));
    // console.log(parseInt(dropextraduedate));
    // console.log(extra_dueDate);
  };

  const handleupdatesalesorder = () => {
    setsubmitdisabled(true);
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/save/api/salesorder', { salesorderinfo: order3, ackno: { ackno } })
      .then((response) => {
        console.log("🚀 ~ 1.then ~ response:", response?.data)
        // console.log(response?.data);
         window.location.reload();
      })
      .then((response) => {
        console.log("🚀 ~ 2.then ~ response:", response?.data);
        // window.location.reload();
      });
  };

  const handleShow1 = () => setShow1(true);
  const handleSave1 = () => {
    setShow1(false);
    setcostclaim(modalcostclaim);
    setcurrclaim(modalcostclaimcurr);

    new Promise(async function (resolve, reject) {
      try {
        setShow2(false);
        setimporttaxesextraclaims(parseFloat(modalimporttaxesextraclaims).toFixed(2));
        setinspectionextraclaims(parseFloat(modalinspectionextraclaims).toFixed(2));
        setreworkingrestackingextraclaims(
          parseFloat(modalreworkingrestackingextraclaims).toFixed(2),
        );
        setdestructiondisposalextraclaims(
          parseFloat(modaldestructiondisposalextraclaims).toFixed(2),
        );
        setrelabellingextraclaims(parseFloat(modalrelabellingextraclaims).toFixed(2));
        settransportationextraclaims(parseFloat(modaltransportationextraclaims).toFixed(2));
        setproductdiscountsextraclaims(parseFloat(modalproductdiscountsextraclaims).toFixed(2));
        setoceanfreightextraclaims(parseFloat(modaloceanfreightextraclaims).toFixed(2));
        setcustomsfeeextraclaims(parseFloat(modalcustomsfeeextraclaims).toFixed(2));
        setdemurrageextraclaims(parseFloat(modaldemurrageextraclaims).toFixed(2));
        setotherextraclaims(parseFloat(modalotherextraclaims).toFixed(2));
        await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
          fromcurrency: importtaxesexclaimscurr,
          value: modalimporttaxesextraclaims,
          tocurrency: modalcclaimcurr?.current,
          ackno: ackno,
        }).then((response) => {
          // console.log(response?.data);
          setconvertedimporttaxesextraclaims(parseFloat(response?.data).toFixed(2));
        });
        await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
          fromcurrency: inspectionextraclaimscurrency,
          value: modalinspectionextraclaims,
          tocurrency: modalcclaimcurr?.current,
          ackno: ackno,
        }).then((response) => {
          // console.log(response?.data);
          setconvertedinspectionextraclaims(parseFloat(response?.data).toFixed(2));
        });
        await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
          fromcurrency: reworkingrestackingextraclaimscurrency,
          value: modalreworkingrestackingextraclaims,
          tocurrency: modalcclaimcurr?.current,
          ackno: ackno,
        }).then((response) => {
          // console.log(response?.data);
          setconvertedreworkingrestackingextraclaims(parseFloat(response?.data).toFixed(2));
        });
        await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
          fromcurrency: destructiondisposalextraclaimscurrency,
          value: modaldestructiondisposalextraclaims,
          tocurrency: modalcclaimcurr?.current,
          ackno: ackno,
        }).then((response) => {
          // console.log(response?.data);
          setconverteddestructiondisposalextraclaims(parseFloat(response?.data).toFixed(2));
        });
        await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
          fromcurrency: relabellingextraclaimscurrency,
          value: modalrelabellingextraclaims,
          tocurrency: modalcclaimcurr?.current,
          ackno: ackno,
        }).then((response) => {
          // console.log(response?.data);
          setconvertedrelabellingextraclaims(parseFloat(response?.data).toFixed(2));
        });
        await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
          fromcurrency: transportationextraclaimscurrency,
          value: modaltransportationextraclaims,
          tocurrency: modalcclaimcurr?.current,
          ackno: ackno,
        }).then((response) => {
          // console.log(response?.data);
          setconvertedtransportationextraclaims(parseFloat(response?.data).toFixed(2));
        });
        await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
          fromcurrency: productdiscountsextraclaimscurrency,
          value: modalproductdiscountsextraclaims,
          tocurrency: modalcclaimcurr?.current,
          ackno: ackno,
        }).then((response) => {
          // console.log(response?.data);
          setconvertedproductdiscountsextraclaims(parseFloat(response?.data).toFixed(2));
        });
        await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
          fromcurrency: oceanfreightextraclaimscurrency,
          value: modaloceanfreightextraclaims,
          tocurrency: modalcclaimcurr?.current,
          ackno: ackno,
        }).then((response) => {
          // console.log(response?.data);
          setconvertedoceanfreightextraclaims(parseFloat(response?.data).toFixed(2));
        });
        await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
          fromcurrency: customsfeeextraclaimscurrency,
          value: modalcustomsfeeextraclaims,
          tocurrency: modalcclaimcurr?.current,
          ackno: ackno,
        }).then((response) => {
          // console.log(response?.data);
          setconvertedcustomsfeeextraclaims(parseFloat(response?.data).toFixed(2));
        });
        await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
          fromcurrency: demurrageextraclaimscurrency,
          value: modaldemurrageextraclaims,
          tocurrency: modalcclaimcurr?.current,
          ackno: ackno,
        }).then((response) => {
          // console.log(response?.data);
          setconverteddemurrageextraclaims(parseFloat(response?.data).toFixed(2));
        });
        await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
          fromcurrency: otherextraclaimscurrency,
          value: modalotherextraclaims,
          tocurrency: modalcclaimcurr?.current,
          ackno: ackno,
        }).then((response) => {
          // console.log(response?.data);
          setconvertedotherextraclaims(parseFloat(response?.data).toFixed(2));
          // console.log(parseFloat(response?.data).toFixed(2));
        });
      } catch (e) {
        // console.log(e);
      }
      setTimeout(() => resolve(1), 1000); // (*)
    })
      .then(function (result) {
        return new Promise((resolve, reject) => {
          // (*)
          setTimeout(() => resolve(result * 2), 1000);
          // console.log('This is the list');
          // console.log(parseFloat(convertedimporttaxesextraclaims));
          // console.log(parseFloat(convertedinspectionextraclaims));
          // console.log(parseFloat(convertedreworkingrestackingextraclaims));
          setsumextraclaims(
            (
              parseFloat(convimporttaxes?.current) +
              parseFloat(convinspection?.current) +
              parseFloat(convreworkingrestacking?.current) +
              parseFloat(convdestructiondisposal?.current) +
              parseFloat(convrelabelling?.current) +
              parseFloat(convtransportation?.current) +
              parseFloat(convproductdiscounts?.current) +
              parseFloat(convoceanfreight?.current) +
              parseFloat(convcustomsfee?.current) +
              parseFloat(convdemurrage?.current) +
              parseFloat(convother?.current)
            ).toFixed(2),
          );
          // console.log(sumextraclaims);
        });
      })
      .then(function (result) {
        return new Promise((resolve, reject) => {
          // (*)
          setTimeout(() => resolve(result * 2), 1000);
          setsumextraclaims2(sumextraclaims);
          // console.log(sumextraclaims);
        });
      })
      .then(function (result) {
        return new Promise((resolve, reject) => {
          settotalextraclaims(
            (
              parseFloat(convimporttaxes?.current) +
              parseFloat(convinspection?.current) +
              parseFloat(convreworkingrestacking?.current) +
              parseFloat(convdestructiondisposal?.current) +
              parseFloat(convrelabelling?.current) +
              parseFloat(convtransportation?.current) +
              parseFloat(convproductdiscounts?.current) +
              parseFloat(convoceanfreight?.current) +
              parseFloat(convcustomsfee?.current) +
              parseFloat(convdemurrage?.current) +
              parseFloat(convother?.current) +
              parseFloat(modalcostclaim)
            ).toFixed(2),
          );
        });
      });
  };
  const handleClose1 = () => {
    setShow1(false);
    setmodalcostclaim(costClaimed);
    setmodalcostclaimcurr(currencyClaimed);
  };

  const handleshowcurr = () => {
    setshowcurr(true);
  };
  const handleClosecurr = () => {
    setshowcurr(false);
  };


  const handleShow2 = () => setShow2(true);
  const handleClose2 = () => {
    setShow2(false);
    setmodalimporttaxesextraclaims(parseFloat(importtaxesextraclaims).toFixed(2));
    setmodalinspectionextraclaims(parseFloat(inspectionextraclaims).toFixed(2));
    setmodalreworkingrestackingextraclaims(parseFloat(reworkingrestackingextraclaims).toFixed(2));
    setmodaldestructiondisposalextraclaims(parseFloat(destructiondisposalextraclaims).toFixed(2));
    setmodalrelabellingextraclaims(parseFloat(relabellingextraclaims).toFixed(2));
    setmodaltransportationextraclaims(parseFloat(transportationextraclaims).toFixed(2));
    setmodalproductdiscountsextraclaims(parseFloat(productdiscountsextraclaims).toFixed(2));
    setmodaloceanfreightextraclaims(parseFloat(oceanfreightextraclaims).toFixed(2));
    setmodalcustomsfeeextraclaims(parseFloat(customsfeeextraclaims).toFixed(2));
    setmodaldemurrageextraclaims(parseFloat(demurrageextraclaims).toFixed(2));
    setmodalotherextraclaims(parseFloat(otherextraclaims).toFixed(2));
  };

  const handleSave2 = () => {
    new Promise(async function (resolve, reject) {
      try {
        setShow2(false);

        setimporttaxesextraclaims(parseFloat(modalimporttaxesextraclaims).toFixed(2));
        setinspectionextraclaims(parseFloat(modalinspectionextraclaims).toFixed(2));
        setreworkingrestackingextraclaims(
          parseFloat(modalreworkingrestackingextraclaims).toFixed(2),
        );
        setdestructiondisposalextraclaims(
          parseFloat(modaldestructiondisposalextraclaims).toFixed(2),
        );
        setrelabellingextraclaims(parseFloat(modalrelabellingextraclaims).toFixed(2));
        settransportationextraclaims(parseFloat(modaltransportationextraclaims).toFixed(2));
        setproductdiscountsextraclaims(parseFloat(modalproductdiscountsextraclaims).toFixed(2));
        setoceanfreightextraclaims(parseFloat(modaloceanfreightextraclaims).toFixed(2));
        setcustomsfeeextraclaims(parseFloat(modalcustomsfeeextraclaims).toFixed(2));
        setdemurrageextraclaims(parseFloat(modaldemurrageextraclaims).toFixed(2));
        setotherextraclaims(parseFloat(modalotherextraclaims).toFixed(2));
        await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
          fromcurrency: importtaxesextraclaimscurrency,
          value: modalimporttaxesextraclaims,
          tocurrency: currencyClaimed,
          ackno: ackno,
        }).then((response) => {
          // console.log(response?.data);
          setconvertedimporttaxesextraclaims(parseFloat(response?.data).toFixed(2));
        });
        await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
          fromcurrency: inspectionextraclaimscurrency,
          value: modalinspectionextraclaims,
          tocurrency: currencyClaimed,
          ackno: ackno,
        }).then((response) => {
          // console.log(response?.data);
          setconvertedinspectionextraclaims(parseFloat(response?.data).toFixed(2));
        });
        await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
          fromcurrency: reworkingrestackingextraclaimscurrency,
          value: modalreworkingrestackingextraclaims,
          tocurrency: currencyClaimed,
          ackno: ackno,
        }).then((response) => {
          // console.log(response?.data);
          setconvertedreworkingrestackingextraclaims(parseFloat(response?.data).toFixed(2));
        });
        await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
          fromcurrency: destructiondisposalextraclaimscurrency,
          value: modaldestructiondisposalextraclaims,
          tocurrency: currencyClaimed,
          ackno: ackno,
        }).then((response) => {
          // console.log(response?.data);
          setconverteddestructiondisposalextraclaims(parseFloat(response?.data).toFixed(2));
        });
        await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
          fromcurrency: relabellingextraclaimscurrency,
          value: modalrelabellingextraclaims,
          tocurrency: currencyClaimed,
          ackno: ackno,
        }).then((response) => {
          // console.log(response?.data);
          setconvertedrelabellingextraclaims(parseFloat(response?.data).toFixed(2));
        });
        await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
          fromcurrency: transportationextraclaimscurrency,
          value: modaltransportationextraclaims,
          tocurrency: currencyClaimed,
          ackno: ackno,
        }).then((response) => {
          // console.log(response?.data);
          setconvertedtransportationextraclaims(parseFloat(response?.data).toFixed(2));
        });
        await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
          fromcurrency: productdiscountsextraclaimscurrency,
          value: modalproductdiscountsextraclaims,
          tocurrency: currencyClaimed,
          ackno: ackno,
        }).then((response) => {
          // console.log(response?.data);
          setconvertedproductdiscountsextraclaims(parseFloat(response?.data).toFixed(2));
        });
        await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
          fromcurrency: oceanfreightextraclaimscurrency,
          value: modaloceanfreightextraclaims,
          tocurrency: currencyClaimed,
          ackno: ackno,
        }).then((response) => {
          // console.log(response?.data);
          setconvertedoceanfreightextraclaims(parseFloat(response?.data).toFixed(2));
        });
        await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
          fromcurrency: customsfeeextraclaimscurrency,
          value: modalcustomsfeeextraclaims,
          tocurrency: currencyClaimed,
          ackno: ackno,
        }).then((response) => {
          // console.log(response?.data);
          setconvertedcustomsfeeextraclaims(parseFloat(response?.data).toFixed(2));
        });
        await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
          fromcurrency: demurrageextraclaimscurrency,
          value: modaldemurrageextraclaims,
          tocurrency: currencyClaimed,
          ackno: ackno,
        }).then((response) => {
          // console.log(response?.data);
          setconverteddemurrageextraclaims(parseFloat(response?.data).toFixed(2));
        });
        await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/complaint/api/extrafeesconversion', {
          fromcurrency: otherextraclaimscurrency,
          value: modalotherextraclaims,
          tocurrency: currencyClaimed,
          ackno: ackno,
        }).then((response) => {
          // console.log(response?.data);
          setconvertedotherextraclaims(parseFloat(response?.data).toFixed(2));
          // console.log(parseFloat(response?.data).toFixed(2));
        });
      } catch (e) {
        // console.log(e);
      }
      setTimeout(() => resolve(1), 1000); // (*)
    })
      .then(function (result) {
        return new Promise((resolve, reject) => {
          // (*)
          setTimeout(() => resolve(result * 2), 1000);
          // console.log('This is the list');
          // console.log(parseFloat(convertedimporttaxesextraclaims));
          // console.log(parseFloat(convertedinspectionextraclaims));
          // console.log(parseFloat(convertedreworkingrestackingextraclaims));
          setsumextraclaims(
            (
              parseFloat(convimporttaxes?.current) +
              parseFloat(convinspection?.current) +
              parseFloat(convreworkingrestacking?.current) +
              parseFloat(convdestructiondisposal?.current) +
              parseFloat(convrelabelling?.current) +
              parseFloat(convtransportation?.current) +
              parseFloat(convproductdiscounts?.current) +
              parseFloat(convoceanfreight?.current) +
              parseFloat(convcustomsfee?.current) +
              parseFloat(convdemurrage?.current) +
              parseFloat(convother?.current)
            ).toFixed(2),
          );
          // console.log(sumextraclaims);
        });
      })
      .then(function (result) {
        return new Promise((resolve, reject) => {
          // (*)
          setTimeout(() => resolve(result * 2), 1000);
          setsumextraclaims2(sumextraclaims);
          // console.log(sumextraclaims);
        });
      })
      .then(function (result) {
        return new Promise((resolve, reject) => {
          settotalextraclaims(
            (
              parseFloat(convimporttaxes?.current) +
              parseFloat(convinspection?.current) +
              parseFloat(convreworkingrestacking?.current) +
              parseFloat(convdestructiondisposal?.current) +
              parseFloat(convrelabelling?.current) +
              parseFloat(convtransportation?.current) +
              parseFloat(convproductdiscounts?.current) +
              parseFloat(convoceanfreight?.current) +
              parseFloat(convcustomsfee?.current) +
              parseFloat(convdemurrage?.current) +
              parseFloat(convother?.current) +
              parseFloat(modalcostclaim)
            ).toFixed(2),
          );
        });
      });
  };


  const handleShow3 = () => setShow3(true);
  const handleClose3 = () => {
    setShow3(false);
    setmodalimporttaxescostpaid(parseFloat(importtaxescostpaid).toFixed(2));
    setmodalinspectioncostpaid(parseFloat(inspectioncostpaid).toFixed(2));
    setmodalreworkingrestackingcostpaid(parseFloat(reworkingrestackingcostpaid).toFixed(2));
    setmodaldestructiondisposalcostpaid(parseFloat(destructiondisposalcostpaid).toFixed(2));
    setmodalrelabellingcostpaid(parseFloat(relabellingcostpaid).toFixed(2));
    setmodaltransportationcostpaid(parseFloat(transportationcostpaid).toFixed(2));
    setmodalproductdiscountscostpaid(parseFloat(productdiscountscostpaid).toFixed(2));
    setmodaloceanfreightcostpaid(parseFloat(oceanfreightcostpaid).toFixed(2));
    setmodalcustomsfeecostpaid(parseFloat(customsfeecostpaid).toFixed(2));
    setmodaldemurragecostpaid(parseFloat(demurragecostpaid).toFixed(2));
    setmodalothercostpaid(parseFloat(othercostpaid).toFixed(2));
  };
  const handleSave3 = () => {
    setShow3(false);
    setimporttaxescostpaid(parseFloat(modalimporttaxescostpaid).toFixed(2));
    setinspectioncostpaid(parseFloat(modalinspectioncostpaid).toFixed(2));
    setreworkingrestackingcostpaid(parseFloat(modalreworkingrestackingcostpaid).toFixed(2));
    setdestructiondisposalcostpaid(parseFloat(modaldestructiondisposalcostpaid).toFixed(2));
    setrelabellingcostpaid(parseFloat(modalrelabellingcostpaid).toFixed(2));
    settransportationcostpaid(parseFloat(modaltransportationcostpaid).toFixed(2));
    setproductdiscountscostpaid(parseFloat(modalproductdiscountscostpaid).toFixed(2));
    setoceanfreightcostpaid(parseFloat(modaloceanfreightcostpaid).toFixed(2));
    setcustomsfeecostpaid(parseFloat(modalcustomsfeecostpaid).toFixed(2));
    setdemurragecostpaid(parseFloat(modaldemurragecostpaid).toFixed(2));
    setothercostpaid(parseFloat(modalothercostpaid).toFixed(2));
    setsumcostpaid(
      (
        parseFloat(modalimporttaxescostpaid) +
        parseFloat(modalinspectioncostpaid) +
        parseFloat(modalreworkingrestackingcostpaid) +
        parseFloat(modaldestructiondisposalcostpaid) +
        parseFloat(modalrelabellingcostpaid) +
        parseFloat(modaltransportationcostpaid) +
        parseFloat(modalproductdiscountscostpaid) +
        parseFloat(modaloceanfreightcostpaid) +
        parseFloat(modalcustomsfeecostpaid) +
        parseFloat(modaldemurragecostpaid) +
        parseFloat(modalothercostpaid)
      ).toFixed(2),
    );
    // console.log(sumcostpaid);
  };


  const handleShow4 = () => setShow4(true);
  const handleClose4 = () => {
    setShow4(false);
    setmodalimporttaxesplantaccepted(parseFloat(importtaxesplantaccepted).toFixed(2));
    setmodalinspectionplantaccepted(parseFloat(inspectionplantaccepted).toFixed(2));
    setmodalreworkingrestackingplantaccepted(
      parseFloat(reworkingrestackingplantaccepted).toFixed(2),
    );
    setmodaldestructiondisposalplantaccepted(
      parseFloat(destructiondisposalplantaccepted).toFixed(2),
    );
    setmodalrelabellingplantaccepted(parseFloat(relabellingplantaccepted).toFixed(2));
    setmodaltransportationplantaccepted(parseFloat(transportationplantaccepted).toFixed(2));
    setmodalproductdiscountsplantaccepted(parseFloat(productdiscountsplantaccepted).toFixed(2));
    setmodaloceanfreightplantaccepted(parseFloat(oceanfreightplantaccepted).toFixed(2));
    setmodalcustomsfeeplantaccepted(parseFloat(customsfeeplantaccepted).toFixed(2));
    setmodaldemurrageplantaccepted(parseFloat(demurrageplantaccepted).toFixed(2));
    setmodalotherplantaccepted(parseFloat(otherplantaccepted).toFixed(2));
  };
  const handleSave4 = () => {
    setShow4(false);
    setimporttaxesplantaccepted(parseFloat(modalimporttaxesplantaccepted).toFixed(2));
    setinspectionplantaccepted(parseFloat(modalinspectionplantaccepted).toFixed(2));
    setreworkingrestackingplantaccepted(
      parseFloat(modalreworkingrestackingplantaccepted).toFixed(2),
    );
    setdestructiondisposalplantaccepted(
      parseFloat(modaldestructiondisposalplantaccepted).toFixed(2),
    );
    setrelabellingplantaccepted(parseFloat(modalrelabellingplantaccepted).toFixed(2));
    settransportationplantaccepted(parseFloat(modaltransportationplantaccepted).toFixed(2));
    setproductdiscountsplantaccepted(parseFloat(modalproductdiscountsplantaccepted).toFixed(2));
    setoceanfreightplantaccepted(parseFloat(modaloceanfreightplantaccepted).toFixed(2));
    setcustomsfeeplantaccepted(parseFloat(modalcustomsfeeplantaccepted).toFixed(2));
    setdemurrageplantaccepted(parseFloat(modaldemurrageplantaccepted).toFixed(2));
    setotherplantaccepted(parseFloat(modalotherplantaccepted).toFixed(2));
    setsumplantaccepted(
      (
        parseFloat(modalimporttaxesplantaccepted) +
        parseFloat(modalinspectionplantaccepted) +
        parseFloat(modalreworkingrestackingplantaccepted) +
        parseFloat(modaldestructiondisposalplantaccepted) +
        parseFloat(modalrelabellingplantaccepted) +
        parseFloat(modaltransportationplantaccepted) +
        parseFloat(modalproductdiscountsplantaccepted) +
        parseFloat(modaloceanfreightplantaccepted) +
        parseFloat(modalcustomsfeeplantaccepted) +
        parseFloat(modaldemurrageplantaccepted) +
        parseFloat(modalotherplantaccepted)
      ).toFixed(2),
    );
    // console.log(sumplantaccepted);
  };

  const onepager = () => {
    AxiosApi({
      url: process.env.REACT_APP_API_ROOT_URL_PREFIX + '/onepager/ppt',
      method: 'POST',
      responseType: 'arraybuffer', // Important
      data: {
        ackno: acknowledgementNumber,
        compno123: complaintNumber,
        custname: custname,
        compdesc: complaintDesc,
        uploadeddate: uploaded,
        shiptocust: shiptocust,
        comptype: complaintType,
        shiptosalesorg: shiptosalesorg,
        code: code,
        destination: destination,
        importzone: importzone,
        billtosalesorg: billtosalesorg,
        incoterm1: incoterm1,
        incoterm2: incoterm2,
        exportzone: exportzone,
        defectType: defectType,
        headercomplainttype: headercomplainttype,
        type: type,
        subtype: subtype,
        comment: comment,
        resolution: resolution,
        managermessage: managermessage,
        loadingpt1: loadingpt1,
        loadingpt2: loadingpt2,
        costClaimed: costClaimed,
        currencyClaimed: currencyClaimed,
        sumextraclaims: sumextraclaims,
        totalextraclaims: totalextraclaims,
        sumcostpaid: sumcostpaid,
        totalcostpaid: totalcostpaid,
        sumplantaccepted: sumplantaccepted,
        totalplantaccepted: totalplantaccepted,
        productcostpaid: sum2,
        plantcostpaid: sum3,
        total_hl: totalhl,
        followup: order4,
        orders: order3,
        contaffect: contaffected,
        plantcostcurr: plantcostpaidcurr,
        importercostcurr: importercostpaidcurr,
      },
    }).then((response) => {
      // console.log(response);
      // const type = response?.headers['content-type']
      // console.log(type)
      // const blob = new Blob([response?.data], { type: type, encoding: 'UTF-8' })
      // console.log(blob)
      // const link = document.createElement('a')
      // link.href = window.URL.createObjectURL(blob)
      // link.download = 'Testfile.pptx'
      // link.click()
      console.log(response)
      FileDownload(response?.data, 'pptres.pptx');
    });
  };

  const savemanagermessage = () => {
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/save/api/savemessagemanager', {
      ackno: acknowledgementNumber,
      managermessage: managermessage,
      username: localStorage.getItem('username'),
    }).then((response) => {
      if (response?.data == 'message update success') {
        window.location.reload();
      }
    });
  };
  //anas tbale1
  function RenderExpenseTable(props) {
    const { item, id } = props;
    let tableData2 = order3;
    useEffect(() => {
      if (!order3.length) {
        setorder3([...props.orders3({ action: 'order3' })]);
      }
    }, []);

    const columns2 = [
      {
        dataField: 'id',
        text: 'Id',
        hidden: true,
      },
      {
        dataField: 'salesOrder',
        text: 'Sales Order',
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            console.log(row);
            setmodalInfo(row);
            setShow12(true);
          },
        },
        headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '2px' }}>
              <a
                data-tip
                data-for="salesorder"
                style={{ textDecoration: 'underline', color: '#0d6efd', fontWeight: '700' }}
              >
                {' '}
                salesorder{' '}
              </a>
              <Tooltip place="top" id="salesorder" aria-haspopup="true" effect="solid">
                <p style={{ textAlign: 'left' }}>Example of Sales Order : 14092454</p>
                <p style={{ textAlign: 'left' }}>Note:</p>
                <ol style={{ textAlign: 'left' }}>
                  <li>Always starting with 1... And has 8 digits</li>
                  <li>Sales Order can be found in the Buyco report</li>
                </ol>
              </Tooltip>
            </div>
          );
        },
      },
      {
        dataField: 'Container',
        text: 'Container',
        headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '2px' }}>
              <a
                data-tip
                data-for="container"
                style={{ textDecoration: 'underline', color: '#0d6efd', fontWeight: '700' }}
              >
                {' '}
                CONTAINER{' '}
              </a>
              <Tooltip place="top" id="container" aria-haspopup="true" effect="solid">
                <p style={{ textAlign: 'left' }}>Example of Container Number : MSKU5456215</p>
                <p style={{ textAlign: 'left' }}>Note:</p>
                <ol style={{ textAlign: 'left' }}>
                  <li>Please type without any spaces, or symbols</li>
                  <li>
                    In case its a truck please provide the precise number, it will depend on
                    delivery company
                  </li>
                </ol>
              </Tooltip>
            </div>
          );
        },
      },
      {
        dataField: 'PO',
        text: 'PO Number',
        headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '2px' }}>
              <a
                data-tip
                data-for="ponumber"
                style={{ textDecoration: 'underline', color: '#0d6efd', fontWeight: '700' }}
              >
                {' '}
                PO NUMBER{' '}
              </a>
              <Tooltip id="ponumber" aria-haspopup="true" effect="solid">
                <p style={{ textAlign: 'left' }}>Please provide Customer Purchase order3</p>
                <p style={{ textAlign: 'left' }}>Note:</p>
                <ol style={{ textAlign: 'left' }}>
                  <li>Provide the same Purchase order3 that you used on the order3 request</li>
                  <li>You can always find it in the order3 acknowledgement</li>
                </ol>
              </Tooltip>
            </div>
          );
        },
      },
      {
        dataField: 'qty',
        text: 'Quantity',
        headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '2px' }}>
              <a
                data-tip
                data-for="qty"
                style={{ textDecoration: 'underline', color: '#0d6efd', fontWeight: '700' }}
              >
                {' '}
                QTY{' '}
              </a>
              <Tooltip id="qty" aria-haspopup="true" effect="solid">
                <p style={{ textAlign: 'left' }}>
                  Please provide here the quantity of your SKU- Enter ONLY NUMBERS (number Cases or
                  number of Kegs affected).
                </p>
                <p style={{ textAlign: 'left' }}>Example : 1 = Keg or case</p>
                <p style={{ textAlign: 'left' }}>
                  Note: Enter number of cases or number of kegs- not number of bottles/cans.
                </p>
              </Tooltip>
            </div>
          );
        },
      },
      {
        dataField: 'SKU',
        text: 'SKU',
        headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '2px' }}>
              <a
                style={{ textDecoration: 'underline', color: '#0d6efd', fontWeight: '700' }}
                data-tip="Please Provide the ABI reference visible in the order3 portal"
              >
                {' '}
                SKU{' '}
              </a>
              <Tooltip place="top" type="dark" effect="solid" />
            </div>
          );
        },
      },
      {
        dataField: 'batchCode',
        text: 'Batch Code',
        headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '2px' }}>
              <a
                data-tip
                data-for="batchcode"
                style={{ textDecoration: 'underline', color: '#0d6efd', fontWeight: '700' }}
              >
                {' '}
                BATCH CODE{' '}
              </a>
              <Tooltip id="batchcode" aria-haspopup="true" effect="solid">
                <p style={{ textAlign: 'left' }}>Only required if you have a Quality Issue.</p>
                <p style={{ textAlign: 'left' }}>Note: (refer to keg, cap, bottom or label)</p>
              </Tooltip>
            </div>
          );
        },
      },

      {
        dataField: 'Name 1',
        text: 'Plant Name',
        editable: false,
      },
      {
        dataField: 'Material Description',
        text: 'Material Description',
        editable: false,
      },
      {
        dataField: 'plantCost',
        text: 'Plant Cost',
      },
      {
        dataField: 'ThirdPartyCost',
        text: 'Importer Cost',
      },
      // adding shipping no
      {
        dataField: 'Shipment Number1',
        text: 'Shipment Number',
        headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '2px' }}>
              <a
                style={{ textDecoration: 'underline', color: '#0d6efd', fontWeight: '700' }}
                data-tip="Shipment Number"
              >
                {' '}
                Shipment Number{' '}
              </a>
              <Tooltip place="top" type="dark" effect="solid" />
            </div>
          );
        },
      },
      {
        dataField: 'dateOfGoodsArrival',
        text: 'Date of Arrival',
        headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '2px' }}>
              <a
                data-tip
                data-for="dateofarrival"
                style={{ textDecoration: 'underline', color: '#0d6efd', fontWeight: '700' }}
              >
                {' '}
                Date Of Arrival{' '}
              </a>
              <Tooltip id="dateofarrival" aria-haspopup="true" effect="solid">
                <p style={{ textAlign: 'left' }}>Arrival date to Warehouse</p>
                <p style={{ textAlign: 'left' }}>
                  Note: Demurrage and Detention in POD: 20 business days from ATA.
                </p>
              </Tooltip>
            </div>
          );
        },
        formatter: (cell) => {
          let dateObj = cell;
          if (typeof cell !== 'object') {
            dateObj = new Date(cell);
          }
          return `${('0' + dateObj.getUTCDate()).slice(-2)}/${(
            '0' +
            (dateObj.getUTCMonth() + 1)
          ).slice(-2)}/${dateObj.getUTCFullYear()}`;
        },
        editor: {
          type: Type.DATE,
        },
      },
      {
        dataField: 'isAccepted',
        editor: {
          type: Type.CHECKBOX,
          value: 'Y:N',
        },
        headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
          const checkall = () => {
            setselected(!selected);
            if (refselected.current == true) {
              setsum(parseFloat(0).toFixed(2));
              setorders3sum(parseFloat(0).toFixed(2));
              console.log(order3.length);
              for (let i = 0; i < order3.length; i++) {
                order3[i].isAccepted = 'Y';
                new Promise(function (resolve, reject) {
                  setsum(
                    (parseFloat(refsum.current) + parseFloat(order3[i].ImporterCost)).toFixed(2),
                  );
                  // setorders3sum((parseFloat(reforders3sum.current) + parseFloat(order3[i].PlantCost)).toFixed(2));
                  // settotalcostpaid((parseFloat(refsumcostpaid.current) + parseFloat(order3[i].ImporterCost)).toFixed(2));
                  // settotalplantaccepted((parseFloat(sumplantaccepted) + parseFloat(reforders3sum.current)).toFixed(2));

                  setTimeout(() => resolve(1), 2000); // (*)
                }).then(function (result) {
                  return new Promise((resolve, reject) => {
                    setTimeout(() => resolve(result * 2), 2000);
                    console.log(refsum.current);
                    console.log(reforders3sum.current);
                  });
                });
              }
            } else {
              for (let i = 0; i < order3.length; i++) {
                order3[i].isAccepted = 'N';
                setsum(parseFloat(0).toFixed(2));
                setorders3sum(parseFloat(0).toFixed(2));
              }
            }
          };
          return (
            <div style={{ textAlign: 'center' }}>
              <input checked={selected} onChange={checkall} type="checkbox" />
            </div>
          );
        },
      },
      {
        dataField: 'order3basePkey',
        text: '',
        editable: false,
        formatter: (cell, row) => {
          if (row)
            return (
              <Button
                className="btn-md btn-danger btn-xs border3-secondary rounded"
                onClick={() => {
                  console.log('hi');
                  setdeletethissalesorder(row);
                  console.log(deletethissalesorder);
                  console.log(row.id, 'sales order to be deleted');
                  //   setorder3(order3);
                  //   orders3({
                  //       actionType: "deleteRow",
                  //       row: row.id,
                  //       salesorder: row.salesorder,
                  //       container: row.container,
                  //       POnumber: row.POnumber,
                  //       Qty: row.Qty,
                  //       SKU: row.SKU,
                  //       BatchCode: row.BatchCode,
                  //       Dateofarrival: row.Dateofarrival,
                  //       isAccepted: row.isAccepted
                  //     });

                  setsalesorderdeletemodal(true);
                }}
              >
                Delete
              </Button>
            );
          return null;
        },
      },
      {
        dataField: 'Shipment Number1',
        text: 'Shipment Number1',
        hidden: true,
      },
      {
        dataField: 'Order ID',
        text: 'Shipment Number1',
        hidden: true,
      },
      {
        dataField: 'Production Plant ID',
        text: 'Production Plant ID',
        hidden: true,
      },
      {
        dataField: 'Pack Size (L)',
        text: 'Pack Size (L)',
        hidden: true,
      },
      {
        dataField: 'Brand',
        text: 'Brand',
        hidden: true,
      },
      {
        dataField: 'Production Plant ID',
        text: 'Production Plant ID',
        hidden: true,
      },
      {
        dataField: 'Delivery Plant ID',
        text: 'Delivery Plant ID',
        hidden: true,
      },
      {
        dataField: 'FinalPlantId',
        text: 'FinalPlantId',
        hidden: true,
      },
      ,
      {
        dataField: 'loadingPoint',
        text: 'loadingPoint',
        hidden: true,
      },
      {
        dataField: 'Container Material',
        text: 'Container Material',
        hidden: true,
      },
      {
        dataField: 'HL',
        text: 'HL',
        hidden: true,
      },
      {
        dataField: 'exportSku',
        text: 'exportSku',
        hidden: true,
      },
    ];

    const rowEvents = {
      onClick: (e, row) => {
        console.log(row);
        setmodalInfo(row);
        setShow12(true);
      },
    };

    // if(loaded == false){
    //     return(
    //     <ReactBootstrap.Spinner animation="border" />
    //     );
    // }
    // if(loaded == true) {
    return (
      <div xs={12} className="col form">
        <Modal show={show12} onHide={handleClose12} backdrop="static" keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>SalesOrder Details - {modalInfo['salesOrder']}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label className="form-label">Shipment Number</label>
                    <div className="form-control-plaintext" id="so-shipment-num">
                      {modalInfo['Shipment Number1']}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className="form-label">Container</label>
                    <div className="form-control-plaintext" id="so-container">
                      {modalInfo['Container']}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label className="form-label">PO</label>
                    <div className="form-control-plaintext" id="so-po">
                      {modalInfo['PO']}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className="form-label">Quantity</label>
                    <div className="form-control-plaintext" id="so-qty">
                      {modalInfo['Quantity']}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label className="form-label">SKU</label>
                    <div className="form-control-plaintext" id="so-sku">
                      {modalInfo['SKU']}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className="form-label">Export SKU</label>
                    <div className="form-control-plaintext" id="so-export-sku">
                      {modalInfo['exportSku']}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label className="form-label">Production Plant Code</label>
                    <div className="form-control-plaintext" id="so-prod-plant-code">
                      {modalInfo['Production Plant ID']} - {modalInfo['plantname']}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className="form-label">Loading Point</label>
                    <div className="form-control-plaintext" id="so-loading-point">
                      {modalInfo['loadingPoint']}({modalInfo['FinalPlantId']}) -{' '}
                      {modalInfo['plantname']}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label className="form-label">Pack Size</label>
                    <div className="form-control-plaintext" id="so-pack-size">
                      {modalInfo['Pack Size (L)']}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className="form-label">Type of Package Material</label>
                    <div className="form-control-plaintext" id="so-type-pack-mat">
                      {modalInfo['Container Material']}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label className="form-label">Brand</label>
                    <div className="form-control-plaintext" id="so-brand">
                      {modalInfo['Brand']}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className="form-label">Brand Description</label>
                    <div className="form-control-plaintext" id="so-brand-desc">
                      {modalInfo['Material Description']}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label className="form-label">HL</label>
                    <div className="form-control-plaintext" id="so-hl">
                      {modalInfo['HL']}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className="form-label">Type of Package</label>
                    <div className="form-control-plaintext" id="so-type-pack">
                      {modalInfo['Container Type']}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose12}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {loaded === true && order3.length > 0 ? (
          <ToolkitProvider keyField="id" order3={tableData2} columns={columns2} exportCSV>
            {(props) => (
              <div>
                <div className="d-flex text-right float-right">
                  {/* <ExportCSVButton justify-content-around p-2
                  className="export-button border3-secondary rounded"
                  {...props.csvProps}
                >
                  <span>Export to CSV</span>
                </ExportCSVButton>
                */}
                  <Button
                    className="btn bg-success text-light rounded"
                    disabled={
                      localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                      localStorage.getItem('role') == 'Manager' ||
                      submitdisabled == true
                    }
                    style={{ alignItems: 'right', marginBottom: '20px', marginTop: '10px' }}
                    onClick={() => {
                      if (!tableData2) {
                        setorder3(order3);
                      }
                      setorder3(tableData2);
                      // orders3({ actionType: "addRow" });
                      setShow11(true);
                      if (fcount > 0 && isvalid == true) {
                        setisdisabled(false);
                      } else {
                        setisdisabled(true);
                      }
                    }}
                  >
                    ✚
                  </Button>
                </div>
                <BootstrapTable
                  {...props.baseProps}
                  keyField="id"
                  data={tableData2}
                  columns={columns2}
                  cellEdit={cellEditFactory({
                    mode: 'click',
                    blurToSave: true,
                    onStartEdit: (row, column, rowIndex, columnIndex) => { },
                    beforeSaveCell: (oldValue, newValue, row, column) => {
                      if (column.dataField === 'container') {
                        if (isNaN(Number(newValue))) {
                          alert('You entered ' + newValue + ' Please Enter numbers Only!!');
                        }
                      }
                    },
                    onStartEdit: (row, column, rowIndex, columnIndex) => {
                      console.log('start to edit!!!');
                    },
                    beforeSaveCell: (oldValue, newValue, row, column) => {
                      console.log(newValue);
                    },
                    afterSaveCell: (oldValue, newValue, row, column) => {
                      setsum(parseFloat(0).toFixed(2));
                      setorders3sum(parseFloat(0).toFixed(2));
                      for (let i = 0; i < order3.length; i++) {
                        if (order3[i].isAccepted == 'Y') {
                          new Promise(function (resolve, reject) {
                            setsum(
                              (
                                parseFloat(refsum.current) + parseFloat(order3[i].ImporterCost)
                              ).toFixed(2),
                            );
                            setorders3sum(
                              (
                                parseFloat(reforders3sum.current) + parseFloat(order3[i].PlantCost)
                              ).toFixed(2),
                            );
                            //   settotalplantaccepted((parseFloat(sumplantaccepted) + parseFloat(reforders3sum.current)).toFixed(2));
                            //   settotalcostpaid((parseFloat(refsumcostpaid.current) + parseFloat(order3[i].ImporterCost)).toFixed(2));
                            setTimeout(() => resolve(1), 2000); // (*)
                          }).then(function (result) {
                            return new Promise((resolve, reject) => {
                              setTimeout(() => resolve(result * 2), 2000);
                              console.log(refsum.current);
                              console.log(reforders3sum.current);
                            });
                          });
                        }
                      }
                    },
                  })}
                ></BootstrapTable>
              </div>
            )}
          </ToolkitProvider>
        ) : <p>unable to load data...</p>}
      </div>
    );
  }

  async function savecomplaint123() {
    const data = {
      ack: acknowledgementNumber,

      invalidmess: invalidmess,

      complaintNumber: complaintNumber,
    };

    if (type == 'Invalid') {
      await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/invalidmess/invalidreason', data)
        .then((res) => {
          // console.log('invalidreason', res);
        })
        .catch((error) => {
          // console.log('erro from invalidreason');
          //console.log('error');
        });

      await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/invalidmess', data)
        .then((res) => {
          // console.log('invalidmess', res);
          setinvalidmess('');
        })
        .catch((error) => {
          // console.log('erro from invalidmess');
        });

      await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/followup/customerfollowups', { ackno: { ackno } })
        .then(function (response) {
          setcustomerfollowup(response?.data);
          // console.log(response?.data);
        })
        .catch(function (error) {
          // handle error
          // console.log(error);
        });

      // console.log('kushal', invalidmess);
    } else {
      alert('hii');
    }
  }
  // this  is changed
  const savecomplaint = async () => {
    setsubmitdisabled(true);
    const data = {
      ack: acknowledgementNumber,

      invalidmess: invalidmess,

      complaintNumber: complaintNumber,
    };

    if (type == 'Invalid') {
      await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/invalidmess/invalidreason', data)
        .then((res) => {
          // console.log('invalidreason', res);
        })
        .catch((error) => {
          // console.log('erro from invalidreason');
        });

      await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/invalidmess', data)
        .then((res) => {
          // console.log('invalidmess', res);
        })
        .catch((error) => {
          // console.log('erro from invalidmess');
        });

      await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/followup/customerfollowups', { ackno: { ackno } })
        .then(function (response) {
          setcustomerfollowup(response?.data);
          // console.log(response?.data);
        })
        .catch(function (error) {
          // handle error
          // console.log(error);
        });

      // console.log('line 2000', invalidmess);
    }

    //

    // console.log('This is save complaint');
    // console.log(reasonofrejection123);
    // console.log(acceptedByAm);
    // console.log(acceptedByVlc);
    // console.log(acceptedByPlantLogi);
    if (
      uploaded == undefined ||
      uploaded == 'null' ||
      uploaded == 'undefined' ||
      uploaded == 'Invalid Date' ||
      uploaded == 'Invalid date' ||
      uploaded == 'Invalid date'
    ) {
      setupload('');
    } else {
      let newupload = moment(uploaded).format('DD-MM-YYYY');
      // console.log(newupload);
      setupload(newupload);
    }
    if (
      customerContacted == undefined ||
      customerContacted == 'null' ||
      customerContacted == 'undefined' ||
      customerContacted == 'Invalid Date' ||
      customerContacted == 'Invalid date' ||
      customerContacted == 'Invalid date'
    ) {
      setcusdate('');
    } else {
      let newcusdate = moment(customerContacted).format('DD-MM-YYYY');
      // console.log(newcusdate);
      setcusdate(newcusdate);
    }
    if (
      ownerContacted == undefined ||
      ownerContacted == 'null' ||
      ownerContacted == 'undefined' ||
      ownerContacted == 'Invalid Date' ||
      ownerContacted == 'Invalid date' ||
      ownerContacted == 'Invalid date'
    ) {
      setownerdate('');
    } else {
      let newownerdate = moment(ownerContacted).format('DD-MM-YYYY');
      setownerdate(newownerdate);
    }
    if (
      registrationDate == undefined ||
      registrationDate == 'null' ||
      registrationDate == 'undefined' ||
      registrationDate == 'Invalid Date' ||
      registrationDate == 'Invalid date'
    ) {
      setregdate('');
    } else {
      let newregdate = moment(registrationDate).format('DD-MM-YYYY');
      setregdate(newregdate);
    }
    if (
      closedDate == undefined ||
      closedDate == 'null' ||
      closedDate == 'undefined' ||
      closedDate == 'Invalid Date' ||
      closedDate == 'Invalid date'
    ) {
      setclosedate('');
    } else {
      let newclosedate = moment(closedDate).format('DD-MM-YYYY');
      setclosedate(newclosedate);
    }
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/save/api/savecomplaint', {
      ackno: acknowledgementNumber,
      compno: complaintNumber,
      complaintDesc: complaintDesc,
      extra_dueDate: extra_dueDate,
      uploaded: refuploaded?.current,
      registeredBy: registeredBy,
      cqAgent: cqAgent,
      defectType: defectType,
      headercomplainttype: headercomplainttype,
      customerContacted: refcustomerContacted?.current,
      ownerContacted: refownerContacted?.current,
      closedDate: refclosedDate?.current,
      comment: comment,
      note: note,
      cpaid: sumcostpaid,
      status: status,
      diffquantityclaimed: diffquantityclaimed,
      accepted: accepted,
      acceptedBy: acceptedBy,
      registrationDate: refregistrationDate?.current,
      type: type,
      subtype: subtype,
      username: localStorage.getItem('username'),
      costClaimed: costClaimed,
      currencyImporter: importercostpaidcurr,
      currencyClaimed: currencyClaimed,
      currencyPlant: plantcostpaidcurr,
      extraFeesPlant: extraFeesPlant,
      resolution: resolution,
      managermessage: managermessage,
      rulesOfEngagement: rulesOfEngagement,
      acceptedByAm: acceptedByAm,
      checkedByAm: checkedByAm,
      acceptedByVlc: acceptedByVlc,
      acceptedByPlantLogi: acceptedByPlantLogi,
      costAcceptedByAm: costAcceptedByAm,
      costAcceptedByVlc: costAcceptedByVlc,
      costAcceptedByPlantLogi: costAcceptedByPlantLogi,
      currAccAm: currAccAm,
      currAccVlc: currAccVlc,
      currAccPl: currAccPl,
      currExtraAm: currExtraAm,
      currExtraVlc: currExtraVlc,
      currExtraPl: currExtraPl,
      feesAcceptedByAm: feesAcceptedByAm,
      feesAcceptedByVlc: feesAcceptedByVlc,
      feesAcceptedbyPl: feesAcceptedbyPl,
      reasonRejection: reasonofrejection123,
      reasonPartially: partiallyreason,
      email123: email,
      companyname: companyName,
      prodcostpaid: sum,
      importtaxescostpaid: importtaxescostpaid,
      inspectioncostpaid: inspectioncostpaid,
      reworkingrestackingcostpaid: reworkingrestackingcostpaid,
      destructiondisposalcostpaid: destructiondisposalcostpaid,
      relabellingcostpaid: relabellingcostpaid,
      transportationcostpaid: transportationcostpaid,
      productdiscountscostpaid: productdiscountscostpaid,
      oceanfreightcostpaid: oceanfreightcostpaid,
      demurragecostpaid: demurragecostpaid,
      customsfeecostpaid: customsfeecostpaid,
      othercostpaid: othercostpaid,
      extrafeespaid: sumcostpaid,
      extrafeescurrency: importercostpaidcurr,
      extrafeesclaimed: sumextraclaims,
      extradocrequestedornot: extradocrequested,
    })
      .then((response) => {
        // console.log('hi');
        // console.log(response?.data);
        AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/save/api/saveextrafeesclaim', {
          ackno: acknowledgementNumber,
          importtaxesextraclaims: importtaxesextraclaims,
          importtaxesextraclaimscurrency: importtaxesextraclaimscurrency,
          inspectionextraclaims: inspectionextraclaims,
          inspectionextraclaimscurrency: inspectionextraclaimscurrency,
          reworkingrestackingextraclaims: reworkingrestackingextraclaims,
          reworkingrestackingextraclaimscurrency: reworkingrestackingextraclaimscurrency,
          destructiondisposalextraclaims: destructiondisposalextraclaims,
          destructiondisposalextraclaimscurrency: destructiondisposalextraclaimscurrency,
          relabellingextraclaims: relabellingextraclaims,
          relabellingextraclaimscurrency: relabellingextraclaimscurrency,
          transportationextraclaims: transportationextraclaims,
          transportationextraclaimscurrency: transportationextraclaimscurrency,
          productdiscountsextraclaims: productdiscountsextraclaims,
          productdiscountsextraclaimscurrency: productdiscountsextraclaimscurrency,
          oceanfreightextraclaims: oceanfreightextraclaims,
          oceanfreightextraclaimscurrency: oceanfreightextraclaimscurrency,
          demurrageextraclaims: demurrageextraclaims,
          demurrageextraclaimscurrency: demurrageextraclaimscurrency,
          customsfeeextraclaims: customsfeeextraclaims,
          customsfeeextraclaimscurrency: customsfeeextraclaimscurrency,
          otherextraclaims: otherextraclaims,
          otherextraclaimscurrency: otherextraclaimscurrency,
        }).then((response) => {
          // console.log('hi');
          // console.log(response?.data);
          /*if(response?.data == 'Complaint has been saved successfully'){
                            window.location.reload(); 
                        }
                        else{
                            alert("The complaint is not saved properly")
                        }*/
        });
      })
      .then((response) => {
        // console.log('Extar fees paid');
        AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/save/api/saveextrafeespaid', {
          ackno: acknowledgementNumber,
          importtaxescostpaid: importtaxescostpaid,
          inspectioncostpaid: inspectioncostpaid,
          reworkingrestackingcostpaid: reworkingrestackingcostpaid,
          destructiondisposalcostpaid: destructiondisposalcostpaid,
          relabellingcostpaid: relabellingcostpaid,
          transportationcostpaid: transportationcostpaid,
          productdiscountscostpaid: productdiscountscostpaid,
          oceanfreightcostpaid: oceanfreightcostpaid,
          demurragecostpaid: demurragecostpaid,
          customsfeecostpaid: customsfeecostpaid,
          othercostpaid: othercostpaid,
        }).then((response) => {
          // console.log('hi');
          // console.log(response?.data);
          /*if(response?.data == 'Complaint has been saved successfully'){
                            window.location.reload(); 
                        }
                        else{
                            alert("The complaint is not saved properly")
                        }*/
        });
      })
      .then((response) => {
        // console.log('Plant fees paid');
        AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/save/api/saveplantaccepted', {
          ackno: acknowledgementNumber,
          importtaxesplantaccepted: importtaxesplantaccepted,
          inspectionplantaccepted: inspectionplantaccepted,
          reworkingrestackingplantaccepted: reworkingrestackingplantaccepted,
          destructiondisposalplantaccepted: destructiondisposalplantaccepted,
          relabellingplantaccepted: relabellingplantaccepted,
          transportationplantaccepted: transportationplantaccepted,
          productdiscountsplantaccepted: productdiscountsplantaccepted,
          oceanfreightplantaccepted: oceanfreightplantaccepted,
          demurrageplantaccepted: demurrageplantaccepted,
          customsfeeplantaccepted: customsfeeplantaccepted,
          otherplantaccepted: otherplantaccepted,
        })
          .then((response) => {
            // console.log('savecndn');
            if (
              costRequested == undefined ||
              costRequested == 'null' ||
              costRequested == 'undefined' ||
              costRequested == 'Invalid Date' ||
              costRequested == 'Invalid date'
            ) {
              setcostRequested('');
            } else {
              let newcostreqdate = moment(costRequested).format('DD-MM-YYYY');
              setcostRequested(newcostreqdate);
            }
            if (
              costbooked == undefined ||
              costbooked == 'null' ||
              costbooked == 'undefined' ||
              costbooked == 'Invalid Date' ||
              costbooked == 'Invalid date'
            ) {
              setcostbooked('');
            } else {
              let newcostbooked = moment(costbooked).format('DD-MM-YYYY');
              setcostbooked(newcostbooked);
            }
            if (
              feesBooked == undefined ||
              feesBooked == 'null' ||
              feesBooked == 'undefined' ||
              feesBooked == 'Invalid Date' ||
              feesBooked == 'Invalid date'
            ) {
              setfeesBooked('');
            } else {
              let newfeesbooked = moment(feesBooked).format('DD-MM-YYYY');
              setfeesBooked(newfeesbooked);
            }
            if (
              feesRequested == undefined ||
              feesRequested == 'null' ||
              feesRequested == 'undefined' ||
              feesRequested == 'Invalid Date' ||
              feesRequested == 'Invalid date'
            ) {
              setfeesRequested('');
            } else {
              let newfeesrequested = moment(feesRequested).format('DD-MM-YYYY');
              setfeesRequested(newfeesrequested);
            }
            if (
              dnrequested == undefined ||
              dnrequested == 'null' ||
              dnrequested == 'undefined' ||
              dnrequested == 'Invalid Date' ||
              dnrequested == 'Invalid date'
            ) {
              setdnrequested('');
            } else {
              let newdnrequested = moment(dnrequested).format('DD-MM-YYYY');
              setdnrequested(newdnrequested);
            }
            if (
              dnbooked == undefined ||
              dnbooked == 'null' ||
              dnbooked == 'undefined' ||
              dnbooked == 'Invalid Date' ||
              dnbooked == 'Invalid date'
            ) {
              setdnbooked('');
            } else {
              let newdnbooked = moment(dnbooked).format('DD-MM-YYYY');
              setdnbooked(newdnbooked);
            }
            AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/save/api/savecndn', {
              ackno: { ackno },
              DNpending: dnpending,
              DNbooked: dnbooked,
              DNcostcenter: dncostcenter,
              DNFinalInvoice: dnFinalInvoice,
              DNgl: dngl,
              DNrequested: dnrequested,
              costBooked: costbooked,
              costCMAmount: costCMAmount,
              costCMAmountCurrency: costCMAmountCurrency,
              feesCMAmountCurrency: feesCMAmountCurrency,
              costCMNumber: costCMNumber,
              costRequested: costRequested,
              cmbooked: cmbooked,
              feesCMAmount: feesCMAmount,
              feesCMNumber: feesCMNumber,
              feesRequested: feesRequested,
              profitCenter: profitCenter,
              feesBooked: feesBooked,
            });
          })
          .then((response) => {
            // console.log('savebsc');
            AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/save/api/savebsc', {
              ackno: { ackno },
              bscdefect: bscdefect,
              bscnote: bscnote,
              preventive: preventive,
              process: processValue,
              root: root,
              teamlead: Teamlead,
            }).then((response) => {
              // console.log('Plant accepted');
              // console.log(response?.data);
              if (response?.data == 'Complaint has been saved successfully') {
                window.location.reload();
              } else {
                alert('The complaint is not saved properly');
              }
            });
          });
      });
  };



  /*const checktoggle1 = (e) =>{
    setaccAm(e.target.value);
    // console.log(e.target.value);
    if (e.target.value == true) {
        setacceptedByAm('Y');
    }
    else if (e.target.value == false) {
        setacceptedByAm('N');
        // console.log('N');
    }
    }
    const checktoggle2 = (e) =>{
    setaccVlc(e.target.value);
    // console.log(e.target.value);
    if (e.target.value == true) {
        setacceptedByVlc('Y');
        // console.log('Y');
    }
    else if (e.target.value == false) {
        setacceptedByVlc('N');
        // console.log('N');
    }
    }
    const checktoggle3 = (e) =>{
    setaccPli(e.target.value);
    // console.log(e.target.value);
    if (e.target.value == true) {
        setacceptedByPlantLogi('Y');
        // console.log('Y');
    }
    else if (e.target.value == false) {
        setacceptedByPlantLogi('N');
        // console.log('N');
    }
    }
    */



  let size = 0;


  const handleShow7 = () => setShow7(true);
  const handlesendcustomerfollowup = () => {
    // console.log(localStorage.getItem('role'));
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/followup/cqcustomerfollowup', {
      username: localStorage.getItem('username'),
      customermail: email,
      action: cqaction1,
      ackno: ackno,
      compno2: complaintNumber,
      comment: comment1,
      messagepostedby: localStorage.getItem('role'),
    })
      .then(function (response) {
        setShow7(false);
        // console.log(response?.data);
        window.location.reload();
        // setreloadstate(reloadstate + 1);
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
      });
    setShow7(false);
  };
  const handleClose7 = () => {
    setShow7(false);
  };


  const handlesendinternalfollowup = () => {
    // console.log(localStorage.getItem('role'));
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/followup/cqinternalfollowup', {
      username: localStorage.getItem('username'),
      ackno123: ackno,
      compno: complaintNumber,
      followup: order4,
      messagepostedby: localStorage.getItem('role'),
    })
      .then(function (response) {
        // console.log(response?.data);
        window.location.reload();
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
      });
    setShow9(false);
  };

  const handledeletesalesorder = (row) => {
    setsubmitdisabled(true);
    // console.log('salesorder delete');
    // console.log(deletethissalesorder, 'salesorder to be deleted');

    setorder3(order3);
    orders3({
      actionType: 'deleteRow',
      row: deletethissalesorder.id,
      salesorder: deletethissalesorder.salesOrder,
      container: deletethissalesorder.container,
      POnumber: deletethissalesorder.POnumber,
      Qty: deletethissalesorder.Qty,
      SKU: deletethissalesorder.SKU,
      BatchCode: deletethissalesorder.BatchCode,
      Dateofarrival: deletethissalesorder.Dateofarrival,
      isAccepted: deletethissalesorder.isAccepted,
    });
    orders3({ actionType: 'addRow' });
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/followup/deletesalesorder', {
      username: localStorage.getItem('username'),
      ackno123: ackno,
      salesOrder: deletethissalesorder.salesOrder,
      id: deletethissalesorder.id,
    })
      .then(function (response) {
        // console.log(response?.data);
        setsalesorderdeletemodal(false);
        window.location.reload();
      })
      .catch(function (error) {
        // handle error
        setsalesorderdeletemodal(false);
        window.location.reload();
        // console.log(error);
      });
  };
  const handledeleteinternalfollowup = (row) => {
    // console.log('hi');
    // console.log(deletethisrow, 'row to be deleted');
    console.log(row);
    setorder4(order4);
    orders4({
      actionType: 'deleteRow',
      row: deletethisrow.id,
      author: deletethisrow.author,
      date_start: deletethisrow.container,
      owner: deletethisrow.owner,
      action: deletethisrow.action,
      comment: deletethisrow.comment,
      date_finish: deletethisrow.date_finish,
    });
    orders4({ actionType: 'addRow' });
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/followup/deleteinternalfollowup', {
      username: localStorage.getItem('username'),
      ackno123: ackno,
      comment: deletethisrow.comment,
      author: deletethisrow.author,
    })
      .then(function (response) {
        // console.log(response?.data);
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
      });
    setShow9(false);
    window.location.reload();
  };

  const orders3 = (action) => {
    if (!action) {
      return order3;
    } else {
      switch (action.actionType) {
        case 'addRow':
          let newRow = {};
          newRow.id = order3.length + 1;
          newRow.salesOrder = ' ';
          newRow.Container = ' ';
          newRow.PO = ' ';
          newRow.Quantity = ' ';
          newRow.SKU = ' ';
          newRow.batchCode = ' ';
          newRow.plantCost = ' ';
          newRow.ThirdPartyCost = ' ';
          newRow.dateOfGoodsArrival = ' ';
          newRow.isAccepted = 'N';
          setorder3([...order3, newRow]);
          // console.log('addrow');
          return order3;
        case 'deleteRow':
          //this delets different rows only
          let new_state = order3.filter(
            (row) => row.id !== action.row || row.salesorder !== action.salesorder,
          );
          // console.log('deleterow');
          setorder3([...new_state]);
          return order3;
        default:
          return order3;
      }
    }
  };

  const getUploadParams = ({ meta, file }) => {
    // console.log(meta);
    const body = new FormData();
    //body.append('sessionid12', sessionid12);
    body.append('customFile', file);
    const uploadurl = process.env.REACT_APP_API_ROOT_URL_PREFIX + '/files/api/files-1' + '/' + refsessionid12?.current;
    // console.log(uploadurl);
    return { url: uploadurl, body };
  };
  const handleClose9 = () => {
    setShow9(false);
  };

  const handleShow9 = () => setShow9(true);
  const handleSave9 = () => {
    setShow9(false);
    setPristine();
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/save/api/addinternalfollowup', {
      username: localStorage.getItem('username'),
      action: cqaction2,
      ackno: ackno,
      owner: owner2,
      compno: complaintNumber,
      comment: comment2,
      startdate: date_start2,
      finishdate: date_finish2,
      messagepostedby: localStorage.getItem('role'),
    }).then((response) => {
      // console.log(response?.data);
      window.location.reload();
    });
    orders4({ actionType: 'addRow' });
  };


  const handleShow11 = () => setShow11(true);
  const handleSave11 = () => {
    setShow11(false);
    setPristine();
    // console.log('Hello');
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/save/api/addsalesorder', {
      ackno: ackno,
      compno: complaintNumber,
      salesorder: salesorder1,
      container: container1,
      bookingnum: bookingnum1,
      batchcode: batchcode1,
      po: po1,
      sku: sku1,
      quantity: quantity1,
      dateOfGoodsArrival: dateOfGoodsArrival1,
    }).then((response) => {
      // console.log(response?.data);
      window.location.reload();
    });
  };

  const handleClose11 = () => {
    setShow11(false);
  };
  const handlesalesorderdeletemodalsave = (row) => {
    handledeletesalesorder(row);
    setsalesorderdeletemodal(false);
  };
  const handleclosesalesorderdeletemodal = () => {
    setsalesorderdeletemodal(false);
  };


  const handleSave14 = (row) => {
    // console.log('Hello');
    handleDeleteComplaint();
    //   orders4({ actionType: "addRow" });
    setShow14(false);
  };

  const handleClose14 = () => {
    setShow14(false);
  };


  const handleShowDelete = () => {
    setShow14(true);
  };

  //   const handleShow10 = () => setShow10(true);
  const handleSave10 = (row) => {
    // console.log('Hello');
    handledeleteinternalfollowup(row);
    //   orders4({ actionType: "addRow" });
    setShow10(false);
  };

  const handleClose10 = () => {
    setShow10(false);
  };


  const handleChangeStatus = ({ meta, file }, status) => {
    setDirty();
    // console.log(status, meta, file);
    if (status == 'rejected_file_type') {
      alert(
        'UnSupported file type. Either the extension is not supported / your file size is > than 50 MB',
      );
    }
    if (status == 'error_upload') {
      alert('File upload failed, files being uploaded may be open in your local PC');
    }
    if (status == 'done') {
      {
        //logging file info and name to other table
        // console.log('starting file information logging to backend', file);
        // console.log('file meta info print', meta);
        let tempemailid = localStorage.getItem('username');
        AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/creditnotedetails/storecreditnotedetails', {
          meta: meta,
          file: file,
          ackno: ackno,
          compno: complaintNumber,
          session: refsessionid12?.current,
          email: tempemailid,
        });
      }
      setfilesfinal((filesfinal) => [...filesfinal, meta.name]);
      // console.log('Files Final:', filesfinal);
      size += file.size;
      if (size > 20000000) {
        setisdisabled(true);
      } else if (size < 20000000) {
        setisdisabled(false);
      }
      // console.log('The total files size after upload is:');
      // console.log(size);
    }
    if (status == 'removed') {
      let filename = file.name;
      let tempemailid = localStorage.getItem('username');

      {
        AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/creditnotedetails/removefilefromdb', {
          meta: meta,
          file: file,
          session: refsessionid12?.current,
          email: tempemailid,
        }).then((response) => {
          //logging info to other table of the file name and type
          let message = response;
          // console.log(message);
        });
      }
      AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + `/remove/api/${refsessionid12?.current}`, { fname: filename })
        .then((response) => {
          let message = response;
          // console.log(`Removed file message:`);
          // console.log(message);
        })
        .then(
          AxiosApi.get(process.env.REACT_APP_API_ROOT_URL_PREFIX + `/folder/api/folder/${refsessionid12?.current}`).then((response) => {
            size = response?.data.filesize;
            // console.log('Afer removal total size is:');
            // console.log(size);
          }),
        );
      if (size > 20000000) {
        setisdisabled(true);
      } else if (size < 20000000) {
        setisdisabled(false);
      }
      // console.log(size);
    }
    if (status == 'error_validation') {
      alert('Maximum size is 200MB totally');
    }
  };

  const handleSubmitFinal = (files, allFiles) => {
    setdel(1);
    setsubmitdisabled(true);
    // console.log('Session id', sessionid12);
    // console.log('Ackno', acknowledgementNumber);
    // console.log('compno', complaintNumber);
    // console.log('Email', email);
    // console.log('Comp name', companyName);
    setPristine();
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + `/creditnoteupload`, {
      ackno: acknowledgementNumber,
      emailid: email,
      compno: complaintNumber,
      compname: companyName,
      ses: sessionid12,
    }).then(function (response) {
      // console.log(response?.data);
      alert('All Files are uploaded successfully!');
      window.location.reload();
    });

    // console.log('handleSubmit %o', files);

    for (let i = 0; i < files.length; i++) {
      // console.log('meta: %o', files[i].meta);
      size += files[i].meta.size;
    }

    // console.log('size: ', size);
    // allFiles.forEach(f => f.remove())
  };

  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles?.map((f) => f.fileObject));
      });
    });
  };
  const validatefile = ({ meta, file }) => {
    const valsize = 20000000;
    let totsize = size + file.size;
    if (totsize > valsize) {
      return 'File Upload Error: Maximum total files size = 200 MB';
    } else {
      return false;
    }
  };

  if (checkacknopresentspoc == 'FALSE')
    return (
      <div>
        {/* {console.log(ackno, 'props from backend')} */}
        <h1>Complaint not assigned to you</h1>
      </div>
    );
  if (checkacknopresentcqteam == 'FALSE')
    return (
      <div>
        {/* {console.log(ackno, 'props from backend')} */}
        <h1>Complaint not present in database</h1>
      </div>
    );

  // if(loaded == false)
  // return (
  //     <div>
  //         <ReactBootstrap.Spinner animation="border" />
  //     </div>
  // );

  // if(loaded == true)
  return (
    <div className="background">
      <Modal show={show10} onHide={handleClose10} backdrop="static" keyboard={false}>
        <Modal.Body>
          Are you sure you want to delete, this row will be deleted permanently.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose10}>
            Cancel
          </Button>
          <Button
            variant="danger"
            disabled={
              localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
              localStorage.getItem('role') == 'Manager' ||
              submitdisabled == true
            }
            onClick={handleSave10}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show14} onHide={handleClose14} backdrop="static" keyboard={false}>
        <Modal.Body>Are you sure you want to delete this complaint permanently?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose14}>
            Cancel
          </Button>
          <Button
            variant="danger"
            disabled={
              localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
              localStorage.getItem('role') == 'Manager' ||
              submitdisabled == true
            }
            onClick={handleSave14}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={salesorderdeletemodal}
        onHide={handleclosesalesorderdeletemodal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          Are you sure you want to delete, this row will be deleted permanently.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleclosesalesorderdeletemodal}>
            Cancel
          </Button>
          <Button
            variant="danger"
            disabled={
              localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
              localStorage.getItem('role') == 'Manager' ||
              submitdisabled == true
            }
            onClick={handlesalesorderdeletemodalsave}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show9} onHide={handleClose9} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add follow Up</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-6">
                <div className="row">
                  <div className="col-auto">
                    <label className="form-label">Start Date</label>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                      <input
                        type="date"
                        value={date_start2}
                        className="form-control form-control-sm"
                        onChange={(e) => {
                          let formateddate = String(moment(e.target.value).format('YYYY-MM-DD'));
                          // console.log(date_start2);
                          setdate_start2(formateddate);
                          // console.log(formateddate);
                        }}
                      />
                      {/* </LocalizationProvider> */}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-auto">
                    <label className="form-label">Finish Date</label>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                      <input
                        type="date"
                        value={date_finish2}
                        className="form-control form-control-sm"
                        onChange={(e) => {
                          let formateddate = String(moment(e.target.value).format('YYYY-MM-DD'));
                          // console.log(date_finish2);
                          setdate_finish2(formateddate);
                          // console.log(formateddate);
                        }}
                      />
                      {/* </LocalizationProvider> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="row">
                  <div className="col-auto">
                    <label className="form-label">Owner</label>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <select
                        id="add-select-owner-follow-up"
                        value={owner2}
                        onChange={(e) => {
                          setowner2(e.target.value);
                          // console.log('Hello');
                        }}
                        className="form-control form-control-sm"
                      >
                        <option value="">Select</option>
                        {ownerslist?.map((ownerslist) => (
                          <option value={ownerslist.offenderName}>{ownerslist.offenderName}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-auto">
                    <label className="form-label">Action</label>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <select
                        id="add-select-action-follow-up"
                        value={cqaction2}
                        onChange={(e) => setcqaction2(e.target.value)}
                        className="form-control form-control-sm"
                      >
                        <option value="">Select</option>
                        {actionslist?.map((actionslist) => (
                          <option value={actionslist.action}>{actionslist.action}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label className="form-label">Comment</label>
                  <textarea
                    id="add-comment-follow-up"
                    value={comment2}
                    onChange={(e) => setcomment2(e.target.value)}
                    className="form-control"
                    rows="3"
                    placeholder="Enter comment"
                  ></textarea>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose9}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleSave9}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show11} onHide={handleClose11} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add Sales Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-6">
                      <div className="row">
                        <div className="col-auto">
                          <label className="form-label">Sales Order</label>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <input
                              id="add-so-salesorder"
                              value={salesorder1}
                              onChange={(e) => setsalesorder1(e.target.value)}
                              type="text"
                              disabled={
                                localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                localStorage.getItem('role') == 'Manager' ||
                                submitdisabled == true
                              }
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="row">
                        <div className="col-auto">
                          <label className="form-label">Container</label>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <input
                              id="add-so-container"
                              type="text"
                              value={container1}
                              onChange={(e) => setcontainer1(e.target.value)}
                              disabled={
                                localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                localStorage.getItem('role') == 'Manager' ||
                                submitdisabled == true
                              }
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="row">
                        <div className="col-auto">
                          <label className="form-label">PO</label>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <input
                              id="add-so-po"
                              type="text"
                              value={po1}
                              onChange={(e) => setpo1(e.target.value)}
                              disabled={
                                localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                localStorage.getItem('role') == 'Manager' ||
                                submitdisabled == true
                              }
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="row">
                        <div className="col-auto">
                          <label className="form-label">Booking Number</label>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <input
                              id="add-so-bookingnum"
                              type="text"
                              value={bookingnum1}
                              onChange={(e) => setbookingnum1(e.target.value)}
                              disabled={
                                localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                localStorage.getItem('role') == 'Manager' ||
                                submitdisabled == true
                              }
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="row">
                        <div className="col-auto">
                          <label className="form-label">Quantity</label>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <input
                              id="add-so-qty"
                              type="text"
                              value={quantity1}
                              onChange={(e) => setquantity1(e.target.value)}
                              disabled={
                                localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                localStorage.getItem('role') == 'Manager' ||
                                submitdisabled == true
                              }
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="row">
                        <div className="col-auto">
                          <label className="form-label">SKU</label>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <input
                              id="add-so-sku"
                              type="text"
                              value={sku1}
                              onChange={(e) => setsku1(e.target.value)}
                              disabled={
                                localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                localStorage.getItem('role') == 'Manager' ||
                                submitdisabled == true
                              }
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="row">
                        <div className="col-auto">
                          <label className="form-label">Batch Code</label>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <input
                              id="add-so-batchcode"
                              value={batchcode1}
                              onChange={(e) => setbatchcode1(e.target.value)}
                              disabled={
                                localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                localStorage.getItem('role') == 'Manager' ||
                                submitdisabled == true
                              }
                              type="text"
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="row">
                        <div className="col-auto">
                          <label className="form-label">Date of Goods Arrival</label>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <input
                              id="add-so-dategoodsarrival"
                              value={dateOfGoodsArrival1}
                              onChange={(e) => setdateOfGoodsArrival1(e.target.value)}
                              disabled={
                                localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                localStorage.getItem('role') == 'Manager' ||
                                submitdisabled == true
                              }
                              type="date"
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose11}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleSave11}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <NavbarComponent />

      <div
        className="modal fade"
        id="loading-modal"
        tabindex="-1"
        role="dialog"
        data-keyboard="false"
        data-backdrop="static"
        aria-labelledby="loading-modal-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="loading-modal-title">
                Please Wait
              </h5>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="text-center">
                    <div
                      className="spinner-grow text-warning"
                      style={{ width: '3rem', height: '3rem' }}
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* stars onClick={() => setShow(true)}*/}

      <Modal show={show1} onHide={handleClose1} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Product cost claimed by customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="form-label">Cost</label>
                  <div className="row gutters-xs">
                    <div className="col-8">
                      <input
                        id="num-cost-customer-modal"
                        type="number"
                        disabled={
                          localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                          localStorage.getItem('role') == 'Manager' ||
                          submitdisabled == true
                        }
                        value={modalcostclaim}
                        onChange={(e) => setmodalcostclaim(e.target.value)}
                        step="0.01"
                        className="form-control"
                      />
                    </div>
                    <div className="col-4">
                      <select
                        id="select-curr-cost-customer-modal"
                        value={modalcostclaimcurr}
                        disabled={
                          localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                          localStorage.getItem('role') == 'Manager' ||
                          submitdisabled == true
                        }
                        onChange={(e) => setmodalcostclaimcurr(e.target.value)}
                        className="form-control custom-select"
                      >
                        <option value="USD">USD</option>
                        <option value="GBP">GBP</option>
                        <option value="EUR">EUR</option>
                        <option value="MXN">MXN</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleSave1}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showcurr} onHide={handleClosecurr} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Currency Converter</Modal.Title>
        </Modal.Header>
        <Currencyconverter ackno={ackno} />
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosecurr}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Extra Fees Claimed</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Import Taxes</Col>
            <Col className="col-xs-4" style={{ paddingLeft: '3rem' }}>
              <input
                id="num-cost-customer-modal"
                value={modalimporttaxesextraclaims}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodalimporttaxesextraclaims(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">
              <select
                id="chooseCurrency"
                value={importtaxesextraclaimscurrency}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setimporttaxesextraclaimscurrency(e.target.value)}
                className="form-control custom-select"
              >
                <option value="">Select</option>
                {options?.map((option) => (<option value={option.value}>{option.label}</option>))}

              </select>
            </Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Inspection</Col>
            <Col className="col-xs-4" style={{ paddingLeft: '3rem' }}>
              <input
                id="num-cost-customer-modal"
                value={modalinspectionextraclaims}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodalinspectionextraclaims(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">
              <select
                id="chooseCurrency"
                value={inspectionextraclaimscurrency}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setinspectionextraclaimscurrency(e.target.value)}
                className="form-control custom-select"
              >
                <option value="">Select</option>
                {options?.map((option) => (<option value={option.value}>{option.label}</option>))}

              </select>
            </Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Reworking/Restacking</Col>
            <Col className="col-xs-4">
              <input
                id="num-cost-customer-modal"
                value={modalreworkingrestackingextraclaims}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodalreworkingrestackingextraclaims(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">
              <select
                id="chooseCurrency"
                value={reworkingrestackingextraclaimscurrency}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setreworkingrestackingextraclaimscurrency(e.target.value)}
                className="form-control custom-select"
              >
                <option value="">Select</option>
                {options?.map((option) => (<option value={option.value}>{option.label}</option>))}

              </select>
            </Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Destruction/Disposal</Col>
            <Col className="col-xs-4" style={{ paddingLeft: '1.3rem' }}>
              <input
                id="num-cost-customer-modal"
                value={modaldestructiondisposalextraclaims}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodaldestructiondisposalextraclaims(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">
              <select
                id="chooseCurrency"
                value={destructiondisposalextraclaimscurrency}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setdestructiondisposalextraclaimscurrency(e.target.value)}
                className="form-control custom-select"
              >
                <option value="">Select</option>
                {options?.map((option) => (<option value={option.value}>{option.label}</option>))}

              </select>
            </Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Relabelling</Col>
            <Col className="col-xs-4" style={{ paddingLeft: '3rem' }}>
              <input
                id="num-cost-customer-modal"
                value={modalrelabellingextraclaims}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodalrelabellingextraclaims(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">
              <select
                id="chooseCurrency"
                value={relabellingextraclaimscurrency}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setrelabellingextraclaimscurrency(e.target.value)}
                className="form-control custom-select"
              >
                <option value="">Select</option>
                {options?.map((option) => (<option value={option.value}>{option.label}</option>))}

              </select>
            </Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Transportation</Col>
            <Col className="col-xs-4" style={{ paddingLeft: '3rem' }}>
              <input
                id="num-cost-customer-modal"
                value={modaltransportationextraclaims}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodaltransportationextraclaims(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">
              <select
                id="chooseCurrency"
                value={transportationextraclaimscurrency}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => settransportationextraclaimscurrency(e.target.value)}
                className="form-control custom-select"
              >
                <option value="">Select</option>
                {options?.map((option) => (<option value={option.value}>{option.label}</option>))}

              </select>
            </Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Product Discounts</Col>
            <Col className="col-xs-4" style={{ paddingLeft: '3rem' }}>
              <input
                id="num-cost-customer-modal"
                value={modalproductdiscountsextraclaims}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodalproductdiscountsextraclaims(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">
              <select
                id="chooseCurrency"
                value={productdiscountsextraclaimscurrency}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setproductdiscountsextraclaimscurrency(e.target.value)}
                className="form-control custom-select"
              >
                <option value="">Select</option>
                {options?.map((option) => (<option value={option.value}>{option.label}</option>))}

              </select>
            </Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Ocean Freight</Col>
            <Col className="col-xs-4" style={{ paddingLeft: '3rem' }}>
              <input
                id="num-cost-customer-modal"
                value={modaloceanfreightextraclaims}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodaloceanfreightextraclaims(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">
              <select
                id="chooseCurrency"
                value={oceanfreightextraclaimscurrency}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setoceanfreightextraclaimscurrency(e.target.value)}
                className="form-control custom-select"
              >
                <option value="">Select</option>
                {options?.map((option) => (<option value={option.value}>{option.label}</option>))}

              </select>
            </Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Customs Fee</Col>
            <Col className="col-xs-4" style={{ paddingLeft: '3rem' }}>
              <input
                id="num-cost-customer-modal"
                value={modalcustomsfeeextraclaims}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodalcustomsfeeextraclaims(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">
              <select
                id="chooseCurrency"
                value={customsfeeextraclaimscurrency}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setcustomsfeeextraclaimscurrency(e.target.value)}
                className="form-control custom-select"
              >
                <option value="">Select</option>
                {options?.map((option) => (<option value={option.value}>{option.label}</option>))}

              </select>
            </Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Demurrage & Detention/ Storage/ Waiting charges</Col>
            <Col style={{ paddingLeft: '3rem' }} className="col-xs-4">
              <input
                id="num-cost-customer-modal"
                value={modaldemurrageextraclaims}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodaldemurrageextraclaims(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">
              <select
                id="chooseCurrency"
                value={demurrageextraclaimscurrency}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setdemurrageextraclaimscurrency(e.target.value)}
                className="form-control custom-select"
              >
                <option value="">Select</option>
                {options?.map((option) => (<option value={option.value}>{option.label}</option>))}

              </select>
            </Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Other</Col>
            <Col className="col-xs-4" style={{ paddingLeft: '3rem' }}>
              <input
                id="num-cost-customer-modal"
                value={modalotherextraclaims}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodalotherextraclaims(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">
              <select
                id="chooseCurrency"
                value={otherextraclaimscurrency}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setotherextraclaimscurrency(e.target.value)}
                className="form-control custom-select"
              >
                <option value="">Select</option>
                {options?.map((option) => (<option value={option.value}>{option.label}</option>))}

              </select>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleSave2}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show4} onHide={handleClose4} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Plant Extra Fees Accepted</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Import Taxes</Col>
            <Col className="col-xs-4" style={{ paddingLeft: '3rem' }}>
              <input
                id="num-cost-customer-modal"
                value={modalimporttaxesplantaccepted}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodalimporttaxesplantaccepted(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">{plantcostpaidcurr}</Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Inspection</Col>
            <Col className="col-xs-4" style={{ paddingLeft: '3rem' }}>
              <input
                id="num-cost-customer-modal"
                value={modalinspectionplantaccepted}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodalinspectionplantaccepted(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">{plantcostpaidcurr}</Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Reworking/Restacking</Col>
            <Col className="col-xs-4">
              <input
                id="num-cost-customer-modal"
                value={modalreworkingrestackingplantaccepted}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodalreworkingrestackingplantaccepted(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">{plantcostpaidcurr}</Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Destruction/Disposal</Col>
            <Col className="col-xs-4" style={{ paddingLeft: '1.3rem' }}>
              <input
                id="num-cost-customer-modal"
                value={modaldestructiondisposalplantaccepted}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodaldestructiondisposalplantaccepted(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">{plantcostpaidcurr}</Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Relabelling</Col>
            <Col className="col-xs-4" style={{ paddingLeft: '3rem' }}>
              <input
                id="num-cost-customer-modal"
                value={modalrelabellingplantaccepted}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodalrelabellingplantaccepted(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">{plantcostpaidcurr}</Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Transportation</Col>
            <Col className="col-xs-4" style={{ paddingLeft: '3rem' }}>
              <input
                id="num-cost-customer-modal"
                value={modaltransportationplantaccepted}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodaltransportationplantaccepted(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">{plantcostpaidcurr}</Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Product Discounts</Col>
            <Col className="col-xs-4" style={{ paddingLeft: '3rem' }}>
              <input
                id="num-cost-customer-modal"
                value={modalproductdiscountsplantaccepted}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodalproductdiscountsplantaccepted(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">{plantcostpaidcurr}</Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Ocean Freight</Col>
            <Col className="col-xs-4" style={{ paddingLeft: '3rem' }}>
              <input
                id="num-cost-customer-modal"
                value={modaloceanfreightplantaccepted}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodaloceanfreightplantaccepted(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">{plantcostpaidcurr}</Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Customs Fee</Col>
            <Col className="col-xs-4" style={{ paddingLeft: '3rem' }}>
              <input
                id="num-cost-customer-modal"
                value={modalcustomsfeeplantaccepted}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodalcustomsfeeplantaccepted(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">{plantcostpaidcurr}</Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Demurrage & Detention/ Storage/ Waiting charges</Col>
            <Col style={{ paddingLeft: '3rem' }} className="col-xs-4">
              <input
                id="num-cost-customer-modal"
                value={modaldemurrageplantaccepted}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodaldemurrageplantaccepted(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">{plantcostpaidcurr}</Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Other</Col>
            <Col className="col-xs-4" style={{ paddingLeft: '3rem' }}>
              <input
                id="num-cost-customer-modal"
                value={modalotherplantaccepted}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodalotherplantaccepted(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">{plantcostpaidcurr}</Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleSave4}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show3} onHide={handleClose3} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Extra Fees Paid</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Import Taxes</Col>
            <Col className="col-xs-4" style={{ paddingLeft: '3rem' }}>
              <input
                id="num-cost-customer-modal"
                value={modalimporttaxescostpaid}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodalimporttaxescostpaid(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">{importercostpaidcurr}</Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Inspection</Col>
            <Col className="col-xs-4" style={{ paddingLeft: '3rem' }}>
              <input
                id="num-cost-customer-modal"
                value={modalinspectioncostpaid}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodalinspectioncostpaid(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">{importercostpaidcurr}</Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Reworking/Restacking</Col>
            <Col className="col-xs-4">
              <input
                id="num-cost-customer-modal"
                value={modalreworkingrestackingcostpaid}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodalreworkingrestackingcostpaid(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">{importercostpaidcurr}</Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Destruction/Disposal</Col>
            <Col className="col-xs-4" style={{ paddingLeft: '1.3rem' }}>
              <input
                id="num-cost-customer-modal"
                value={modaldestructiondisposalcostpaid}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodaldestructiondisposalcostpaid(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">{importercostpaidcurr}</Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Relabelling</Col>
            <Col className="col-xs-4" style={{ paddingLeft: '3rem' }}>
              <input
                id="num-cost-customer-modal"
                value={modalrelabellingcostpaid}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodalrelabellingcostpaid(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">{importercostpaidcurr}</Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Transportation</Col>
            <Col className="col-xs-4" style={{ paddingLeft: '3rem' }}>
              <input
                id="num-cost-customer-modal"
                value={modaltransportationcostpaid}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodaltransportationcostpaid(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">{importercostpaidcurr}</Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Product Discounts</Col>
            <Col className="col-xs-4" style={{ paddingLeft: '3rem' }}>
              <input
                id="num-cost-customer-modal"
                value={modalproductdiscountscostpaid}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodalproductdiscountscostpaid(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">{importercostpaidcurr}</Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Ocean Freight</Col>
            <Col className="col-xs-4" style={{ paddingLeft: '3rem' }}>
              <input
                id="num-cost-customer-modal"
                value={modaloceanfreightcostpaid}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodaloceanfreightcostpaid(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">{importercostpaidcurr}</Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Customs Fee</Col>
            <Col className="col-xs-4" style={{ paddingLeft: '3rem' }}>
              <input
                id="num-cost-customer-modal"
                value={modalcustomsfeecostpaid}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodalcustomsfeecostpaid(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">{importercostpaidcurr}</Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Demurrage & Detention/ Storage/ Waiting charges</Col>
            <Col style={{ paddingLeft: '3rem' }} className="col-xs-4">
              <input
                id="num-cost-customer-modal"
                value={modaldemurragecostpaid}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodaldemurragecostpaid(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">{importercostpaidcurr}</Col>
          </Row>
          <Row style={{ margin: '0.5rem' }}>
            <Col className="col-xs-5">Other</Col>
            <Col className="col-xs-4" style={{ paddingLeft: '3rem' }}>
              <input
                id="num-cost-customer-modal"
                value={modalothercostpaid}
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                onChange={(e) => setmodalothercostpaid(e.target.value)}
                type="number"
                step="0.01"
                className="form-control"
              />
            </Col>
            <Col className="col-xs-3">{importercostpaidcurr}</Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleSave3}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show5} onHide={handleClose5} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Product Cost Accepted Split</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-3">
                <label className="form-label">Area Manager</label>
              </div>
              <div className="col-auto">
                <div className="form-group">
                  <input
                    id="ca-am"
                    type="number"
                    disabled={
                      localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                      localStorage.getItem('role') == 'Manager' ||
                      submitdisabled == true
                    }
                    onChange={(e) => setcostAcceptedByAm(e.target.value)}
                    value={costAcceptedByAm}
                    step="0.01"
                    className="form-control form-control-sm"
                  />
                </div>
              </div>
              <div className="col-auto" style={{ paddingBottom: '1rem' }}>
                <label id="ca-am-curr" className="form-label"></label>
                <select
                  id="ca-am-curr"
                  disabled={
                    localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                    localStorage.getItem('role') == 'Manager' ||
                    submitdisabled == true
                  }
                  onChange={(e) => setcurrAccAm(e.target.value)}
                  value={currAccAm}
                  className="form-control form-control-sm"
                >
                  <option value="">Select</option>
                  <option value="USD">USD</option>
                  <option value="GBP">GBP</option>
                  <option value="EUR">EUR</option>
                  <option value="MXN">MXN</option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col-3">
                <label className="form-label">VLC</label>
              </div>
              <div className="col-auto">
                <div className="form-group">
                  <input
                    id="ca-vlc"
                    type="number"
                    disabled={
                      localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                      localStorage.getItem('role') == 'Manager' ||
                      submitdisabled == true
                    }
                    onChange={(e) => setcostAcceptedByVlc(e.target.value)}
                    value={costAcceptedByVlc}
                    step="0.01"
                    className="form-control form-control-sm"
                  />
                </div>
              </div>
              <div className="col-auto" style={{ paddingBottom: '1rem' }}>
                <label id="ca-vlc-curr" className="form-label"></label>
                <select
                  id="ca-vlc-curr"
                  value={currAccVlc}
                  disabled={
                    localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                    localStorage.getItem('role') == 'Manager' ||
                    submitdisabled == true
                  }
                  onChange={(e) => setcurrAccVlc(e.target.value)}
                  className="form-control form-control-sm"
                >
                  <option value="">Select</option>
                  <option value="USD">USD</option>
                  <option value="GBP">GBP</option>
                  <option value="EUR">EUR</option>
                  <option value="MXN">MXN</option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col-3">
                <label className="form-label">Plant/Logistics</label>
              </div>
              <div className="col-auto">
                <div className="form-group">
                  <input
                    id="ca-pl"
                    type="number"
                    disabled={
                      localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                      localStorage.getItem('role') == 'Manager' ||
                      submitdisabled == true
                    }
                    onChange={(e) => setcostAcceptedByPlantLogi(e.target.value)}
                    value={costAcceptedByPlantLogi}
                    step="0.01"
                    className="form-control form-control-sm"
                  />
                </div>
              </div>
              <div className="col-auto" style={{ paddingBottom: '1rem' }}>
                <label id="ca-pl-curr" className="form-label"></label>
                <select
                  id="ca-pl-curr"
                  disabled={
                    localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                    localStorage.getItem('role') == 'Manager' ||
                    submitdisabled == true
                  }
                  onChange={(e) => setcurrAccPl(e.target.value)}
                  value={currAccPl}
                  className="form-control form-control-sm"
                >
                  <option value="">Select</option>
                  <option value="USD">USD</option>
                  <option value="GBP">GBP</option>
                  <option value="EUR">EUR</option>
                  <option value="MXN">MXN</option>
                </select>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose5}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleClose5}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show6} onHide={handleClose6} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Extra Fees Accepted Split</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-3">
                <label className="form-label">Area Manager</label>
              </div>
              <div className="col-auto">
                <div className="form-group">
                  <input
                    id="ca-am"
                    type="number"
                    disabled={
                      localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                      localStorage.getItem('role') == 'Manager' ||
                      submitdisabled == true
                    }
                    onChange={(e) => setfeesAcceptedByAm(e.target.value)}
                    value={feesAcceptedByAm}
                    step="0.01"
                    className="form-control form-control-sm"
                  />
                </div>
              </div>
              <div className="col-auto" style={{ paddingBottom: '1rem' }}>
                <label id="ca-am-curr" className="form-label"></label>
                <select
                  id="ca-am-curr"
                  disabled={
                    localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                    localStorage.getItem('role') == 'Manager' ||
                    submitdisabled == true
                  }
                  onChange={(e) => setcurrExtraAm(e.target.value)}
                  value={currExtraAm}
                  className="form-control form-control-sm"
                >
                  <option value="">Select</option>
                  <option value="USD">USD</option>
                  <option value="GBP">GBP</option>
                  <option value="EUR">EUR</option>
                  <option value="MXN">MXN</option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col-3">
                <label className="form-label">VLC</label>
              </div>
              <div className="col-auto">
                <div className="form-group">
                  <input
                    id="ca-vlc"
                    type="number"
                    disabled={
                      localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                      localStorage.getItem('role') == 'Manager' ||
                      submitdisabled == true
                    }
                    onChange={(e) => setfeesAcceptedByVlc(e.target.value)}
                    value={feesAcceptedByVlc}
                    step="0.01"
                    className="form-control form-control-sm"
                  />
                </div>
              </div>
              <div className="col-auto" style={{ paddingBottom: '1rem' }}>
                <label id="ca-vlc-curr" className="form-label"></label>
                <select
                  id="ca-vlc-curr"
                  disabled={
                    localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                    localStorage.getItem('role') == 'Manager' ||
                    submitdisabled == true
                  }
                  onChange={(e) => setcurrExtraVlc(e.target.value)}
                  value={currExtraVlc}
                  className="form-control form-control-sm"
                >
                  <option value="">Select</option>
                  <option value="USD">USD</option>
                  <option value="GBP">GBP</option>
                  <option value="EUR">EUR</option>
                  <option value="MXN">MXN</option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col-3">
                <label className="form-label">Plant/Logistics</label>
              </div>
              <div className="col-auto">
                <div className="form-group">
                  <input
                    id="ca-pl"
                    type="number"
                    disabled={
                      localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                      localStorage.getItem('role') == 'Manager' ||
                      submitdisabled == true
                    }
                    onChange={(e) => setfeesAcceptedbyPl(e.target.value)}
                    value={feesAcceptedbyPl}
                    step="0.01"
                    className="form-control form-control-sm"
                  />
                </div>
              </div>
              <div className="col-auto" style={{ paddingBottom: '1rem' }}>
                <label id="ca-pl-curr" className="form-label"></label>
                <select
                  id="ca-pl-curr"
                  disabled={
                    localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                    localStorage.getItem('role') == 'Manager' ||
                    submitdisabled == true
                  }
                  onChange={(e) => setcurrExtraPl(e.target.value)}
                  value={currExtraPl}
                  className="form-control form-control-sm"
                >
                  <option value="">Select</option>
                  <option value="USD">USD</option>
                  <option value="GBP">GBP</option>
                  <option value="EUR">EUR</option>
                  <option value="MXN">MXN</option>
                </select>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose6}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleClose6}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show7} onHide={handleClose7} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add Customer follow Up</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-auto">
                    <label className="form-label">Owner</label>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <input
                        id="add-select-owner-follow-up"
                        value={localStorage.getItem('username')}
                        className="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-auto">
                    <label className="form-label">Action</label>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <select
                        id="add-select-action-follow-up"
                        value={cqaction1}
                        onChange={(e) => setcqaction1(e.target.value)}
                        className="form-control form-control-sm"
                      >
                        <option value="General">General</option>
                        <option value="Additional information request">
                          Additional information request
                        </option>
                        <option value="Additional documents request">
                          Additional documents request
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label className="form-label">Comment</label>
                  <textarea
                    id="add-comment-follow-up"
                    value={comment1}
                    onChange={(e) => setcomment1(e.target.value)}
                    className="form-control"
                    rows="3"
                    placeholder="Enter comment"
                  ></textarea>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose7}>
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={handlesendcustomerfollowup}
            disabled={
              localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
              localStorage.getItem('role') == 'Manager' ||
              submitdisabled == true
            }
          >
            Send Follow Up
          </Button>
        </Modal.Footer>
      </Modal>

      <div
        className="modal fade"
        id="dialog-modal"
        tabindex="-1"
        role="dialog"
        data-keyboard="false"
        data-backdrop="static"
        aria-labelledby="dialog-modal-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="dialog-modal-title">
                Information
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <p id="dialog-modal-msg"></p>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Close
              </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*Modal for Confirm Dialog Box*/}
      <div
        className="modal fade"
        id="confirm-dialog-modal"
        tabindex="-1"
        role="dialog"
        data-keyboard="false"
        data-backdrop="static"
        aria-labelledby="confirm-dialog-modal-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="confirm-dialog-modal-title">
                Information
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <p id="confirm-dialog-modal-msg"></p>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" id="confirm-yes" className="btn btn-danger">
                Yes
              </button>
              <button type="button" id="confirm-no" className="btn btn-secondary">
                No
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*Modal for Product Cost*/}
      <div
        className="modal fade"
        id="cost-customer-modal"
        tabindex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="cost-customer-modal-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="cost-customer-modal-title"></h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label">Cost</label>
                      <div className="row gutters-xs">
                        <div className="col-8">
                          <input
                            id="num-cost-customer-modal"
                            disabled={
                              localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                              localStorage.getItem('role') == 'Manager' ||
                              submitdisabled == true
                            }
                            type="number"
                            step="0.01"
                            className="form-control"
                          />
                        </div>
                        <div className="col-4">
                          <select
                            id="select-curr-cost-customer-modal"
                            disabled={
                              localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                              localStorage.getItem('role') == 'Manager' ||
                              submitdisabled == true
                            }
                            className="form-control custom-select"
                          >
                            <option value="">Select</option>
                            <option value="USD">USD</option>
                            <option value="GBP">GBP</option>
                            <option value="EUR">EUR</option>
                            <option value="MXN">MXN</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                id="save-cost-customer-modal"
                type="button"
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                className="btn btn-primary"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*Modal for Extra Fees Claimed*/}
      <div
        className="modal fade"
        id="extra-fees-claimed-customer-modal"
        tabindex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="extra-fees-claimed-customer-modal-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="extra-fees-claimed-customer-modal-title">
                Extra Fees Claimed
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body"></div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                id="save-extra-fees-claimed-modal"
                type="button"
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                className="btn btn-primary"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="extra-fees-paid-customer-modal"
        tabindex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="extra-fees-paid-customer-modal-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="extra-fees-paid-customer-modal-title">
                Extra Fees Paid
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body"></div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                id="save-extra-fees-paid-modal"
                type="button"
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                className="btn btn-primary"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="plant-extra-fees-accepted-customer-modal"
        tabindex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="plant-extra-fees-accepted-customer-modal-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="plant-extra-fees-accepted-customer-modal-title">
                Plant Extra Fees Accepted
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body"></div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                id="save-plant-extra-fees-accepted-modal"
                type="button"
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                className="btn btn-primary"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*Modal for Product Cost Accepted Split*/}
      <div
        className="modal fade"
        id="cost-accepted-split-modal"
        tabindex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="cost-accepted-split-modal-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="cost-accepted-split-modal-title">
                Product Cost Accepted Split
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-3">
                    <label className="form-label">Area Manager</label>
                  </div>
                  <div className="col-auto">
                    <div className="form-group">
                      <input
                        id="ca-am"
                        type="number"
                        step="0.01"
                        disabled={
                          localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                          localStorage.getItem('role') == 'Manager' ||
                          submitdisabled == true
                        }
                        className="form-control form-control-sm"
                      />
                    </div>
                  </div>
                  <div className="col-auto">
                    <label id="ca-am-curr" className="form-label"></label>
                    <select
                      id="ca-am-curr"
                      disabled={
                        localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                        localStorage.getItem('role') == 'Manager' ||
                        submitdisabled == true
                      }
                      className="form-control form-control-sm"
                    >
                      <option value="">Select</option>
                      <option value="USD">USD</option>
                      <option value="GBP">GBP</option>
                      <option value="EUR">EUR</option>
                      <option value="MXN">MXN</option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-3">
                    <label className="form-label">VLC</label>
                  </div>
                  <div className="col-auto">
                    <div className="form-group">
                      <input
                        id="ca-vlc"
                        type="number"
                        step="0.01"
                        disabled={
                          localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                          localStorage.getItem('role') == 'Manager' ||
                          submitdisabled == true
                        }
                        className="form-control form-control-sm"
                      />
                    </div>
                  </div>
                  <div className="col-auto">
                    <label id="ca-vlc-curr" className="form-label"></label>
                    <select
                      id="ca-vlc-curr"
                      disabled={
                        localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                        localStorage.getItem('role') == 'Manager' ||
                        submitdisabled == true
                      }
                      className="form-control form-control-sm"
                    >
                      <option value="">Select</option>
                      <option value="USD">USD</option>
                      <option value="GBP">GBP</option>
                      <option value="EUR">EUR</option>
                      <option value="MXN">MXN</option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-3">
                    <label className="form-label">Plant/Logistics</label>
                  </div>
                  <div className="col-auto">
                    <div className="form-group">
                      <input
                        id="ca-pl"
                        type="number"
                        step="0.01"
                        disabled={
                          localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                          localStorage.getItem('role') == 'Manager' ||
                          submitdisabled == true
                        }
                        className="form-control form-control-sm"
                      />
                    </div>
                  </div>
                  <div className="col-auto">
                    <label id="ca-pl-curr" className="form-label"></label>
                    <select
                      id="ca-pl-curr"
                      disabled={
                        localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                        localStorage.getItem('role') == 'Manager' ||
                        submitdisabled == true
                      }
                      className="form-control form-control-sm"
                    >
                      <option value="">Select</option>
                      <option value="USD">USD</option>
                      <option value="GBP">GBP</option>
                      <option value="EUR">EUR</option>
                      <option value="MXN">MXN</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                id="cost-accepted-split-modal-save"
                type="button"
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                className="btn btn-primary"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*Modal for Extra Accepted Split*/}
      <div
        className="modal fade"
        id="plant-extra-modal"
        tabindex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="plant-extra-modal-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="plant-extra-modal-title">
                Extra Fees Accepted Split
              </h5>
              <button
                type="button"
                className="close"
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-3">
                    <label className="form-label">Area Manager</label>
                  </div>
                  <div className="col-auto">
                    <div className="form-group">
                      <input
                        id="ef-am"
                        type="number"
                        step="0.01"
                        disabled={
                          localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                          localStorage.getItem('role') == 'Manager' ||
                          submitdisabled == true
                        }
                        className="form-control form-control-sm"
                      />
                    </div>
                  </div>
                  <div className="col-auto">
                    <label id="ef-am-curr" className="form-label"></label>
                    <select
                      id="ef-am-curr"
                      disabled={
                        localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                        localStorage.getItem('role') == 'Manager' ||
                        submitdisabled == true
                      }
                      className="form-control form-control-sm"
                    >
                      <option value="">Select</option>
                      <option value="USD">USD</option>
                      <option value="GBP">GBP</option>
                      <option value="EUR">EUR</option>
                      <option value="MXN">MXN</option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-3">
                    <label className="form-label">VLC</label>
                  </div>
                  <div className="col-auto">
                    <div className="form-group">
                      <input
                        id="ef-vlc"
                        type="number"
                        step="0.01"
                        disabled={
                          localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                          localStorage.getItem('role') == 'Manager' ||
                          submitdisabled == true
                        }
                        className="form-control form-control-sm"
                      />
                    </div>
                  </div>
                  <div className="col-auto">
                    <label id="ef-vlc-curr" className="form-label"></label>
                    <select
                      id="ef-vlc-curr"
                      disabled={
                        localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                        localStorage.getItem('role') == 'Manager' ||
                        submitdisabled == true
                      }
                      className="form-control form-control-sm"
                    >
                      <option value="">Select</option>
                      <option value="USD">USD</option>
                      <option value="GBP">GBP</option>
                      <option value="EUR">EUR</option>
                      <option value="MXN">MXN</option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-3">
                    <label className="form-label">Plant/Logistics</label>
                  </div>
                  <div className="col-auto">
                    <div className="form-group">
                      <input
                        id="ef-pl"
                        type="number"
                        step="0.01"
                        disabled={
                          localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                          localStorage.getItem('role') == 'Manager' ||
                          submitdisabled == true
                        }
                        className="form-control form-control-sm"
                      />
                    </div>
                  </div>
                  <div className="col-auto">
                    <label id="ef-pl-curr" className="form-label"></label>
                    <select
                      id="ef-pl-curr"
                      disabled={
                        localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                        localStorage.getItem('role') == 'Manager' ||
                        submitdisabled == true
                      }
                      className="form-control form-control-sm"
                    >
                      <option value="">Select</option>
                      <option value="USD">USD</option>
                      <option value="GBP">GBP</option>
                      <option value="EUR">EUR</option>
                      <option value="MXN">MXN</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                id="plant-extra-accepted-save"
                type="button"
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                className="btn btn-primary"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*Modal for Add Follow up*/}
      <div
        className="modal fade"
        id="add-follow-up-modal"
        tabindex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="add-follow-up-modal-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="add-follow-up-modal-title">
                Add Follow Up
              </h5>
              <button
                type="button"
                className="close"
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-auto">
                        <label className="form-label">Start Date</label>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <input
                            id="add-startdate-follow-up"
                            type="date"
                            disabled={
                              localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                              localStorage.getItem('role') == 'Manager' ||
                              submitdisabled == true
                            }
                            className="form-control form-control-sm"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-auto">
                        <label className="form-label">Finish Date</label>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <input
                            id="add-finishdate-follow-up"
                            disabled={
                              localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                              localStorage.getItem('role') == 'Manager' ||
                              submitdisabled == true
                            }
                            type="date"
                            className="form-control form-control-sm"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-auto">
                        <label className="form-label">Owner</label>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <select
                            id="add-select-owner-follow-up"
                            disabled={
                              localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                              localStorage.getItem('role') == 'Manager' ||
                              submitdisabled == true
                            }
                            className="form-control form-control-sm"
                          >
                            <option value="">Select</option>
                            <option value=""></option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-auto">
                        <label className="form-label">Action</label>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <select
                            id="add-select-action-follow-up"
                            disabled={
                              localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                              localStorage.getItem('role') == 'Manager' ||
                              submitdisabled == true
                            }
                            className="form-control form-control-sm"
                          >
                            <option value="">Select</option>
                            <option value=""></option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label className="form-label">Comment</label>
                      <textarea
                        id="add-comment-follow-up"
                        className="form-control"
                        rows="3"
                        placeholder="Enter comment"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                id="add-follow-up-save"
                type="button"
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                className="btn btn-primary"
              >
                Save Follow Up
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*Modal for Edit Follow up*/}
      <div
        className="modal fade"
        id="edit-follow-up-modal"
        tabindex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="edit-follow-up-modal-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="edit-follow-up-modal-title">
                Edit Follow Up
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-auto">
                        <label className="form-label">Start Date</label>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <input
                            id="edit-startdate-follow-up"
                            type="date"
                            disabled={
                              localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                              localStorage.getItem('role') == 'Manager' ||
                              submitdisabled == true
                            }
                            className="form-control form-control-sm"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-auto">
                        <label className="form-label">Finish Date</label>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <input
                            id="edit-finishdate-follow-up"
                            type="date"
                            disabled={
                              localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                              localStorage.getItem('role') == 'Manager' ||
                              submitdisabled == true
                            }
                            className="form-control form-control-sm"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-auto">
                        <label className="form-label">Owner</label>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <select
                            id="edit-select-owner-follow-up"
                            disabled={
                              localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                              localStorage.getItem('role') == 'Manager' ||
                              submitdisabled == true
                            }
                            className="form-control form-control-sm"
                          >
                            <option value="">Select</option>
                            <option value=""></option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-auto">
                        <label className="form-label">Action</label>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <select
                            id="edit-select-action-follow-up"
                            disabled={
                              localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                              localStorage.getItem('role') == 'Manager' ||
                              submitdisabled == true
                            }
                            className="form-control form-control-sm"
                          >
                            <option value="">Select</option>
                            <option value=""></option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label className="form-label">Comment</label>
                      <textarea
                        id="edit-comment-follow-up"
                        className="form-control"
                        rows="3"
                        placeholder="Enter comment"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                id="edit-follow-up-save"
                type="button"
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                className="btn btn-primary"
              >
                Update Follow Up
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*Modal for Sales Order Details*/}
      <div
        className="modal fade"
        id="so-details-modal"
        tabindex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="so-details-modal-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="so-details-modal-title"></h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-label">Shipment Number</label>
                      <div className="form-control-plaintext" id="so-shipment-num"></div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-label">Container</label>
                      <div className="form-control-plaintext" id="so-container"></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-label">PO</label>
                      <div className="form-control-plaintext" id="so-po"></div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-label">Quantity</label>
                      <div className="form-control-plaintext" id="so-qty"></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-label">SKU</label>
                      <div className="form-control-plaintext" id="so-sku"></div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-label">Export SKU</label>
                      <div className="form-control-plaintext" id="so-export-sku"></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-label">Production Plant Code</label>
                      <div className="form-control-plaintext" id="so-prod-plant-code"></div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-label">Loading Point</label>
                      <div className="form-control-plaintext" id="so-loading-point"></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-label">Pack Size</label>
                      <div className="form-control-plaintext" id="so-pack-size"></div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-label">Type of Package Material</label>
                      <div className="form-control-plaintext" id="so-type-pack-mat"></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-label">Brand</label>
                      <div className="form-control-plaintext" id="so-brand"></div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-label">Brand Description</label>
                      <div className="form-control-plaintext" id="so-brand-desc"></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-label">HL</label>
                      <div className="form-control-plaintext" id="so-hl"></div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-label">Type of Package</label>
                      <div className="form-control-plaintext" id="so-type-pack"></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-10">
                    <div className="form-group">
                      <label className="form-label">Batch Code</label>
                      <div className="row gutters-xs">
                        <div className="col-12">
                          <div className="input-group">
                            <input
                              type="text"
                              id="so-batch-code"
                              disabled={
                                localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                localStorage.getItem('role') == 'Manager' ||
                                submitdisabled == true
                              }
                              className="form-control"
                              placeholder="Enter batch code"
                            />
                            <span className="input-group-append">
                              <button
                                id="so-update-batch-code"
                                disabled={
                                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                  localStorage.getItem('role') == 'Manager' ||
                                  submitdisabled == true
                                }
                                className="btn btn-warning"
                                type="button"
                              >
                                Update
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-auto">
                        <label className="form-label"></label>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              type="text"
                              id="so-batch-code"
                              className="form-control"
                              disabled={
                                localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                localStorage.getItem('role') == 'Manager' ||
                                submitdisabled == true
                              }
                              placeholder="Enter batch code"
                            />
                            <span className="input-group-append">
                              <button
                                className="btn btn-warning"
                                disabled={
                                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                  localStorage.getItem('role') == 'Manager' ||
                                  submitdisabled == true
                                }
                                type="button"
                              >
                                Update
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/*Modal for Add Sales Order*/}
      <div
        className="modal fade"
        id="add-so-details-modal"
        tabindex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="add-so-details-modal-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="add-so-details-modal-title">
                Add Sales Order
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-6">
                          <div className="row">
                            <div className="col-auto">
                              <label className="form-label">Sales Order</label>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <input
                                  id="add-so-salesorder"
                                  type="text"
                                  disabled={
                                    localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                    localStorage.getItem('role') == 'Manager' ||
                                    submitdisabled == true
                                  }
                                  className="form-control form-control-sm"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-auto">
                              <label className="form-label">Container</label>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <input
                                  id="add-so-container"
                                  type="text"
                                  disabled={
                                    localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                    localStorage.getItem('role') == 'Manager' ||
                                    submitdisabled == true
                                  }
                                  className="form-control form-control-sm"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="row">
                            <div className="col-auto">
                              <label className="form-label">PO</label>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <input
                                  id="add-so-po"
                                  type="text"
                                  disabled={
                                    localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                    localStorage.getItem('role') == 'Manager' ||
                                    submitdisabled == true
                                  }
                                  className="form-control form-control-sm"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-auto">
                              <label className="form-label">Booking Number</label>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <input
                                  id="add-so-bookingnum"
                                  type="text"
                                  disabled={
                                    localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                    localStorage.getItem('role') == 'Manager' ||
                                    submitdisabled == true
                                  }
                                  className="form-control form-control-sm"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="row">
                            <div className="col-auto">
                              <label className="form-label">Quantity</label>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <input
                                  id="add-so-qty"
                                  type="text"
                                  disabled={
                                    localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                    localStorage.getItem('role') == 'Manager' ||
                                    submitdisabled == true
                                  }
                                  className="form-control form-control-sm"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-auto">
                              <label className="form-label">SKU</label>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <input
                                  id="add-so-sku"
                                  type="text"
                                  disabled={
                                    localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                    localStorage.getItem('role') == 'Manager' ||
                                    submitdisabled == true
                                  }
                                  className="form-control form-control-sm"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="row">
                            <div className="col-auto">
                              <label className="form-label">Batch Code</label>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <input
                                  id="add-so-batchcode"
                                  disabled={
                                    localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                    localStorage.getItem('role') == 'Manager' ||
                                    submitdisabled == true
                                  }
                                  type="text"
                                  className="form-control form-control-sm"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-auto">
                              <label className="form-label">Date of Goods Arrival</label>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <input
                                  id="add-so-dategoodsarrival"
                                  disabled={
                                    localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                    localStorage.getItem('role') == 'Manager' ||
                                    submitdisabled == true
                                  }
                                  type="date"
                                  className="form-control form-control-sm"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Close
              </button>
              <button id="add-so-save" type="button" className="btn btn-primary">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="curr-conv-modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="curr-conv-modal-title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="curr-conv-modal-title">
                Currency Converter
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div>
              <Row>
                <div className="col-lg-12">
                  <div className="form-group">
                    <div className="row gutters-xs">
                      <div className="col-8">
                        <input
                          id="val-curr1"
                          type="number"
                          disabled={
                            localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                            localStorage.getItem('role') == 'Manager' ||
                            submitdisabled == true
                          }
                          className="form-control"
                          placeholder="From"
                        />
                      </div>
                      <div className="col-4">
                        <select
                          id="select-curr1"
                          disabled={
                            localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                            localStorage.getItem('role') == 'Manager' ||
                            submitdisabled == true
                          }
                          className="form-control custom-select"
                        >
                          <option value=""></option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
              <Row>
                <div className="col-lg-12">
                  <div className="form-group">
                    <div className="row gutters-xs">
                      <div className="col-8">
                        <input
                          id="val-curr2"
                          type="number"
                          disabled={
                            localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                            localStorage.getItem('role') == 'Manager' ||
                            submitdisabled == true
                          }
                          readonly
                          className="form-control"
                          placeholder="To"
                        />
                      </div>
                      <div className="col-4">
                        <select
                          name="subtype"
                          id="select-curr2"
                          disabled={
                            localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                            localStorage.getItem('role') == 'Manager' ||
                            submitdisabled == true
                          }
                          className="form-control custom-select"
                        >
                          <option value=""></option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            </div>
            <div className="modal-footer">
              <button
                id="btn-convert"
                type="button"
                disabled={
                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                  localStorage.getItem('role') == 'Manager' ||
                  submitdisabled == true
                }
                className="btn btn-primary"
              >
                Convert
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="my-3 my-md-5 content">
        <div className="page-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-auto">
                <h1 className="page-title">
                  <strong id="complaint-num">{complaintNumber}</strong> - {custname}
                </h1>
              </div>
              {/* //newly added */}
              <div className="col-12 col-lg-2">
                <div className="form-group">
                  <select
                    id="select-complaint-type"
                    disabled={
                      localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                      localStorage.getItem('role') == 'Manager' ||
                      submitdisabled == true
                    }
                    onChange={(e) => setheadercomplainttype(e.target.value)}
                    value={headercomplainttype}
                    className="form-control custom-select form-control-sm"
                  >
                    {/* <option value="">Select Complaint Type</option> */}
                    <option value="logistics">Logistics</option>
                    <option value="quality">Quality</option>
                  </select>
                </div>
              </div>
              {/* //newly added */}
              <div className="col-12 col-lg-2">
                <div className="form-group">
                  <select
                    id="select-entry-type"
                    disabled={
                      localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                      localStorage.getItem('role') == 'Manager' ||
                      submitdisabled == true
                    }
                    onChange={handleSetType}
                    value={type}
                    className="form-control custom-select form-control-sm"
                  >
                    <option value="">Select Entry Type</option>
                    <option value="Complaint">Complaint</option>
                    <option value="Invalid">Invalid</option>
                    <option value="Incident">Incident</option>
                    <option value="Special Request">Special Request</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-lg-2">
                <div className="form-group">
                  <select
                    name="defect-type"
                    id="select-defect-type"
                    onChange={(e) => setdefect(e.target.value)}
                    value={defectType}
                    disabled={
                      localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                      localStorage.getItem('role') == 'Manager' ||
                      submitdisabled == true
                    }
                    className="form-control custom-select form-control-sm"
                  >
                    <option value="">Select Defect Type</option>

                    <option id="1-defect" value="Broken Bottles">
                      Broken Bottles | Quality in Transit
                    </option>

                    <option id="2-defect" value="BSC Documents Delayed">
                      BSC Documents Delayed | Supply Chain Execution
                    </option>

                    <option id="3-defect" value="BSC Documents Incorrect">
                      BSC Documents Incorrect | Supply Chain Execution
                    </option>

                    <option id="4-defect" value="Cloudy / Hazy / Sediments beer">
                      Cloudy / Hazy / Sediments beer | Quality in Production
                    </option>

                    <option id="5-defect" value="Condensation">
                      Condensation | Quality in Transit
                    </option>

                    <option id="6-defect" value="Condensation - inside wrapping">
                      Condensation - inside wrapping | Quality in Transit
                    </option>

                    <option id="7-defect" value="Condensation - outside wrapping - des. Bags">
                      Condensation - outside wrapping - des. Bags | Quality in Transit
                    </option>

                    <option id="8-defect" value="Condensation - outside wrapping - NO des. Bags">
                      Condensation - outside wrapping - NO des. Bags | Quality in Transit
                    </option>

                    <option id="9-defect" value="Consumer complaints">
                      Consumer complaints | Quality in Production
                    </option>

                    <option id="10-defect" value="COVID-19">
                      COVID-19 | Supply Chain Execution
                    </option>

                    <option id="11-defect" value="Damage primary packaging">
                      Damage primary packaging | Quality in Transit
                    </option>

                    <option id="12-defect" value="Damage secondary packaging">
                      Damage secondary packaging | Quality in Transit
                    </option>

                    <option id="13-defect" value="Damage tertiary packaging (pallets)">
                      Damage tertiary packaging (pallets) | Quality in Transit
                    </option>

                    <option id="14-defect" value="Dead Freight (Wasted Journey)">
                      Dead Freight (Wasted Journey) | Supply Chain Execution
                    </option>

                    <option id="15-defect" value="Defective packaging">
                      Defective packaging | Quality in Production
                    </option>

                    <option id="16-defect" value="Demurrage + Port Storage (Containers)">
                      Demurrage + Port Storage (Containers) | Supply Chain Execution
                    </option>

                    <option id="17-defect" value="Dented Cans">
                      Dented Cans | Quality in Production
                    </option>

                    <option id="18-defect" value="Different Quantity Delivered">
                      Different Quantity Delivered | Supply Chain Execution
                    </option>

                    <option id="19-defect" value="Different Quantity Delivered - DOC correct">
                      Different Quantity Delivered - DOC correct | Supply Chain Execution
                    </option>

                    <option id="20-defect" value="Different Quantity Delivered - DOC incorrect">
                      Different Quantity Delivered - DOC incorrect | Supply Chain Execution
                    </option>

                    <option id="21-defect" value="Different Quantity Delivered / POCM">
                      Different Quantity Delivered / POCM | Supply Chain Execution
                    </option>

                    <option id="22-defect" value="Different SKU Delivered">
                      Different SKU Delivered | Supply Chain Execution
                    </option>

                    <option id="23-defect" value="Different SKU Delivered - DOC correct">
                      Different SKU Delivered - DOC correct | Supply Chain Execution
                    </option>

                    <option id="24-defect" value="Different SKU Delivered - DOC incorrect">
                      Different SKU Delivered - DOC incorrect | Supply Chain Execution
                    </option>

                    <option id="25-defect" value="Dirty / Damaged containers">
                      Dirty / Damaged containers | Quality in Transit
                    </option>

                    <option id="26-defect" value="Documents blocked / Payment issues with supplier">
                      Documents blocked / Payment issues with supplier | Supply Chain Execution
                    </option>

                    <option id="27-defect" value="Documents delayed">
                      Documents delayed | Supply Chain Execution
                    </option>

                    <option id="28-defect" value="Documents incorrect">
                      Documents incorrect | Supply Chain Execution
                    </option>

                    <option id="29-defect" value="Flat (Low CO2)">
                      Flat (Low CO2) | Quality in Production
                    </option>

                    <option id="30-defect" value="Foaming issue">
                      Foaming issue | Quality in Production
                    </option>

                    <option id="31-defect" value="Foreign Kegs">
                      Foreign Kegs | Quality in Production
                    </option>

                    <option id="32-defect" value="Glue Issues">
                      Glue Issues | Quality in Production
                    </option>

                    <option id="33-defect" value="Handling/Loading related damages">
                      Handling/Loading related damages | Quality in Transit
                    </option>

                    <option id="34-defect" value="Hoegaarden quality issues">
                      Hoegaarden quality issues | Quality in Production
                    </option>

                    <option id="35-defect" value="Incomplete filling">
                      Incomplete filling | Quality in Production
                    </option>

                    <option id="36-defect" value="Invalid Complaint">
                      Invalid Complaint | Invalid Complaint
                    </option>

                    <option id="37-defect" value="Label / Print - missing">
                      Label / Print - missing | Quality in Production
                    </option>

                    <option id="38-defect" value="Label / Print - unreadable / defective">
                      Label / Print - unreadable / defective | Quality in Production
                    </option>

                    <option id="39-defect" value="Label / Print Incorrect">
                      Label / Print Incorrect | Quality in Production
                    </option>

                    <option id="40-defect" value="Late loading - waiting time - dead freight">
                      Late loading - waiting time - dead freight | Supply Chain Execution
                    </option>

                    <option id="41-defect" value="Leakage keg (spear)">
                      Leakage keg (spear) | Quality in Production
                    </option>

                    <option id="42-defect" value="Leaking Cans - external damages">
                      Leaking Cans - external damages | Quality in Transit
                    </option>

                    <option id="43-defect" value="Leaking Cans - no external damages">
                      Leaking Cans - no external damages | Quality in Production
                    </option>

                    <option id="44-defect" value="Master Data issue">
                      Master Data issue | Supply Chain Execution
                    </option>

                    <option id="45-defect" value="Missing keg cap">
                      Missing keg cap | Quality in Production
                    </option>

                    <option id="46-defect" value="Missing order for the customer">
                      Missing order for the customer | Supply Chain Execution
                    </option>

                    <option id="47-defect" value="Mold">
                      Mold | Quality in Transit
                    </option>

                    <option id="48-defect" value="Mold - Pallet">
                      Mold - Pallet | Quality in Transit
                    </option>

                    <option id="49-defect" value="Mold (Packaging) - leaking">
                      Mold (Packaging) - leaking | Quality in Transit
                    </option>

                    <option id="50-defect" value="Mold (Packaging) - NO leaking">
                      Mold (Packaging) - NO leaking | Quality in Transit
                    </option>

                    <option id="51-defect" value="Not utilized truck/container capacity">
                      Not utilized truck/container capacity | Supply Chain Execution
                    </option>

                    <option id="52-defect" value="Off taste beer">
                      Off taste beer | Quality in Production
                    </option>

                    <option id="53-defect" value="Other quality in transit issues">
                      Other quality in transit issues | Quality in Transit
                    </option>

                    <option id="54-defect" value="Other quality production issues">
                      Other quality production issues | Quality in Production
                    </option>

                    <option id="55-defect" value="Other Supply Chain Execution">
                      Other Supply Chain Execution | Supply Chain Execution
                    </option>

                    <option id="56-defect" value="Out of stock related Fees (OOS)">
                      Out of stock related Fees (OOS) | Supply Chain Execution
                    </option>

                    <option id="57-defect" value="Pest Infested Load">
                      Pest Infested Load | Quality in Transit
                    </option>

                    <option id="58-defect" value="Rework Secondary Packaging">
                      Rework Secondary Packaging | Quality in Transit
                    </option>

                    <option id="59-defect" value="RFS Incorrect EAN code/bar code">
                      RFS Incorrect EAN code/bar code | Supply Chain Execution
                    </option>

                    <option id="60-defect" value="RFS Incorrect labels">
                      RFS Incorrect labels | Supply Chain Execution
                    </option>

                    <option id="61-defect" value="RIMA complaint">
                      RIMA complaint | Supply Chain Execution
                    </option>

                    <option id="62-defect" value="Rust In Transit / Crowns/Cans In transit">
                      Rust In Transit / Crowns/Cans In transit | Quality in Transit
                    </option>

                    <option id="63-defect" value="Rusty cans - Quality">
                      Rusty cans - Quality | Quality in Production
                    </option>

                    <option id="64-defect" value="Rusty Crowns - Bottles - Quality">
                      Rusty Crowns - Bottles - Quality | Quality in Production
                    </option>

                    <option id="65-defect" value="Shifted loads">
                      Shifted loads | Quality in Transit
                    </option>

                    <option id="66-defect" value="Short shelf life issue">
                      Short shelf life issue | Supply Chain Execution
                    </option>

                    <option id="67-defect" value="Stella Re-call">
                      Stella Re-call | Quality in Production
                    </option>

                    <option id="68-defect" value="Stolen Product">
                      Stolen Product | Quality in Transit
                    </option>

                    <option id="69-defect" value="Torn cases">
                      Torn cases | Quality in Transit
                    </option>

                    <option id="70-defect" value="Unstable pallets">
                      Unstable pallets | Quality in Transit
                    </option>

                    <option id="71-defect" value="Waiting Time Trucks - Pick Up">
                      Waiting Time Trucks - Pick Up | Supply Chain Execution
                    </option>

                    <option id="72-defect" value="Wrong destination delivery">
                      Wrong destination delivery | Supply Chain Execution
                    </option>

                    <option id="73-defect" value="Wet Cases inside container">
                      Wet Cases inside container | Supply Chain Execution
                    </option>
                  </select>
                </div>
              </div>

              <div className="col-12 col-lg">
                {registrationDate != '' && (
                  <>
                    <h1 className="page-title"
                    //  style={{ color: refcolor?.current }}
                    >
                      <strong style={{ color: refcolor?.current }}>Due Date:</strong>{' '}
                      {String(moment(refduedate?.current).format('DD-MM-YYYY'))}
                    </h1>
                  </>
                )}
              </div>
              <div className="row">
                <div className="col">
                  <div className="btn-group">
                    <Button
                      onClick={savecomplaint}
                      type="button"
                      disabled={
                        localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                        localStorage.getItem('role') == 'Manager' ||
                        submitdisabled == true
                      }
                      className="btn btn-primary"
                    >
                      Save
                    </Button>
                    <Button
                      onClick={handleshowcurr}
                      type="button"
                      className="btn btn-warning"
                      disabled={
                        localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                        localStorage.getItem('role') == 'Manager' ||
                        submitdisabled == true
                      }
                    >
                      Convert
                    </Button>
                    <Button
                      type="button"
                      onClick={onepager}
                      className="btn btn-warning"
                      disabled={
                        localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                        localStorage.getItem('role') == 'Manager' ||
                        submitdisabled == true
                      }
                    >
                      One Pager
                    </Button>
                    <Button
                      className="btn btn-warning"
                      onClick={handleaddextrachange}
                      disabled={
                        localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                        localStorage.getItem('role') == 'Manager' ||
                        submitdisabled == true
                      }
                      data-toggle="dropdown"
                    >
                      Add Extra Days
                    </Button>

                    <Button
                      type="button"
                      onClick={handleShowDelete}
                      disabled={
                        localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                        localStorage.getItem('role') == 'Manager' ||
                        submitdisabled == true
                      }
                      className="btn btn-danger"
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showaddextradays === true && (
          <>
            <div style={{ textAlign: 'right' }} className="col-2">
              <input
                id="add-extra-days-num"
                style={{ textAlign: 'right' }}
                value={dropextraduedate}
                onChange={(e) => setdropextraduedate(e.target.value)}
                type="number"
                className="form-control"
              />
              <button
                id="add-extra-days-btn"
                type="button"
                style={{ textAlign: 'right' }}
                onClick={handleaddextradays}
                className="btn btn-primary"
              >
                Add
              </button>
              &nbsp;&nbsp;&nbsp; Current Extra Days: {extra_dueDate}
            </div>
          </>
        )}
        <Row className="row">
          <div className="col-12">
            <div className="card-deck">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-auto">
                          <label className="form-label">Acknowledgement Number</label>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <input
                              type="text"
                              id="ticket-salesforce"
                              className="form-control form-control-sm"
                              value={acknowledgementNumber}
                            />
                          </div>
                        </div>
                      </div>
                      {/* Newly added */}
                      {/* <div className="row">
                        <div className="col-auto">
                            <label className="form-label">Logistics/Quality</label>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <input type="text" id="typeofcomp"  className="form-control form-control-sm" value={headercomplainttype} />
                            </div>
                        </div>
                    </div> */}
                      {/* Newly added */}
                      <div className="row">
                        <div className="col-auto">
                          <label className="form-label">Assigned To</label>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <select
                              className="form-select form-select-sm form-control form-control-sm select-size"
                              aria-label=".form-select-sm example"
                              disabled={
                                localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                localStorage.getItem('role') == 'Manager' ||
                                submitdisabled == true
                              }
                              value={registeredBy}
                              onChange={(e) => setregisteredby(e.target.value)}
                              id="registered-by"
                            >
                              <option value="">Select</option>
                              {cqteam?.map((cqteam) => (
                                <option value={cqteam.userEmail}>{cqteam.userEmail}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-auto">
                          <label className="form-label">SPOC</label>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <select
                              className="form-select form-select-sm select-size"
                              aria-label=".form-select-sm example"
                              disabled={
                                localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                localStorage.getItem('role') == 'Manager' ||
                                submitdisabled == true
                              }
                              id="spoc"
                              onChange={(e) => setcqagent(e.target.value)}
                              value={cqAgent}
                            >
                              <option value="">Select</option>
                              {spoclist?.map((spoclist) => (
                                <option value={spoclist.userEmail}>{spoclist.userEmail}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-auto">
                          <label className="form-label">Complaint Type</label>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <input
                              id="complaint-type"
                              type="text"
                              value={complaintType}
                              disabled={true}
                              className="form-control form-control-sm"
                              readonly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-auto">
                          <label className="form-label">Sub-Type</label>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <select
                              disabled={
                                localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                localStorage.getItem('role') == 'Manager' ||
                                submitdisabled == true
                              }
                              id="select-subtype"
                              onChange={(e) => setsubtype(e.target.value)}
                              value={subtype}
                              className="form-control form-control-sm"
                            >
                              <option value="">Select</option>
                              <option value="BSC">BSC</option>
                              <option value="Forwarder">Forwarder</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <label className="form-label">Bill to Customer</label>
                            <div className="form-control-plaintext">{custname}</div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label className="form-label">Bill-to Sales Organization </label>
                            <div className="form-control-plaintext">{billtosalesorg}</div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <label className="form-label">Ship to Customer</label>
                            <div className="form-control-plaintext">{shiptocust}</div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label className="form-label"> Ship-to Sales Organization</label>
                            <div className="form-control-plaintext">{shiptosalesorg}</div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <label className="form-label">Destination</label>
                            <div className="form-control-plaintext">{destination}</div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label className="form-label">Code</label>
                            <div className="form-control-plaintext">{code}</div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <label className="form-label">Import Zone</label>
                            <div className="form-control-plaintext">{importzone}</div>
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="form-group">
                            <label className="form-label">Exporting Zone</label>
                            <div className="form-control-plaintext">{exportzone}</div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <label className="form-label">Incoterm</label>
                            <div className="form-control-plaintext">
                              {incoterm1}, {incoterm2}
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label className="form-label">Sold To</label>
                            <div className="form-control-plaintext">{soldto}</div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <label className="form-label">Payer Name</label>
                            <div className="form-control-plaintext">{payername}</div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-6">Product Cost Claimed</div>
                        <div className="col-4 text-right" id="product-cost-claimed">
                          {costClaimed} {currencyClaimed}
                        </div>
                        <span className="col-auto btn" onClick={handleShow1}>
                          <a id="show-cost-claim-customer-modal">
                            <FaEdit />
                          </a>
                        </span>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <strong>Extra Fees Claimed</strong>
                        </div>
                        <div className="col-4 text-right" id="extra-fees-claimed">
                          {sumextraclaims} {currencyClaimed}
                        </div>
                        <span className="col-auto btn" onClick={handleShow2}>
                          <a id="show-extra-fees-claimed-customer-modal">
                            <FaEdit />
                          </a>
                        </span>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-6">
                          <strong>Total Claimed</strong>
                        </div>
                        <div className="col-4 text-right" id="total-cost-claimed">
                          {totalextraclaims} {currencyClaimed}
                        </div>
                        <span className="col-auto"></span>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-6">
                          <strong>Product Cost Paid</strong>
                        </div>
                        <div id="product-cost-paid" className="col-4 text-right">
                          {sum2} {importercostpaidcurr}
                        </div>
                        <div id="product-cost-paid" className="col-4 text-right"></div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <strong>Extra Fees Paid</strong>
                        </div>
                        <div id="extra-fees-paid" className="col-4 text-right">
                          {sumcostpaid} {importercostpaidcurr}
                        </div>
                        <span className="col-auto btn" onClick={handleShow3}>
                          <a id="show-extra-fees-paid-customer-modal">
                            <FaEdit />
                          </a>
                        </span>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-6">
                          <strong>Total Paid</strong>
                        </div>
                        <div id="total-cost-paid" className="col-4 text-right">
                          {totalcostpaid} {importercostpaidcurr}
                        </div>
                        <span className="col-auto"></span>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-6">
                          <strong>Plant Cost Accepted</strong>
                        </div>
                        <div id="plant-cost-acc" className="col-4 text-right">
                          {sum3} {plantcostpaidcurr}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <strong>Plant Extra Fees Accepted</strong>
                        </div>
                        <div id="plant-extra-fees-acc" className="col-4 text-right">
                          {sumplantaccepted} {plantcostpaidcurr}
                        </div>
                        <span className="col-auto btn" onClick={handleShow4}>
                          <a id="show-plant-extra-fees-accepted-customer-modal">
                            <FaEdit />
                          </a>
                        </span>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-6">
                          <strong>Total Plant Accepted</strong>
                        </div>
                        <div id="total-plant-acc" className="col-4 text-right">
                          {totalplantaccepted} {plantcostpaidcurr}
                        </div>
                        <span className="col-auto"></span>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-6">
                          <strong>Product Cost Accepted Split</strong>
                        </div>
                        <div id="plant-cost-acc" className="col-4 text-right"></div>
                        <span className="col-auto btn" onClick={handleShow5}>
                          <a id="show-cost-accepted-split-customer-modal">
                            <FaEdit />
                          </a>
                        </span>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <strong>Extra Fees Accepted Split</strong>
                        </div>
                        <div id="plant-extra-fees-acc" className="col-4 text-right"></div>
                        <span className="col-auto btn">
                          <a
                            id="show-extra-fees-accepted-split-customer-modal"
                            onClick={handleShow6}
                          >
                            <FaEdit />
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-auto">
                          <label className="form-label">Accepted</label>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <select
                              id="select-accepted"
                              disabled={
                                localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                localStorage.getItem('role') == 'Manager' ||
                                submitdisabled == true
                              }
                              onChange={handleAccepted}
                              value={accepted}
                              className="form-control form-control-sm"
                            >
                              <option value="">Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                              <option value="Partially">Partially</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-auto">
                          <label className="form-label">Status</label>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <select
                              id="select-status"
                              onChange={(e) => setstatus(e.target.value)}
                              disabled={
                                localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                localStorage.getItem('role') == 'Manager' ||
                                submitdisabled == true
                              }
                              value={status}
                              className="form-control form-control-sm"
                            >
                              <option value="">Select</option>
                              <option value="Open">Open</option>
                              <option value="Closed">Closed</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-auto">
                          <label className="form-label">Different Quantity Delivered</label>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <select
                              id="select-status"
                              onChange={(e) => setdiffquantityclaimed(e.target.value)}
                              disabled={
                                localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                localStorage.getItem('role') == 'Manager' ||
                                submitdisabled == true
                              }
                              value={diffquantityclaimed}
                              className="form-control form-control-sm"
                            >
                              <option value="">Select</option>
                              <option value="true">True</option>
                              <option value="false">False</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-auto">
                          <label className="form-label">Accepted By</label>
                        </div>
                        <div className="col">
                          <label className="custom-control custom-checkbox custom-control-inline">
                            <input
                              disabled={
                                localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                localStorage.getItem('role') == 'Manager' ||
                                submitdisabled == true
                              }
                              id="accepted-by-am"
                              type="checkbox"
                              checked={checkedByAm}
                              onChange={handleOnChange1}
                              className="custom-control-input"
                            />
                            <span className="custom-control-label">Area Manager</span>
                          </label>
                          <label className="custom-control custom-checkbox custom-control-inline">
                            <input
                              disabled={
                                localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                localStorage.getItem('role') == 'Manager' ||
                                submitdisabled == true
                              }
                              id="accepted-by-vlc"
                              type="checkbox"
                              checked={checkedByVlc}
                              onChange={handleOnChange2}
                              className="custom-control-input"
                            />
                            <span className="custom-control-label">VLC</span>
                          </label>
                          <label className="custom-control custom-checkbox custom-control-inline">
                            <input
                              disabled={
                                localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                localStorage.getItem('role') == 'Manager' ||
                                submitdisabled == true
                              }
                              id="accepted-by-plant-log"
                              type="checkbox"
                              checked={checkedByPlantLogi}
                              onChange={handleOnChange3}
                              className="custom-control-input"
                            />
                            <span className="custom-control-label">Plant/Logistics</span>
                          </label>
                        </div>
                      </div>
                      <Form>
                        <div className="row">
                          <div className="col-auto">
                            <label className="form-label">Date Submitted</label>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                              <input
                                type="date"
                                value={complaintSubmission}
                                className="form-control form-control-sm"
                                disabled={true}
                                onChange={(e) => {
                                  let formateddate = String(
                                    moment(e.target.value).format('YYYY-MM-DD'),
                                  );
                                  // console.log(complaintSubmission);
                                  setcomplaintsubmission(complaintSubmission);
                                  // console.log(formateddate);
                                }}
                              />
                              {/* </LocalizationProvider> */}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-auto">
                            <label className="form-label">Date Received</label>
                          </div>

                          <div className="col">
                            <div className="form-group">
                              {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                              <input
                                type="date"
                                value={uploaded}
                                className="form-control form-control-sm"
                                disabled={
                                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                  localStorage.getItem('role') == 'Manager' ||
                                  submitdisabled == true
                                }
                                onChange={(e) => {
                                  let formateddate = String(
                                    moment(e.target.value).format('YYYY-MM-DD'),
                                  );
                                  // console.log(uploaded);
                                  setupload(formateddate);
                                  // console.log(formateddate);
                                }}
                              />
                              {/* </LocalizationProvider> */}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-auto">
                            <label className="form-label">Date of Registration</label>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                              <input
                                type="date"
                                value={registrationDate}
                                disabled={
                                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                  localStorage.getItem('role') == 'Manager' ||
                                  submitdisabled == true
                                }
                                className="form-control form-control-sm"
                                onChange={(e) => {
                                  let formateddate = String(
                                    moment(e.target.value).format('YYYY-MM-DD'),
                                  );
                                  // console.log(registrationDate);
                                  setregdate(formateddate);
                                  // console.log(formateddate);
                                }}
                              />
                              {/* </LocalizationProvider> */}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-auto">
                            <label className="form-label">Resolution Date</label>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                              <input
                                type="date"
                                value={closedDate}
                                className="form-control form-control-sm"
                                disabled={
                                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                  localStorage.getItem('role') == 'Manager' ||
                                  submitdisabled == true
                                }
                                onChange={(e) => {
                                  let formateddate = String(
                                    moment(e.target.value).format('YYYY-MM-DD'),
                                  );
                                  // console.log(closedDate);
                                  setclosedate(formateddate);
                                  // console.log(formateddate);
                                }}
                              />
                              {/* </LocalizationProvider> */}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-auto">
                            <label className="form-label">Customer Credited</label>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                              <input
                                type="date"
                                value={customerContacted}
                                disabled={
                                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                  localStorage.getItem('role') == 'Manager' ||
                                  submitdisabled == true
                                }
                                className="form-control form-control-sm"
                                onChange={(e) => {
                                  let formateddate = String(
                                    moment(e.target.value).format('YYYY-MM-DD'),
                                  );
                                  // console.log(customerContacted);
                                  setcusdate(formateddate);
                                  // console.log(formateddate);
                                }}
                              />
                              {/* </LocalizationProvider> */}
                            </div>
                          </div>
                        </div>
                      </Form>

                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <label className="custom-control custom-checkbox">
                              <input
                                id="rules-engagement"
                                type="checkbox"
                                disabled={
                                  localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                  localStorage.getItem('role') == 'Manager' ||
                                  submitdisabled == true
                                }
                                checked={checkedrulesOfEngagement}
                                onChange={handleOnChange4}
                                className="custom-control-input"
                              />
                              <span className="custom-control-label">Rules of Engagement</span>
                            </label>
                          </div>
                        </div>
                        <div className="col-6"></div>
                      </div>

                      <hr></hr>

                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label className="form-label">Customer's Issue Description</label>
                            <div className="form-control-plaintext"></div>
                            <textarea
                              id="complaint-issue-desc"
                              className="form-control"
                              disabled={
                                localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                localStorage.getItem('role') == 'Manager' ||
                                submitdisabled == true
                              }
                              value={complaintDesc}
                              onChange={(e) => setcomplaintdesc(e.target.value)}
                              rows="4"
                              placeholder="Enter complaint description"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label className="form-label">Total Numbers of Containers Affected</label>
                        <div id="total-containers-affected" className="form-control-plaintext">
                          {contaffected}
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="form-label">Total HL Affected</label>
                        <div id="total-hl-affected" className="form-control-plaintext">
                          {totalhl}
                        </div>
                      </div>

                      {type == 'Invalid' ? (
                        <div className="form-group ">
                          <label className="form-label">INVALID</label>
                          <div id="total-containers-affected" className="form-control-plaintext">
                            INVALID REASON
                          </div>
                          <textarea
                            id="complaint-text"
                            className="form-control"
                            value={invalidmess}
                            onChange={(e) => setinvalidmess(e.target.value)}
                            rows="4"
                            placeholder="Enter text"
                          ></textarea>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      <div className="form-group">
                        <label className="form-label">Complaint Text</label>
                        <textarea
                          id="complaint-text"
                          className="form-control"
                          disabled={
                            localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                            localStorage.getItem('role') == 'Manager' ||
                            submitdisabled == true
                          }
                          value={comment}
                          onChange={(e) => setcomment(e.target.value)}
                          rows="4"
                          placeholder="Enter complaint text"
                        ></textarea>
                      </div>
                      <div className="form-group">
                        <label className="form-label">Resolution</label>
                        <textarea
                          id="resolution-text"
                          className="form-control"
                          disabled={
                            localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                            localStorage.getItem('role') == 'Manager' ||
                            submitdisabled == true
                          }
                          onChange={(e) => setresolution(e.target.value)}
                          value={resolution}
                          rows="4"
                          placeholder="Enter resolution"
                        ></textarea>
                      </div>
                      {accepted === 'No' && (
                        <>
                          <div className="form-group">
                            <label className="form-label">Rejection Reason</label>
                            <textarea
                              id="resolution-text"
                              className="form-control"
                              disabled={
                                localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                localStorage.getItem('role') == 'Manager' ||
                                submitdisabled == true
                              }
                              value={reasonofrejection123}
                              onChange={(e) => setreasonofrejection123(e.target.value)}
                              rows="4"
                              placeholder="Enter rejection reason"
                            ></textarea>
                          </div>
                        </>
                      )}
                      {accepted === 'Partially' && (
                        <>
                          <div className="form-group">
                            <label className="form-label">Partially Accepted Reason</label>
                            <textarea
                              id="resolution-text"
                              className="form-control"
                              disabled={
                                localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                localStorage.getItem('role') == 'Manager' ||
                                submitdisabled == true
                              }
                              value={partiallyreason}
                              onChange={(e) => setpartiallyreason(e.target.value)}
                              rows="4"
                              placeholder="Enter partially accepted reason"
                            ></textarea>
                          </div>
                        </>
                      )}
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <label className="form-label">Bill To No.</label>
                            <div className="form-control-plaintext">{billto}</div>
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="form-group">
                            <label className="form-label">Ship To No.</label>
                            <div className="form-control-plaintext">{shipto}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="form-label">Message from Manager</label>
                      <textarea
                        id="message-manager"
                        disabled={
                          localStorage.getItem('role') == 'ABI_CQ_SPOC' || submitdisabled == true
                        }
                        className="form-control"
                        rows="3"
                        value={managermessage}
                        onChange={(e) => setmanagermessage(e.target.value)}
                        placeholder="Enter a message"
                      ></textarea>
                      <Button
                        onClick={savemanagermessage}
                        type="button"
                        disabled={localStorage.getItem('role') == 'ABI_CQ_SPOC'}
                        className="btn btn-primary"
                      >
                        Save Message
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br></br>
            <div>
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChangetab} aria-label="lab API tabs example">
                      <Tab label="Details" value="1" />
                      <Tab label="CN/DN" value="2" />
                      <Tab label="BSC Complaints" value="3" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <div className="card">
                      <div style={{ marginTop: '10px' }}>
                        {loaded === true && order3.length > 0 ? (
                          <RenderExpenseTable order3={order3} orders3={orders3} />
                        ) : null}
                        <Row style={{ textAlign: 'center', paddingRight: '160px', float: 'right' }}>
                          <div className="col-4">
                            <select
                              id="select-curr-cost-customer-modal"
                              value={plantcostpaidcurr}
                              disabled={localStorage.getItem('role') == 'ABI_CQ_SPOC'}
                              onChange={(e) => setplantcostpaidcurr(e.target.value)}
                              className="form-control custom-select"
                            >
                              <option value="">Select</option>
                              <option value="USD">USD</option>
                              <option value="GBP">GBP</option>
                              <option value="EUR">EUR</option>
                              <option value="MXN">MXN</option>
                              <option value="CNY">CNY</option>
                            </select>
                          </div>
                          <div className="col-4" style={{ marginLeft: '10px' }}>
                            <select
                              id="select-curr-cost-customer-modal"
                              value={importercostpaidcurr}
                              disabled={localStorage.getItem('role') == 'ABI_CQ_SPOC'}
                              onChange={(e) => setimportercostpaidcurr(e.target.value)}
                              className="form-control custom-select"
                            >
                              <option value="">Select</option>
                              <option value="USD">USD</option>
                              <option value="GBP">GBP</option>
                              <option value="EUR">EUR</option>
                              <option value="MXN">MXN</option>
                              <option value="CNY">CNY</option>
                            </select>
                          </div>
                          <div
                            className="col-1"
                            style={{ marginLeft: '10px', marginRight: '50px' }}
                          >
                            <Button
                              onClick={handleupdatesalesorder}
                              disabled={
                                localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                localStorage.getItem('role') == 'Manager' ||
                                submitdisabled == true
                              }
                            >
                              Update
                            </Button>
                          </div>
                        </Row>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel value="2">
                    <div className="card-deck">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Credit Notes</h3>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-12 col-md-6 col-lg-6">
                              <div className="row">
                                <div className="col-auto">
                                  <label className="form-label">Product Cost CN Number</label>
                                </div>
                                <div className="col">
                                  <div className="form-group">
                                    <input
                                      id="prod-cost-cn-num"
                                      type="text"
                                      className="form-control form-control-sm"
                                      onChange={(e) => setcostCMNumber(e.target.value)}
                                      value={costCMNumber}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-auto">
                                  <label className="form-label">Product Cost CN Amount</label>
                                </div>
                                <div className="col">
                                  <div className="form-group">
                                    <input
                                      id="prod-cost-cn-amt"
                                      type="number"
                                      step="0.01"
                                      className="form-control form-control-sm"
                                      onChange={(e) => setcostCMAmount(e.target.value)}
                                      value={costCMAmount}
                                    />
                                  </div>
                                </div>
                                <div className="col-auto">
                                  <select
                                    id="select-prod-cost-cn-curr"
                                    value={costCMAmountCurrency}
                                    onChange={(e) => setcostCMAmountCurrency(e.target.value)}
                                    className="form-control form-control-sm"
                                  >
                                    <option value="">Select</option>
                                    <option value="USD">USD</option>
                                    <option value="GBP">GBP</option>
                                    <option value="EUR">EUR</option>
                                    <option value="MXN">MXN</option>
                                  </select>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-auto">
                                  <label className="form-label">
                                    Product Cost Requested (Date)
                                  </label>
                                </div>
                                <div className="col-12">
                                  <div className="form-group">
                                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                                    <input
                                      type="date"
                                      disabled={
                                        localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                        localStorage.getItem('role') == 'Manager' ||
                                        submitdisabled == true
                                      }
                                      value={costRequested}
                                      className="form-control form-control-sm"
                                      onChange={(e) => {
                                        let formateddate = String(
                                          moment(e.target.value).format('YYYY-MM-DD'),
                                        );
                                        // console.log(costRequested);
                                        setcostRequested(formateddate);
                                        // console.log(formateddate);
                                      }}
                                    />
                                    {/* </LocalizationProvider> */}
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-auto">
                                  <label className="form-label">Product Cost Booked (Date)</label>
                                </div>
                                <div className="col">
                                  <div className="form-group">
                                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                                    <input
                                      type="date"
                                      disabled={
                                        localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                        localStorage.getItem('role') == 'Manager' ||
                                        submitdisabled == true
                                      }
                                      value={costbooked}
                                      className="form-control form-control-sm"
                                      onChange={(e) => {
                                        let formateddate = String(
                                          moment(e.target.value).format('YYYY-MM-DD'),
                                        );
                                        // console.log(costbooked);
                                        setcostbooked(formateddate);
                                        // console.log(formateddate);
                                      }}
                                    />
                                    {/* </LocalizationProvider> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6">
                              <div className="row">
                                <div className="col-auto">
                                  <label className="form-label">Extra Fees CN Number</label>
                                </div>
                                <div className="col">
                                  <div className="form-group">
                                    <input
                                      disabled={
                                        localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                        localStorage.getItem('role') == 'Manager' ||
                                        submitdisabled == true
                                      }
                                      type="text"
                                      id="extra-fees-cn-num"
                                      className="form-control form-control-sm"
                                      value={feesCMNumber}
                                      onChange={(e) => setfeesCMNumber(e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-auto">
                                  <label className="form-label">Extra Fees CN Amount</label>
                                </div>
                                <div className="col">
                                  <div className="form-group">
                                    <input
                                      disabled={
                                        localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                        localStorage.getItem('role') == 'Manager' ||
                                        submitdisabled == true
                                      }
                                      type="number"
                                      step="0.01"
                                      id="extra-fees-cn-amt"
                                      value={feesCMAmount}
                                      onChange={(e) => setfeesCMAmount(e.target.value)}
                                      className="form-control form-control-sm"
                                    />
                                  </div>
                                </div>
                                <div className="col-auto">
                                  <select
                                    disabled={
                                      localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                      localStorage.getItem('role') == 'Manager' ||
                                      submitdisabled == true
                                    }
                                    id="select-extra-fees-cn-curr"
                                    value={feesCMAmountCurrency}
                                    onChange={(e) => setfeesCMAmountCurrency(e.target.value)}
                                    className="form-control form-control-sm"
                                  >
                                    <option value="">Select</option>
                                    <option value="USD">USD</option>
                                    <option value="GBP">GBP</option>
                                    <option value="EUR">EUR</option>
                                    <option value="MXN">MXN</option>
                                  </select>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-auto">
                                  <label className="form-label">Extra Fees Requested (Date)</label>
                                </div>
                                <div className="col">
                                  <div className="form-group">
                                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                                    <input
                                      type="date"
                                      disabled={
                                        localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                        localStorage.getItem('role') == 'Manager' ||
                                        submitdisabled == true
                                      }
                                      value={feesRequested}
                                      className="form-control form-control-sm"
                                      onChange={(e) => {
                                        let formateddate = String(
                                          moment(e.target.value).format('YYYY-MM-DD'),
                                        );
                                        // console.log(feesRequested);
                                        setfeesRequested(formateddate);
                                        // console.log(formateddate);
                                      }}
                                    />
                                    {/* </LocalizationProvider> */}
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-auto">
                                  <label className="form-label">Extra Fees Booked (Date)</label>
                                </div>
                                <div className="col">
                                  <div className="form-group">
                                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                                    <input
                                      type="date"
                                      value={feesBooked}
                                      className="form-control form-control-sm"
                                      disabled={
                                        localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                        localStorage.getItem('role') == 'Manager' ||
                                        submitdisabled == true
                                      }
                                      onChange={(e) => {
                                        let formateddate = String(
                                          moment(e.target.value).format('YYYY-MM-DD'),
                                        );
                                        // console.log(feesBooked);
                                        setfeesBooked(formateddate);
                                        // console.log(formateddate);
                                      }}
                                    />
                                    {/* </LocalizationProvider> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Debit Notes</h3>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-12 col-md-6 col-lg-6">
                              <div className="row">
                                <div className="col-auto">
                                  <label className="form-label">DN Final Invoice</label>
                                </div>
                                <div className="col">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      disabled={
                                        localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                        localStorage.getItem('role') == 'Manager' ||
                                        submitdisabled == true
                                      }
                                      id="dn-final-invoice"
                                      className="form-control form-control-sm"
                                      value={dnFinalInvoice}
                                      onChange={(e) => setdnFinalInvoice(e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-auto">
                                  <label className="form-label">DN Final Requested (Date)</label>
                                </div>
                                <div className="col-12">
                                  <div className="form-group">
                                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                                    <input
                                      type="date"
                                      disabled={
                                        localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                        localStorage.getItem('role') == 'Manager' ||
                                        submitdisabled == true
                                      }
                                      value={dnrequested}
                                      className="form-control form-control-sm"
                                      onChange={(e) => {
                                        let formateddate2 = String(
                                          moment(e.target.value).format('YYYY-MM-DD'),
                                        );
                                        // console.log(dnrequested);
                                        setdnrequested(formateddate2);
                                        // console.log(formateddate2);
                                      }}
                                    />
                                    {/* </LocalizationProvider> */}
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-auto">
                                  <label className="form-label">DN Final Booked (Date)</label>
                                </div>
                                <div className="col">
                                  <div className="form-group">
                                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                                    <input
                                      type="date"
                                      value={dnbooked}
                                      disabled={
                                        localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                        localStorage.getItem('role') == 'Manager' ||
                                        submitdisabled == true
                                      }
                                      className="form-control form-control-sm"
                                      onChange={(e) => {
                                        let formateddate = String(
                                          moment(e.target.value).format('YYYY-MM-DD'),
                                        );
                                        // console.log(dnbooked);
                                        setdnbooked(formateddate);
                                        // console.log(formateddate);
                                      }}
                                    />
                                    {/* </LocalizationProvider> */}
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-auto">
                                  <label className="form-label">Profit Center</label>
                                </div>
                                <div className="col">
                                  <div className="form-group">
                                    <input
                                      disabled={
                                        localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                        localStorage.getItem('role') == 'Manager' ||
                                        submitdisabled == true
                                      }
                                      type="text"
                                      id="dn-profit-center"
                                      className="form-control form-control-sm"
                                      value={profitCenter}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6">
                              <div className="row">
                                <div className="col-auto">
                                  <label className="form-label">Cost Center</label>
                                </div>
                                <div className="col">
                                  <div className="form-group">
                                    <input
                                      disabled={
                                        localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                        localStorage.getItem('role') == 'Manager' ||
                                        submitdisabled == true
                                      }
                                      type="text"
                                      id="dn-cost-center"
                                      className="form-control form-control-sm"
                                      value={dncostcenter}
                                      onChange={(e) => setdncostcenter(e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-auto">
                                  <label className="form-label">G/L</label>
                                </div>
                                <div className="col">
                                  <div className="form-group">
                                    <input
                                      disabled={
                                        localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                        localStorage.getItem('role') == 'Manager' ||
                                        submitdisabled == true
                                      }
                                      type="text"
                                      id="dn-gl"
                                      className="form-control form-control-sm"
                                      value={dngl}
                                      onChange={(e) => setdngl(e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="custom-control custom-checkbox">
                                  <input
                                    disabled={
                                      localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                                      localStorage.getItem('role') == 'Manager' ||
                                      submitdisabled == true
                                    }
                                    id="dn-pending-checkbox"
                                    type="checkbox"
                                    checked={checkdnpending}
                                    onChange={handleOnChange5}
                                    className="custom-control-input"
                                  />
                                  <span className="custom-control-label">DN Pending</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                  </TabPanel>
                  <TabPanel value="3">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12 col-lg-6">
                            <div className="row">
                              <div className="col-auto">
                                <label className="form-label">BSC Responsible Team Lead</label>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    id="bsc-team-lead"
                                    value={Teamlead}
                                    onChange={(e) => setTeamlead(e.target.value)}
                                    className="form-control form-control-sm"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-auto">
                                <label className="form-label">BSC Defect Type</label>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <select
                                    id="select-bsc-defect-type"
                                    value={bscdefect}
                                    onChange={(e) => setbscdefect(e.target.value)}
                                    className="form-control form-control-sm"
                                  >
                                    <option value="">Select</option>
                                    {bscdefectlist?.map((bscdefectlist) => (
                                      <option value={bscdefectlist.bscdefectitems}>
                                        {bscdefectlist.bscdefectitems}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-auto">
                                <label className="form-label">BSC Process</label>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <select
                                    id="select-bsc-process-type"
                                    value={processValue}
                                    onChange={(e) => setprocess(e.target.value)}
                                    className="form-control form-control-sm"
                                  >
                                    <option value="">Select</option>
                                    {bscprocesslist?.map((bscprocesslist) => (
                                      <option value={bscprocesslist.bscprocesstypes}>
                                        {bscprocesslist.bscprocesstypes}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div className="form-group">
                                  <label className="form-label">BSC Issue Description</label>
                                  <textarea
                                    id="bsc-issue-desc"
                                    className="form-control"
                                    value={bscnote}
                                    onChange={(e) => setbscnote(e.target.value)}
                                    rows="3"
                                    placeholder="Enter the issue description"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-lg-6">
                            <div className="form-group">
                              <label className="form-label">BSC Root Cause</label>
                              <textarea
                                id="bsc-root-cause"
                                className="form-control"
                                rows="3"
                                value={root}
                                onChange={(e) => setroot(e.target.value)}
                                placeholder="Enter the root cause"
                              ></textarea>
                            </div>
                            <div className="form-group">
                              <label className="form-label">BSC Preventive Actions</label>
                              <textarea
                                id="bsc-preventive-actions"
                                className="form-control"
                                rows="3"
                                value={preventive}
                                onChange={(e) => setpreventive(e.target.value)}
                                placeholder="Enter any preventive actions"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </TabContext>
              </Box>
            </div>
            <div className="card-deck">
              <div className="card col-12 col-lg-8">
                {/* <div className="card-header">
            <div className="col-11"><h3 className="card-title">Follow Ups</h3></div>
            <div className="col-auto text-right">
                <button type="button" className="btn btn-info btn-warning"  data-toggle="modal" disabled={localStorage.getItem("role")=='ABI_CQ_SPOC' || localStorage.getItem("role")=='Employee'} data-target="#add-follow-up-modal" onClick={handlesendinternalfollowup}>Update</button>
            </div>
        </div> */}
                <div id="followup-body" className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <RenderExpenseTable4 order4={order4} orders4={orders4} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card col-12 col-lg-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label className="form-label">Message for Future</label>
                        <textarea
                          id="message-future"
                          disabled={
                            localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                            localStorage.getItem('role') == 'Manager' ||
                            submitdisabled == true
                          }
                          className="form-control"
                          rows="3"
                          value={note}
                          onChange={(e) => setnote(e.target.value)}
                          placeholder="Enter a message"
                        ></textarea>
                      </div>
                      <div className="form-group">
                        <label className="form-label">Reminders On:</label>
                        <div className="row gutters-xs">
                          <select
                            id="select-curr-cost-customer-modal"
                            value={extradocrequested}
                            disabled={
                              localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                              localStorage.getItem('role') == 'Manager' ||
                              submitdisabled == true
                            }
                            onChange={(e) => setextradocrequested(e.target.value)}
                            className="form-control custom-select"
                          >
                            <option value="">Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br></br>
            <div className="card-deck">
              <div className="card col-12 col-lg-8">
                <div className="card-header">
                  <div className="col-11">
                    <h3 className="card-title">Customer Follow Ups</h3>
                  </div>
                  <div className="col-auto text-right">
                    <button
                      type="button"
                      className="btn btn-info btn-warning"
                      data-toggle="modal"
                      data-target="#add-follow-up-modal"
                      disabled={
                        localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                        localStorage.getItem('role') == 'Manager' ||
                        localStorage.getItem('role') == 'Employee'
                      }
                      onClick={handleShow7}
                    >
                      Add
                    </button>
                  </div>
                </div>
                <div id="followup-body" className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <Card className="cardscrollable" style={{ height: '27rem' }}>
                        <VerticalTimeline layout={'1-column-left'} animate={false} visible={true}>
                          {customerfollowup?.map((val) => {
                            let info =
                              'Posted on: ' +
                              val.date_start +
                              '  (All timings are in Central Europe time)';
                            if (val.messagePostedBy == 'customer') {
                              return (
                                <VerticalTimelineElement
                                  className="vertical-timeline-element--work"
                                  contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                  contentArrowStyle={{
                                    borderRight: '7px solid  rgb(33, 150, 243)',
                                  }}
                                  date={info}
                                  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                  position="left"
                                  visible={true}
                                >
                                  <h4 className="vertical-timeline-element-title">{val.action}</h4>
                                  <h5 className="vertical-timeline-element-subtitle">
                                    {val.author}
                                  </h5>
                                  <h5>Message : {val.comment}</h5>
                                </VerticalTimelineElement>
                              );
                            } else {
                              return (
                                <VerticalTimelineElement
                                  className="vertical-timeline-element--work"
                                  contentStyle={{ background: 'rgb(255, 122, 128)', color: '#fff' }}
                                  contentArrowStyle={{
                                    borderRight: '7px solid  rgb(255, 122, 128)',
                                  }}
                                  date={info}
                                  iconStyle={{ background: 'rgb(255, 122, 128)', color: '#fff' }}
                                  position="left"
                                  visible={true}
                                >
                                  <h4 className="vertical-timeline-element-title">{val.action}</h4>
                                  <h5 className="vertical-timeline-element-subtitle">
                                    {val.author}
                                  </h5>
                                  <h5>Message : {val.comment}</h5>
                                </VerticalTimelineElement>
                              );
                            }
                          })}
                        </VerticalTimeline>
                      </Card>
                    </div>
                  </div>
                </div>
                <Row style={{ paddingLeft: '20px' }}>Customer Email: {email}</Row>
                <br />
              </div>
              <div className="card col-12 col-lg-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label className="form-label">Credit Note</label>
                        <Dropzone
                          validate={validatefile}
                          onSubmit={handleSubmitFinal}
                          accept="image/png,image/jpeg,application/pdf,video/mp4,video/mpeg,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,application/zip,application/x-zip-compressed,application/vnd.openxmlformats-officedocument.presentationml.presentation,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.ppt,.pptx"
                          getUploadParams={getUploadParams}
                          onChangeStatus={handleChangeStatus}
                          getFilesFromEvent={getFilesFromEvent}
                          submitButtonDisabled={
                            localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                            localStorage.getItem('role') == 'Manager' ||
                            submitdisabled == true
                          }
                          maxSizeBytes={500000000}
                          styles={{
                            dropzone: { height: 250 },
                            dropzoneActive: { borderColor: 'green', backgroundColor: '#baffb3' },
                            dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
                            inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : {}),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    Attachments:
                    {fileslist2?.map((val) => {
                      return (
                        <p>
                          <a
                            onClick={() => {
                              Downloadfile(val.fileName);
                            }}
                            name={val.fileName}
                            style={{
                              textDecoration: 'underline',
                              color: '#0d6efd',
                              fontWeight: '700',
                            }}
                            value={val.fileName}
                          >
                            {val.fileName}
                          </a>
                        </p>
                      );
                    })}
                  </div>
                  {/* newly added */}
                  <div>
                    Sharepoint link to Attachments:
                    <tr>
                      <td>
                        <a href={finallink} target="_blank">
                          Click here
                        </a>
                      </td>
                    </tr>
                  </div>
                  {/* newly added */}
                </div>
              </div>
            </div>
            <br></br>
          </div>
        </Row>
      </div>
    </div>
  );
  // anas table2 
  function RenderExpenseTable4(props) {

    const defaultSorted = [
      {
        dataField: 'id',
        order4: 'dsc',
      },
    ];

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    let tableData4 = order4;



    const pricesorder4 = [
      { id: '2', author: '', date_start: '', owner: '', action: '', comment: '', date_finish: '' },
      { id: '3', author: '', date_start: '', owner: '', action: '', comment: '', date_finish: '' },
      { id: '4', author: '', date_start: '', owner: '', action: '', comment: '', date_finish: '' },
    ];

    const columns4 = [
      {
        dataField: 'id',
        text: 'Id',
        hidden: true,
      },
      {
        dataField: 'author',
        text: 'Author',
        editable: false,
      },
      {
        dataField: 'date_start',
        text: 'Start Date',
        editable: false,
        formatter: (cell) => {
          let dateObj = cell;
          if (typeof cell !== 'object') {
            dateObj = new Date(cell);
          }
          return `${('0' + dateObj.getUTCDate()).slice(-2)}/${(
            '0' +
            (dateObj.getUTCMonth() + 1)
          ).slice(-2)}/${dateObj.getUTCFullYear()}`;
        },
        editor: {
          type: Type.DATE,
        },
      },
      {
        dataField: 'owner',
        text: 'Owner',
        editable: false,
        sort: 'true',
      },
      {
        dataField: 'action',
        text: 'Action',
      },
      {
        dataField: 'comment',
        text: 'Comment',
        editable: false,
      },
      {
        dataField: 'date_finish',
        text: 'Finish Date',
        editable: false,
        formatter: (cell) => {
          let dateObj = cell;
          if (typeof cell !== 'object') {
            dateObj = new Date(cell);
          }
          return `${('0' + dateObj.getUTCDate()).slice(-2)}/${(
            '0' +
            (dateObj.getUTCMonth() + 1)
          ).slice(-2)}/${dateObj.getUTCFullYear()}`;
        },
        editor: {
          type: Type.DATE,
        },
      },
      {
        dataField: 'order4basePkey',
        text: '',
        editable: false,
        formatter: (cell, row) => {
          return (
            <Button
              className="btn-md btn-danger btn-xs border4-secondary rounded"
              disabled={
                localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                localStorage.getItem('role') == 'Manager' ||
                submitdisabled == true
              }
              onClick={() => {
                setdeletethisrow(row);
                setShow10(true);
                // console.log(deletethisrow);
                // console.log(row.id, 'id to be displayed');
                //     setorder4(order4);
                //   orders4({
                //       actionType: "deleteRow",
                //       row: row.id,
                //       author: row.author,
                //       date_start: row.container,
                //       owner: row.owner,
                //       action: row.action,
                //       comment: row.comment,
                //       date_finish: row.date_finish
                //     });

              }}
            >
              Delete
            </Button>
          );
          return null;
        },
      },
    ];

    useEffect(() => {
      if (!order4?.length) {
        setorder4([...props?.orders4({ action: 'order4' })]);
      }
    }, []);

    return (
      <div xs={12} className="col form">
        <ToolkitProvider keyField="id" order4={tableData4} columns={columns4} exportCSV>
          {(props) => (
            <div>
              <div className="d-flex text-right float-right">
                {/* <ExportCSVButton justify-content-around p-2
                  className="export-button border4-secondary rounded"
                  {...props.csvProps}
                >
                  <span>Export to CSV</span>
                </ExportCSVButton>
                */}

                <div className="col-auto text-left">
                  <button
                    type="button"
                    className="btn btn-info btn-success"
                    data-toggle="modal"
                    disabled={
                      localStorage.getItem('role') == 'ABI_CQ_SPOC' ||
                      localStorage.getItem('role') == 'Employee'
                    }
                    data-target="#add-follow-up-modal"
                    onClick={handleShow9}
                  >
                    ✚
                  </button>
                </div>
              </div>
              <BootstrapTable
                {...props.baseProps}
                keyField="id"
                data={tableData4}
                columns={columns4}
                // defaultSorted={defaultSorted}
                cellEdit={cellEditFactory({
                  mode: 'click',
                  blurToSave: true,
                  onStartEdit: (row, column, rowIndex, columnIndex) => { },
                  beforeSaveCell: (oldValue, newValue, row, column) => {
                    if (column.dataField === 'container') {
                      if (isNaN(Number(newValue))) {
                        alert('You entered ' + newValue + ' Please Enter numbers Only!!');
                      }
                    }
                  },
                  onStartEdit: (row, column, rowIndex, columnIndex) => {
                    console.log('start to edit!!!');
                  },
                  beforeSaveCell: (oldValue, newValue, row, column) => {
                    console.log(newValue);
                  },
                  afterSaveCell: (oldValue, newValue, row, column) => {
                    console.log(order4);
                  },
                })}
              ></BootstrapTable>
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }


}

export default ComplaintPag2;
